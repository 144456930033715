import { Swiper, SwiperSlide } from "swiper/react";
import VideoCard from "./VideoCard";
import { Navigation } from "swiper";
import { useCallback, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import VideoModal from "./VideoModal";


const VideoSlider = ({ videos, theme = null }) => 
{
    const swiperRef = useRef(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showVideoModal, setShowVideoModal] = useState(false);

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, []);

    const handleClick = (video) => {
        setSelectedVideo(video);
        setShowVideoModal(true);
    }

    return (
        <>
            <VideoModal video={selectedVideo} onOpen={showVideoModal} onClose={() => setShowVideoModal(false)} />

            <div className='flex items-center justify-between'>
                <h1 className='text-primary lg:text-3xl md:text-2xl text-xl font-bold'>{ theme?.name }</h1>
                <div className='flex justify-end mb-5 text-2xl lg:text-3xl font-bold pr-5 md:p-0 gap-x-3 md:gap-x-5 lg:gap-x-8'>
                    <button className="prev-arrow flex justify-center items-center w-9 h-9 md:w-10 md:h-10 lg:w-14 lg:h-14 bg-white text-primary border-primary border-2 rounded-full hover:bg-primary hover:text-white" onClick={handlePrev}><IoIosArrowBack /></button>
                    <button className="next-arrow flex justify-center items-center w-9 h-9 md:w-10 md:h-10 lg:w-14 lg:h-14 bg-white text-primary border-primary border-2 rounded-full hover:bg-primary hover:text-white" onClick={handleNext}><IoIosArrowForward /></button>
                </div>
            </div>
            <Swiper
                spaceBetween={30}
                loop={false}
                ref={swiperRef}
                navigation={{
                    clickable: videos.length > 4 ? true : false,
                }}
                modules={Navigation}
                className="mySwiper"
                breakpoints={{
                    320: {
                        slidesPerView: videos.length > 1 ? 1.5 : 1,
                    },
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    }
                }}
            >
                {theme != null ?
                (
                    videos.map((video, index) => (
                        (video.theme_id === theme?.id && video.active === 1) && 
                        <SwiperSlide key={index}>
                            <VideoCard
                                video={video}
                                onClick={() => { handleClick(video) }}
                            />
                        </SwiperSlide>
                    ))
                ) : (
                    videos.map((video, index) => (
                        (video.is_new === 1 && video.active === 1) &&
                        <SwiperSlide key={index}>
                            <VideoCard
                                video={video}
                                onClick={() => { handleClick(video) }}
                            />
                        </SwiperSlide>
                    ))
                )}
            </Swiper>
        </>
    )
}

export default VideoSlider;