import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

const Index = () => {

    const { t } = useTranslation();

  return (
    <>
    <section className='flex flex-col justify-center items-center w-full space-y-12 px-10 py-20'>
        <div className='space-y-4 text-center'>
            <h1 className='text-3xl text-black font-bold'>{t('Authentication.accountVerifiedTitle')}</h1>
            <p className='text-xl'>{t('Authentication.accountVerifiedSubtitle')}</p>
        </div>
        <Link to="/login" className='text-lg text-white bg-black rounded-full py-2 px-10'>{t('Authentication.login')}</Link>
    </section>
    </>
  )
}

export default Index