import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useState } from "react";
import ChangePlan from "../../Modals/ChangePlan";
import { Link } from "react-router-dom";
import PlanCard from "../../Pricing/StepPlan/PlanCard";
import { PERMANENCE_PERIODS, PLANS } from "../../../constants/global";

const PlansStore = () => 
{
    const { t } = useTranslation();
    const { auth } = useAuth();

    const showChangePlanModal = () => {
        setChangePlanModal('hidden');
    }

    const [changePlanModal, setChangePlanModal] = useState('hidden');

    const [selectedPlan, setSelectedPlan] = useState({plan: null, permanencePeriod: null});

    const [businessBasicPrice, setBusinessBasicPrice] = useState(t('Pricing.step2.business_plan.basic.no_loyalty_price'));
    const [businessElitePrice, setBusinessElitePrice] = useState(t('Pricing.step2.business_plan.elite.no_loyalty_price'));

    const businessBasicPlanOptions = [
        {   name: t('Pricing.step2.business_plan.basic.option_users'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.basic.option_requests'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.basic.option_duplicates'),
            isChecked: false
        },
        {
            name: t('Pricing.step2.business_plan.basic.option_branding'),
            isChecked: false
        }
    ]
    const businessBasicPermanenceOptions = [
        {   name: t('Pricing.step2.business_plan.permanence_period_options.no_loyalty'),
            value: PERMANENCE_PERIODS.NO_LOYALTY
        },
        {   name: t('Pricing.step2.business_plan.permanence_period_options.6_months'),
            value: PERMANENCE_PERIODS.SIX_MONTHS
        },
    ]

    const businessElitePlanOptions = [
        {   name: t('Pricing.step2.business_plan.elite.option_users'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.elite.option_requests'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.elite.option_duplicates'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.elite.option_branding'),
            isChecked: true
        }
    ]
    const businessElitePermanenceOptions = [
        {   name: t('Pricing.step2.business_plan.permanence_period_options.no_loyalty'),
            value: PERMANENCE_PERIODS.NO_LOYALTY
        },
        {   name: t('Pricing.step2.business_plan.permanence_period_options.3_months'),
            value: PERMANENCE_PERIODS.THREE_MONTHS
        },
        {   name: t('Pricing.step2.business_plan.permanence_period_options.6_months'),
            value: PERMANENCE_PERIODS.SIX_MONTHS
        },
    ]

    const handlePlanSelection = (plan, permanencePeriod = null) => {
        let planAux = {plan: null, permanencePeriod: null}
        if (selectedPlan.plan === plan && (selectedPlan.permanencePeriod === permanencePeriod || permanencePeriod == null)) {
            planAux = {plan: null, permanencePeriod: null}
        } else {
            planAux = {plan: plan, permanencePeriod: permanencePeriod}
        }

        setSelectedPlan(planAux)
    }

    const handlePrice = () => {
        if (selectedPlan.plan == PLANS.BUSINESS_BASIC) {
            if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.NO_LOYALTY) {
                setBusinessBasicPrice(t('Pricing.step2.business_plan.basic.no_loyalty_price'))
            }
            else if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.SIX_MONTHS) {
                setBusinessBasicPrice(t('Pricing.step2.business_plan.basic.six_months_price'))
            }
        } else if (selectedPlan.plan == PLANS.BUSINESS_ELITE) {
            if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.NO_LOYALTY) {
                setBusinessElitePrice(t('Pricing.step2.business_plan.elite.no_loyalty_price'))
            }
            else if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.THREE_MONTHS) {
                setBusinessElitePrice(t('Pricing.step2.business_plan.elite.three_months_price'))
            }
            else if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.SIX_MONTHS) {
                setBusinessElitePrice(t('Pricing.step2.business_plan.elite.six_months_price'))
            }
        }
    }

    useEffect(() => {
        handlePrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlan])
    

    
    return (
        <>
            <div className={changePlanModal}>
                <ChangePlan showmodal={showChangePlanModal} />
            </div>

            <div className='flex flex-col bg-white shadow-custom rounded-2xl p-10'>
                <div className='flex flex-col md:flex-row md:justify-between md:items-center w-full border-b-2 md:pb-10 pb-5 gap-y-5'>
                    <div>
                        <p className='text-lg md:text-xl lg:text-2xl font-bold'>{t('Dashboard.store.next_payment')}</p>
                        <p className='md:text-lg lg:text-xl'>13 January 2023</p>
                    </div>
                    <div>
                        <p className='text-lg md:text-xl lg:text-2xl font-bold'>**** **** **** 3729</p>
                        <Link className='lg:text-md underline text-primary'>{t('Dashboard.store.update_payment_method')}</Link>
                    </div>
                    <div>
                        <p className='text-lg md:text-xl lg:text-2xl font-bold'>{t('Dashboard.store.your_plan')}</p>
                        <p className='md:text-lg lg:text-xl'>
                            {auth?.plan_id === 1
                                ? t('Dashboard.dashboard.basic')
                                : (auth?.plan_id === 2
                                    ? t('Dashboard.dashboard.plus')
                                    : t('Dashboard.dashboard.pioneer'))
                            }
                        </p>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row items-center justify-center gap-10 mt-5 w-full'>

                    <PlanCard
                        title={t('Pricing.step2.business_plan.basic.card_title')}
                        options={businessBasicPlanOptions}
                        price={businessBasicPrice}
                        permanencePeriodOptions={businessBasicPermanenceOptions}
                        hasVat
                        has20Discount
                        isSelected={ selectedPlan.plan === PLANS.BUSINESS_BASIC }
                        onSelect={(selectedPermanencePeriod) => { handlePlanSelection(PLANS.BUSINESS_BASIC, selectedPermanencePeriod) }}
                    />
                    <PlanCard
                        title={t('Pricing.step2.business_plan.elite.card_title')}
                        options={businessElitePlanOptions}
                        price={businessElitePrice}
                        permanencePeriodOptions={businessElitePermanenceOptions}
                        hasVat
                        isPopular
                        has20Discount
                        has35Discount
                        isSelected={ selectedPlan.plan === PLANS.BUSINESS_ELITE }
                        onSelect={(selectedPermanencePeriod) => { handlePlanSelection(PLANS.BUSINESS_ELITE, selectedPermanencePeriod) }}
                    />
                </div>

                <button
                    onClick={() => setChangePlanModal('')}
                    className='bg-white border-2 border-primary py-2 px-10 rounded-full text-primary font-bold text-lg mt-5 ml-auto'
                >
                    {t('Dashboard.store.save_changes')}
                </button>
            </div>
        </>
    )
}

export default PlansStore;