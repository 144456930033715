import React from 'react';
import DashboardStore from '../../../components/Dashboard/Store';
import ScrollToTop from '../../../components/Router/ScrollToTop';
import { Navigate } from 'react-router';
import ComingSoon from '../../ComingSoon';
import useAuth from '../../../hooks/useAuth';

const Store = () => {
    const {auth} = useAuth();
    return (
        <>
            <ScrollToTop />
            {auth.is_dynamik_test ? (
                <>
                <Navigate to={ "addons" } replace />
                <DashboardStore />
                </>
                ) : (
                    <ComingSoon/>
                )
            }
        </>
    )
}

export default Store