import { t } from "i18next";
import { currentLanguage } from "../../Config/i18n";
import EditSVG from "../../../assets/svg/editSVG";
import TrashSVG from "../../../assets/svg/trashSVG";

const BuyerMatchCard = ({ buyerMatch, onNotificationToggle, onEdit, onDelete }) =>
{
    return (
        <div className="bg-white rounded-xl md:rounded-3xl shadow-custom p-5 lg:p-10 flex flex-col md:flex-row gap-5 md:gap-0">
            <div className="space-y-3 md:space-y-5 w-full">
                <div className="text-lg md:text-xl">{ buyerMatch.name }{" "}{ (t('Dashboard.my_requests.in')).toLowerCase() }
                    {" "}{ ((buyerMatch.locations)?.length > 0)
                        ? (buyerMatch.locations)?.map((buyerMatch, index, array) => { return `${buyerMatch.name}${index != array.length - 1 ? ", " : ""}` }) : t('Dashboard.buyer_match.any_location') }</div>
                <div className="text-sm">
                    <span>{ currentLanguage() === 'en' 
                        ? buyerMatch.property_type_name ?? t('Dashboard.buyer_match.any_property')
                        : buyerMatch.property_type_name_pt ?? t('Dashboard.buyer_match.any_property') };
                    </span>
                    <span>{" "}{ currentLanguage() === 'en'
                        ? buyerMatch.request_type_name ?? t('Dashboard.buyer_match.any_request_type')
                        : buyerMatch.request_type_name_pt ?? t('Dashboard.buyer_match.any_request_type') };
                    </span>
                    <span>{" "}{ currentLanguage() === 'en'
                        ? buyerMatch.typology_name ?? t('Dashboard.buyer_match.any_typology') 
                        : buyerMatch.typology_name_pt ?? t('Dashboard.buyer_match.any_typology') };
                    </span>
                    <span>{" "}{ t('Dashboard.buyer_match.min_budget') } { buyerMatch.price_from ?? t('Dashboard.buyer_match.any_price') };</span>
                    <span>{" "}{ t('Dashboard.buyer_match.min_area') } { buyerMatch.area_from ?? t('Dashboard.buyer_match.any_area') }</span>
                </div>
            </div>

            <div className="block md:hidden h-1 rounded-md bg-primary" />
            <div className="hidden md:block mx-10 2xl:mx-20 w-1 rounded-md bg-primary" />

            <div className="flex flex-col md:flex-row md:items-center md:justify-between md:w-full gap-10">
                <div className="flex items-center justify-between md:justify-normal md:gap-10">
                    <div>
                        <p className="mb-2 md:text-lg">
                            { t("Dashboard.buyer_match.immediate_alerts") }
                        </p>
                        <label className="switch">
                            <input defaultChecked={buyerMatch.immediate_alert} onChange={(e) => { onNotificationToggle({ immediate_alert: e.target.checked }) }} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>

                    <div>
                        <p className="mb-2 md:text-lg">
                            { t("Dashboard.buyer_match.private_filter") }
                        </p>
                        <label className="switch">
                            <input defaultChecked={buyerMatch.private_filter} onChange={(e) => { onNotificationToggle({ private_filter: e.target.checked }) }} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className="flex gap-10 text-primary self-end md:self-center">
                    <div className="hover:cursor-pointer" onClick={() => onEdit() }>
                        <EditSVG />
                    </div>
                    <div className="hover:cursor-pointer" onClick={() => onDelete() }>
                        <TrashSVG />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyerMatchCard