import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Index/HeroSection";
import Footer from "../components/Footer";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ScrollToTop from "../components/Router/ScrollToTop";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import useScript from "../hooks/useScript";
import { Helmet } from "react-helmet";
import { useAlert } from "../contexts/AlertContext";
import RegisterNow from "../components/Index/RegisterNow";
import ForBusinesses from "../components/Index/ForBusinesses";
import RequestSlider from "../components/RequestSlider";
import CompanyLogoSlider from "../components/CompanyLogoSlider";

const Home = () => {
  const location = useLocation();

  useScript('https://cdn.cookie-script.com/s/41eed63aa273ca735efe730c8c60594f.js');

  useEffect(() => {
    if (location.hash !== null && location.hash !== "") {
      let element = document.getElementById(location.hash.replace("#", ""));
      if (element != null) {
        element.scrollIntoView({
          block: "start",
          behavior: "smooth",
          inline: "start",
        });
      }
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Fortimy - O Portal dos pedidos imobiliários</title>
        <meta name="description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
        <meta name="keywords" content="Fortimy, Imobiliário, Imóvel, Comprador, Inquilino, Propriedades, Consultor imobiliário" />
        <link rel="canonical" href="https://fortimy.com/" />
        <meta property="og:title" content="Fortimy" />
        <meta property="og:description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Fortimy" />
        <meta property="og:locale" content="pt_PT" />
        <meta property="og:url" content="https://fortimy.com/" />
        <meta property="og:image" content="https://fortimy.com/logo2.png" />
        <meta property="og:image:secure_url" content="https://fortimy.com/logo2.png" />
        <meta property="article:publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Fortimy" />
        <meta name="twitter:description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
        <meta name="twitter:image" content="https://fortimy.com/logo2.png" />
      </Helmet>

      <section className="overflow-clip">
        <ScrollToTop />
        <Navbar />
        <Hero />
        {/* <Numbers /> */}
        {/* <TrustedBy /> */}
        {/* <Tutorial /> */}
        <CompanyLogoSlider />
        <RequestSlider />
        <RegisterNow />
        <ForBusinesses />
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          {/* <WhyFortimy />
          <Community /> */}
          {/* <Plans /> */}
        </AnimationOnScroll>
        <Footer />
      </section>
    </>
  );
};

export default Home;
