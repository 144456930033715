import React from 'react';
import DashboardRequests from '../../../components/Dashboard/MyRequests';
import ScrollToTop from '../../../components/Router/ScrollToTop';
import useAuth from '../../../hooks/useAuth';
import { Navigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const MyRequests = () => {
    const { auth } = useAuth()
    const { id } = useParams();
    const location = useLocation();

    return (
        <>
            <ScrollToTop />
            <DashboardRequests />
            { !location.state?.cta &&
                <Navigate to={ auth?.has_requests ? (id ? `active/${id}` : "active") : "create"} replace />
            }
        </>
    )
}

export default MyRequests
