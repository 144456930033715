import { useTranslation } from "react-i18next"
import RequestCard from "./Cards/RequestCard"
import { Link } from "react-router-dom";

const Requests = ({ requests }) => 
{
    const { t } = useTranslation();

    return (
        <>
        <div className='hidden lg:flex justify-between items-center w-full py-5 border-b-2'>
            <h1 className='text-3xl font-bold text-black'>{t('Dashboard.dashboard.your_requests')}</h1>
        </div>
        {(requests?.data)?.map((request, index) => (
            index < 5 &&
            <RequestCard key={index} request={request} />
        ))}
        {(requests?.data)?.length > 1 &&
            <Link to={"/app/my-requests/active"} className="bg-white shadow-lg border-2 rounded-3xl font-bold text-primary p-1 px-4 mx-auto w-fit flex">
                {t('Dashboard.dashboard.show_more')}
            </Link>
        }

        {(requests?.data)?.length === 0 &&
            <div className="w-full flex justify-center text-primary text-2xl font-bold mt-2">
                {t("Dashboard.dashboard.no_requests")}
            </div>
        }

        </>
    )
}

export default Requests