import React from 'react'
import { useTranslation } from "react-i18next"

const Tooltip = ({message, children}) => {
    
    const { t } = useTranslation();

    return (
        <div className='group relative flex'>
            {children}
            <div className='hidden group-hover:flex absolute -top-5 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-primary text-white text-center text-sm whitespace-nowrap px-4 py-2 rounded-full border-2 border-white'>
                {t(message)}
            </div>
        </div>
    )
}

export default Tooltip