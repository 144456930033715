import React from 'react';
import {Link} from 'react-router-dom';
import LogoBranco from '../../../../assets/Index/Logo-Branco.png';

const Footer = () => {
  return (
    <section className='flex flex-col items-center justify-center bg-top bg-no-repeat bg-footerBg md:bg-auto bg-auto h-80  md:h-[490px] bg-white'>
      {/* <h1 className='text-white md:text-5xl text-4xl font-bold pb-8'>Upgrade Today!</h1>
      <div className='flex md:flex-row flex-col justify-center items-center text-center'>
        <Link to="/pricing" className='bg-white text-primary text-xl py-1 md:px-3 rounded-full shadow-lg md:mx-2 md:w-auto w-[120%] md:my-0 my-2'>Manage your team</Link>
        <Link to="/login" className='bg-transparent text-white md:text-xl text-xl border-2 border-white py-1 md:px-3 rounded-full shadow-lg md:mx-2 md:w-auto w-[120%] md:my-0 my-2'>Shop for highlights</Link>
      </div> */}
      <img alt='Fortimy Logo' className='h-20 md:h-40' src={LogoBranco} />
    </section>
  )
}

export default Footer