import { t } from "i18next";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const ArchiveRequestModal = ({ title, description, onCancel, onConfirm, isLoading = false }) => {

  return (
    <div className='fixed top-0 left-0 h-screen w-screen bg-black/10 flex justify-center items-center'>
        <div className='md:w-[35vw] bg-white rounded-3xl flex flex-col'>
            <button disabled={isLoading} onClick={() => { onCancel() } } className='flex justify-end text-primary text-4xl m-3'><AiOutlineClose /></button>
            <div className='w-full px-5 text-center flex flex-col items-center justify-center pb-10'>
                <h1 className='font-bold text-primary text-2xl text-center whitespace-normal'>{title}</h1>
                <p className='text-black text-xl pt-5 pb-10'>{description}</p>
                <button disabled={isLoading} onClick={() => { onCancel() } } className='bg-black text-white font-bold rounded-lg my-3 w-[50%] py-2'>{t('Modals.cancel')}</button>
                <button disabled={isLoading} onClick={() => { onConfirm() }} className='bg-primary text-white font-bold rounded-lg my-3 w-[50%] py-2'>{t('Modals.confirm')}</button>
            </div>
        </div>
    </div>
  );
};
export default ArchiveRequestModal;
