import React, {useState} from 'react';
import DevicesImg from '../../../assets/Index/floating-devices.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Hero = () => {

    const [email, setEmail] = useState('');

    const { t } = useTranslation();

    return (
        <section className='w-full pt-[14vh] pb-16 bg-secondary'>
            <div className='flex md:flex-row flex-col w-full justify-center relative gap-10 lg:gap-0'>

                <div className='lg:w-1/3 lg:mr-10 md:w-1/2 mx-10'>
                    <h1 className='font-bold xl:text-5xl md:text-4xl text-3xl md:mb-5 leading-tight md:text-left text-center'>{t("Businesses.HeroTitle")}</h1>
                    <p className='py-5 md:text-2xl leading-normal md:text-left text-center text-lg'>{t("Businesses.HeroDescription")}</p>
                    <div className='w-full mt-5 md:flex hidden'>
                        <Link to="/pricing" state={email} className='py-2 bg-primary text-white text-md w-28 rounded-full items-center flex justify-center mt-[5px] mb-[4px]'>{t("Businesses.HeroInputBtn")}</Link>
                    </div>

                    <div className='w-full mt-5 flex md:hidden px-10'>
                        <Link to="/pricing" state={email} className='mx-auto py-2 bg-primary text-white text-md w-28 rounded-full items-center flex justify-center right-11 mt-[4px]'>{t("Businesses.HeroInputBtn")}</Link>
                    </div>
                </div>

                <div className='xl:w-[600px] md:w-[400px] w-full'>
                    <div className='lg:absolute lg:-top-10 xl:-top-20 m-auto'>
                        <img alt='Cover Hero Section Fortimy' className='rounded-2xl object-cover m-auto' src={DevicesImg} />
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Hero;