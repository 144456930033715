import React from 'react';
import PendingComponent from '../../../components/Dashboard/MyRequests/Pending';
import ScrollToTop from '../../../components/Router/ScrollToTop';

const Active = () => {
    return (
        <>
            <ScrollToTop />
            <PendingComponent />
        </>
    )
}

export default Active