import React, { useEffect, useState } from 'react';
import PersonalIcon from '../../../assets/Pricing/PersonalIcon.png';
import PersonalIconSelected from '../../../assets/Pricing/PersonalIconSelected.png';
import BusinessIcon from '../../../assets/Pricing/BusinessIcon.png';
import BusinessIconSelected from '../../../assets/Pricing/BusinessIconSelected.png';
import { useTranslation } from 'react-i18next';
import { PLAN_TYPES } from '../../../constants/global';
import PlanTypeCard from './PlanTypeCard';

const StepPlanType = (props) => {

    const { t } = useTranslation();

    const [currentBgPersonal, setBgPersonal] = useState(props.selectedPlanType === PLAN_TYPES.INDIVIDUAL ? PersonalIconSelected : PersonalIcon);
    const [currentBgBusiness, setBgBusiness] = useState(props.selectedPlanType === PLAN_TYPES.BUSINESS ? BusinessIconSelected : BusinessIcon);

    const [selectedPlan, setSelectedPlan] = useState(props.selectedPlanType ?? null)

    const handleChooseRoute = (route) => {
        setSelectedPlan(route)
        props.chooseRoute(route)
        if (route === PLAN_TYPES.INDIVIDUAL) {
            setBgBusiness(BusinessIcon)
            setBgPersonal(PersonalIconSelected)
        } else if (route === PLAN_TYPES.BUSINESS) {
            setBgBusiness(BusinessIconSelected)
            setBgPersonal(PersonalIcon)
        }
    }

    return (
        <section className='mt-8'>
            <h1 className='text-primary lg:text-xl text-center'><span className='font-bold'>{t('Pricing.Step1TitleHighlight')}</span>{t('Pricing.Step1Title')}</h1>
            <div className='flex md:flex-row flex-col justify-center items-center my-3 w-'>
                <PlanTypeCard
                    onClick={() => handleChooseRoute(PLAN_TYPES.INDIVIDUAL)}
                    isSelected={selectedPlan === PLAN_TYPES.INDIVIDUAL}
                    icon={currentBgPersonal}
                    text={t('Pricing.Step1Options1')}
                    iconClass="w-1/3"
                />
                <PlanTypeCard
                    onClick={() => handleChooseRoute(PLAN_TYPES.BUSINESS)}
                    isSelected={selectedPlan === PLAN_TYPES.BUSINESS}
                    icon={currentBgBusiness}
                    text={t('Pricing.Step1Options2')}
                    iconClass="w-7/12"
                />
            </div>
        </section>
    )
}

export default StepPlanType