import { TbSend } from "react-icons/tb";
import { BsPaperclip } from "react-icons/bs";
import { MdKeyboardArrowUp, MdCancel } from "react-icons/md";
import { FaRegFile, FaArrowAltCircleDown, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useEffect, useState } from "react";
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import { currentLanguage } from '../../Config/i18n';

const ChatWindow = ({ chat, authUser, render_messages, returnToChats, openDetails }) => {

	const { triggerPopup } = useAlert();
	const { t } = useTranslation();

	const [message, setMessage] = useState("");
	const [attachment, setAttachment] = useState(null);
	const [messages, setMessages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const date = new Date();
	let mm = date.getMonth() + 1; // Months start at 0!
	let dd = date.getDate();
	
	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	const sendMessage = async () => {
		if ((!message.trim() || isLoading) && !attachment) {
			return;
		}

		const fd = new FormData();
		fd.append('chat_id', chat.id);

		if (message) {
			fd.append('message', message);
		}
		if (attachment) {
			fd.append('attachment', attachment.file);
		}

		setIsLoading(true);
		await axios.post("/api/chats/send-message", fd);
		setMessage("");
		setAttachment(null);
		setIsLoading(false);
	};

	const handleAttachment = (e) => {

		if (!e) {
			return;
		}

		//convert to MB
        const filesize = e.target.files[0].size / (1024 * 1024)

        if(filesize < 2) {
			setAttachment({
				name: e.target.files[0].name,
				file: e.target.files[0]
			}); 
        } else {
            triggerPopup('Popups.filesize_error_2mb')
        }
	}

	useEffect(() => {
		setMessages([]);
		setMessages(render_messages);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [render_messages]);

	return (
		<>
		<div className="md:col-span-2 flex w-full flex-col bg-white rounded-3xl h-screen shadow-custom py-3 px-1 lg:py-6 lg:px-3 xl:px-5">
			<div className="flex flex-col md:flex-row md:items-center md:justify-between border-b-2 mx-1 lg:mx-3 xl:mx-5 pb-2 md:pb-5 md:py-7 gap-y-2 md:gap-y-0">
				<div className="flex w-full">
					<button className="flex md:hidden text-primary text-4xl" onClick={() => { returnToChats() }}>
						<MdKeyboardArrowUp className="-rotate-90"/>
					</button>
					<div className="flex items-center space-x-3 lg:space-x-6 xl:space-x-10">
						<div className="overflow-hidden rounded-full w-10 h-10 md:w-20 md:h-20 xl:w-28 xl:h-28">
							<img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${chat.owner.image}`} alt="" className="object-cover w-full h-full" />
						</div>
						<div className="space-y-2">
							<div>
								<h1 className="font-bold text-xl xl:text-3xl">
									{ chat.owner.name }
								</h1>
							</div>
							{/* DESKTOP REQUEST INFO VERSION */}
							{ chat.is_request_available ? (
								<button onClick={() => { openDetails(chat.request_id) }} className="hidden md:flex bg-primary/20 items-center p-2 xl:px-4 rounded-xl gap-2">
									<div className="hidden md:flex bg-white w-10 h-10 p-1 rounded-full justify-center items-center">
										<img src={`/svg/properties/${chat.property_image}`} alt="" className="object-cover w-full h-full" />
									</div>
									<div className="text-left">
										<p className="font-bold text-xs lg:text-md">
											{currentLanguage() === 'en' ? chat.property_name : chat.property_name_pt}{" "}
											{chat.location === "" || chat.location === undefined
												? ""
												: "in " + chat.location}
										</p>
										<p className="font-bold text-xs lg:text-md text-primary">
											{t('Dashboard.messages.show_request')}
										</p>
									</div>
								</button>
							) : (
								<div className="hidden md:flex bg-black/10 items-center p-2 xl:px-4 rounded-xl gap-2">
									<p className="font-bold text-xs lg:text-md text-primary">
										{t('Dashboard.messages.request_unavailable')}
									</p>
								</div>
							)}
						</div>
					</div>
					{/* COMPANY INFO */}
					<div className="flex flex-col items-end ml-auto mr-5">

						{ chat.show_email == true &&
							<div className="text-primary flex items-center gap-x-2 text-sm xl:text-lg">
								<MdEmail className="text-base" />
								<p>
									{ chat.owner.email }
								</p>
							</div>
						}

						{chat.show_phone == true && 
							<div className="text-primary flex items-center gap-x-2 text-sm xl:text-lg">
								<FaPhoneAlt className="text-base" />
								<p>
									{ chat.owner.contact }
								</p>
							</div>
						}

						{ chat.owner.company_logo
							? <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${chat.owner.company_logo}`} alt="" className="max-w-[40px] md:max-w-[70px] lg:max-w-[90px] object-contain" />
							: <img src={"/individual_logo.png"} alt="" className="h-10 object-contain" />
						}
					</div>
				</div>
				{/* MOBILE REQUEST INFO VERSION */}
				{ chat.is_request_available ? (
					<button onClick={() => { openDetails(chat.request_id) }} className="flex md:hidden items-center bg-primary/20 py-1 px-2 xl:px-4 rounded-xl gap-2">
						<div className="flex items-center bg-white w-12 h-12 p-1 rounded-full self-start">
							<img src={`/svg/properties/${chat.property_image}`} alt="" className="object-cover w-full h-full" />
						</div>
						<div className="w-fit">
							<p className="font-bold text-xs md:text-md">
								{currentLanguage() === 'en' ? chat.property_name : chat.property_name_pt}{" "}
								{chat.location === "" || chat.location === undefined
									? ""
									: "in " + chat.location}
							</p>
							<p className="text-xs">ID {chat.request_id}</p>
							<p className="font-bold text-xs lg:text-md text-primary">
								{t('Dashboard.messages.show_request')}
							</p>
						</div>
					</button>
				) : (
					<div onClick={() => { openDetails(chat.request_id) }} className="flex md:hidden items-center bg-black/10 py-1 px-2 xl:px-4 rounded-xl gap-2">
						<p className="font-bold text-xs lg:text-md text-primary">
							{t('Dashboard.messages.request_unavailable')}
						</p>
					</div>
				)}
			</div>

			{ /* MESSAGES */ }
			<div className="w-full h-full py-2 flex flex-col-reverse overflow-x-hidden overflow-y-auto chat-scrollbar">
				<div className="flex flex-col">
					{messages?.map((message, index) => (
						<div key={index} className="px-1 lg:px-3 xl:px-5">
							{message.updated_at_date !== messages[index - 1]?.updated_at_date &&
								<div className="bg-black/5 text-black/30 rounded-full p-1 px-2 text-xs w-fit mx-auto">
									<p>{message.updated_at_date}</p>
								</div>
							}
							<div className={ `${((message.created_by === authUser.id || (message.created_by_company === authUser.company_id && (chat.buyer && authUser.id != chat.buyer?.id))) || (authUser.is_admin && (message.created_by === chat.owner.id && (chat.buyer && authUser.id != chat.buyer?.id))))
								? "flex-row-reverse"
								: ""} flex items-start gap-2 my-2`}
							>
								{ message.created_by !== authUser.id &&
									<div className="overflow-hidden rounded-full w-10 h-10 lg:w-12 lg:h-12 2xl:w-14 2xl:h-14">
										<img src={ message.created_by === chat.owner.id
											? `${process.env.REACT_APP_BACKEND_URL_IMG}/users/${chat.owner.image}`
											: `${process.env.REACT_APP_BACKEND_URL_IMG}/users/${chat.consultant?.image ?? chat.buyer?.image}`
											}
											alt=""
											className="object-cover w-full h-full"
										/>
									</div>
								}
								<div
									className={ `${((message.created_by === authUser.id || (message.created_by_company === authUser.company_id && (chat.buyer && authUser.id != chat.buyer?.id))) || (authUser.is_admin && (message.created_by === chat.owner.id && (chat.buyer && authUser.id != chat.buyer?.id))))
									? "bg-primary/20 text-primary rounded-tl-xl"
									: "bg-black/5 text-black/40 rounded-tr-xl"} rounded-b-xl p-2 px-3 lg:p-3 text-md lg:text-base xl:text-lg w-fit`}
								>
									{message?.attachment ? (
											<>
												<a href={`${process.env.REACT_APP_BACKEND_URL_IMG}/attachments/${message.attachment}`} target="_blank" rel="noreferrer" className="flex items-center gap-1">
													{message.attachment_name}
													<FaArrowAltCircleDown />
												</a>
											</>
										) : (
											<p>{message.message}</p>
										)
									}

									<p className={`${((message.created_by === authUser.id || message.created_by_company === authUser.company_id) || (chat.consultant && message.created_by === chat.buyer_id )) && 'text-end mr-2'} text-xs text-black/30`}>
										{message.updated_at_hour}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			{ /* END OF MESSAGES */ }

			{(!chat.consultant && (!chat.buyer || chat.buyer?.id == authUser?.id)) &&
				<div className="mx-1 lg:mx-3 xl:mx-5">
					<div className="flex bg-black/5 rounded-2xl p-3 md:px-5 md:py-4 w-full">
						<label
							htmlFor="uploadAttachment"
							className="text-xl lg:text-3xl opacity-20 cursor-pointer"
						>
							<BsPaperclip />
						</label>
						<input
							className="hidden"
							type="file"
							id="uploadAttachment"
							onChange={(e) => { handleAttachment(e) }}
						/>

						{attachment ? (
							<>
								<p onKeyDown={(e) => { if(e.key === 'Enter') sendMessage() }}
									className="flex items-center gap-1 mx-4 bg-transparent placeholder:text-black/20 w-full focus:outline-none"
								>
									<FaRegFile />
									{attachment.name}
								</p>
								<MdCancel
									className="text-xl lg:text-3xl m-auto mr-3 opacity-20 cursor-pointer"
									onClick={() => { 
										setAttachment(null);
										document.getElementById('uploadAttachment').value = null;
									}}
								/>
							</>
							) : (
							<input
								type="text"
								placeholder="Your message..."
								value={message}
								onChange={(event) => setMessage(event.target.value)}
								onKeyDown={(e) => { if(e.key === 'Enter') sendMessage() }}
								className="mx-4 bg-transparent placeholder:text-black/20 w-full focus:outline-none"
							/>
							)
						}

						<button onClick={() => { sendMessage() }} className={`${isLoading ? 'opacity-5' : 'opacity-20'} text-xl lg:text-3xl`}>
							<TbSend />
						</button>				
					</div>
				</div>
			}
		</div>
		</>
	);
};

export default ChatWindow;
