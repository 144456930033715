import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from "react-router-dom";
import { MY_REQUESTS_TABS_PATH } from "../../../constants/global";

const MyRequests = () => {  
  const { t } = useTranslation();

  const location = useLocation();
  const [activeTab, setActiveTab] = useState('text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mx-2');
  const [archivedTab, setArchivedTab] = useState('text-2xl text-fifth p-3 rounded-2xl mx-2');
  const [pendingTab, setPendingTab] = useState('text-2xl text-fifth p-3 rounded-2xl mx-2');
  const [createTab, setCreateTab] = useState('text-2xl text-fifth p-3 rounded-2xl mx-2');

  const urlHasPath = (path) => {
      if(location.pathname.includes(path)) {
          return location.pathname;
      }
  }

  useEffect(() => {
      switch (location.pathname) {
          case urlHasPath(MY_REQUESTS_TABS_PATH.ACTIVE):
            setActiveTab('text-lg md:text-xl lg:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mx-2 focus:outline-none');
            setArchivedTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setPendingTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setCreateTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            break;
          case urlHasPath(MY_REQUESTS_TABS_PATH.ARCHIVED):
            setArchivedTab('text-lg md:text-xl lg:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mx-2 focus:outline-none');
            setActiveTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setPendingTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setCreateTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            break;
          case urlHasPath(MY_REQUESTS_TABS_PATH.PENDING):
            setPendingTab('text-lg md:text-xl lg:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mx-2 focus:outline-none');
            setActiveTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setArchivedTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setCreateTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            break;
          case urlHasPath(MY_REQUESTS_TABS_PATH.CREATE):
            setCreateTab('text-lg md:text-xl lg:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mx-2 focus:outline-none');
            setActiveTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setPendingTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setArchivedTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            break;
          default:
            setActiveTab('text-lg md:text-xl lg:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mx-2 focus:outline-none');
            setArchivedTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setPendingTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
            setCreateTab('text-lg md:text-xl lg:text-2xl text-fifth p-3 rounded-2xl mx-2 focus:outline-none');
          break;
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      <section>
        <div className='flex flex-wrap md:justify-start justify-center items-center pb-3 md:pb-10'>
          <Link to="active" className={activeTab}>{t('Dashboard.my_requests.active_requests')}</Link>
          <Link to="archived" className={archivedTab}>{t('Dashboard.my_requests.archived_requests')}</Link>
          <Link to="pending" className={pendingTab}>{t('Dashboard.my_requests.pending_requests')}</Link>
          <Link to="create" className={createTab}>{t('Dashboard.my_requests.make_new_request')}</Link>
        </div>
        <Outlet />
      </section>
    </>
  );
};

export default MyRequests;
