import React from 'react';
import DashboardEditRequest from '../../components/Dashboard/EditRequest';
import ScrollToTop from '../../components/Router/ScrollToTop';

const EditRequest = () => {
    return (
        <>
            <ScrollToTop />
            <DashboardEditRequest />
        </>
    )
}

export default EditRequest