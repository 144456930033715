import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ScrollToTop from "../components/Router/ScrollToTop";
import PrivacyPolicyComp from "../components/PrivacyPolicy";

const PrivacyPolicy = () => {
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <PrivacyPolicyComp />
            <Footer />
        </>
    )
}

export default PrivacyPolicy;