import BillingSettings from "../../../components/Dashboard/Settings/BillingSettings";
import ScrollToTop from "../../../components/Router/ScrollToTop";

const Billing = () =>
{
    return (
        <>
            <ScrollToTop />
            <BillingSettings />
        </>
    )
}

export default Billing;