const getVideoID = (link) => {
    const regExp =
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\\&v=)([^#\\&\\?]*).*/
    // eslint-disable-next-line
    const match = link.match(regExp)
    if (match && match[2].length === 11) {
        return match[2]
    }
    return ''
}

export default getVideoID;