import React from 'react'
import Navbar from '../components/Navbar';
import Demo from '../components/DemoRequest'
import Footer from '../components/Footer';
import ScrollToTop from '../components/Router/ScrollToTop'

const DemoRequest = () => {
  return (
    <>
        <ScrollToTop />
        <Navbar />
        <Demo />
        <Footer />
    </>
  )
}

export default DemoRequest