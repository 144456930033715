import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useAuthContext from '../Config/AuthContext';
import { useAlert } from '../../contexts/AlertContext';
import { useTranslation } from "react-i18next";
import ToggeablePassword from '../Inputs/ToggleablePassword';

const ResetPasswordComp = () => {

    const { resetPassword, success } = useAuthContext();
    const { toggleLoader, triggerPopup } = useAlert();

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setpasswordConfirmation] = useState('');
    const [chars, setChars] = useState('col-span-1 text-black/60');
    const [upper, setUpper] = useState('col-span-1 text-black/60');
    const [lower, setLower] = useState('col-span-1 text-black/60');
    const [number, setNumber] = useState('col-span-1 text-black/60');
    const [symbol, setSymbol] = useState('col-span-1 text-black/60');
    const [matchPasswords, setMatchPasswords] = useState('col-span-1 text-black/60');
    const [validateVerification, setValidateVerification] = useState(false);
    let isPasswordValid = false;

    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");

    const { token } = useParams();

    const { t } = useTranslation();

    const validatePassword = (passwordInput) => {

        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-.])/;
        const minLengthRegExp = /.{8,}/;
        const uppercasePassword = uppercaseRegExp.test(passwordInput);
        const lowercasePassword = lowercaseRegExp.test(passwordInput);
        const digitsPassword = digitsRegExp.test(passwordInput);
        const specialCharPassword = specialCharRegExp.test(passwordInput);
        const minLengthPassword = minLengthRegExp.test(passwordInput);

        if (uppercasePassword) {
            setUpper('col-span-1 text-primary');
        } else {
            setUpper('col-span-1 text-black/60');
        }
        if (lowercasePassword) {
            setLower('col-span-1 text-primary');
        } else {
            setLower('col-span-1 text-black/60');
        }
        if (digitsPassword) {
            setNumber('col-span-1 text-primary');
        } else {
            setNumber('col-span-1 text-black/60');
        }
        if (specialCharPassword) {
            setSymbol('col-span-1 text-primary');
        } else {
            setSymbol('col-span-1 text-black/60');
        }
        if (minLengthPassword) {
            setChars('col-span-1 text-primary');
        } else {
            setChars('col-span-1 text-black/60');
        }

        if (uppercasePassword && lowercasePassword && digitsPassword && specialCharPassword && minLengthPassword && password === passwordConfirmation) {
            isPasswordValid = true;
        } else {
            isPasswordValid = false;
        }

    }

    useEffect(() => {
        if (password === passwordConfirmation && password !== '') {
            setValidateVerification(true);
            setMatchPasswords("col-span-1 text-primary");
        } else {
            setValidateVerification(false);
            setMatchPasswords('col-span-1 text-black/60');
        }
    }, [passwordConfirmation, password])

    const submitForm = async () => {
        validatePassword(password);
        if (isPasswordValid) {
            toggleLoader(true);            
            const response = await resetPassword({ token, email, password, passwordConfirmation })
            if (response) {
                toggleLoader(false);
                if (response.status === 200) {
                    triggerPopup('Authentication.resetPasswordSuccess')
                    setTimeout(() => {
                        window.location.href = "/login"
                    }, 4000);
                } else {
                    triggerPopup('Something went wrong')
                }
            }
        }
    }

    return (
        <>
        <section className='w-full flex flex-col py-40'>
            <div className='w-full flex justify-center items-center py-5 text-center'>
                <h1 className='text-black font-bold text-3xl pt-2'>{t('Authentication.resetPassword')}</h1>
            </div>
            <div className='md:grid md:grid-cols-10 flex flex-col md:px-0 px-10'>
                <div className='flex flex-col xl:col-start-5 xl:col-end-7 lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9'>
                    <form className='' onSubmit={(e) => e.preventDefault()}>
                        <ToggeablePassword value={password} onChange={(value) => { setPassword(value); validatePassword(value); }} placeholder={t('Authentication.newPassword')}/>
                        {/* <ErrorMessage errors={errors.current_password} /> */}
                        {/* <input  value={password} type="password" className='w-full my-2 p-2 border-[1px] rounded-md border-fifth placeholder:text-xl placeholder:text-black' placeholder={t('Authentication.newPassword')} /> */}
                        <div className='text-xs flex flex-col mt-1 mb-1 gap-y-1'>
                            <div className='grid grid-cols-3 items-center'>
                                <div className={chars}>{t('Pricing.characters')}</div>
                                <div className={upper}>{t('Pricing.uppercase')}</div>
                                <div className={lower}>{t('Pricing.lowercase')}</div>
                            </div>
                            <div className='grid grid-cols-3 items-center'>
                                <div className={number}>{t('Pricing.digit')}</div>
                                <div className={symbol}>{t('Pricing.symbol')}</div>
                                <div className='w-[1px]'></div>
                            </div>
                        </div>
                        <ToggeablePassword value={passwordConfirmation} onChange={(value) => setpasswordConfirmation(value)} placeholder={t('Authentication.confirmPassword')}/>
                        {/* <ErrorMessage errors={errors.new_password} /> */}
                        {/* <input  value={passwordConfirmation} type="password" className='w-full my-2 p-2 border-[1px] rounded-md border-fifth placeholder:text-xl placeholder:text-black' placeholder={t('Authentication.confirmPassword')} /> */}
                        <div className='text-xs flex my-1'>
                            <div className={matchPasswords}>{t('Pricing.matchPassword')}</div>
                        </div>
                        <button onClick={() => submitForm()} type='button' className='w-full font-bold text-white text-xl bg-black p-2 shadow-lg rounded-full mt-5'>{t('Authentication.resetPassword')}</button>
                    </form>
                </div>
            </div>
        </section>
        </>
    )
}

export default ResetPasswordComp