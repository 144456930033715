import { useTranslation } from "react-i18next";
import { useState } from "react";
import { MANAGE_LEARNING_FILTERS } from "../../../../constants/global";
import Themes from "./Themes";
import Videos from "./Videos";

const Learning = () => {

    const { t } = useTranslation();
    const [filter, setFilter] = useState(MANAGE_LEARNING_FILTERS.THEME);

    return (
        <>
            <div className='flex flex-col w-full gap-y-5'>
                <div className="w-fit border-2 rounded-lg grid grid-cols-2 mt-6 md:text-lg text-base">
                    <button
                        onClick={() => setFilter(MANAGE_LEARNING_FILTERS.THEME)}
                        className={`${
                            filter === MANAGE_LEARNING_FILTERS.THEME
                            ? "bg-primary text-white"
                            : "bg-white text-primary"
                        } 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
                    >
                        {t("Dashboard.admin.themes")}
                    </button>
                    <button
                        onClick={() => setFilter(MANAGE_LEARNING_FILTERS.VIDEO)}
                        className={`${
                            filter === MANAGE_LEARNING_FILTERS.VIDEO
                            ? "bg-primary text-white"
                            : "bg-white text-primary"
                        } 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
                    >
                        {t("Dashboard.admin.video")}
                    </button>
                </div>

                {filter === MANAGE_LEARNING_FILTERS.THEME ?
                    (
                        <Themes />
                    ) :
                    (
                        <Videos />
                    )
                }
            </div>
        </>
    );
};

export default Learning;
