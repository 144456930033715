import React from "react";


const TestimonialSwiperSlide = ({ text, name, role, img, logo }) => {
  return (
      <div className="border-y-4 py-4 mx-4 2xl:mx-8 h-full flex flex-col justify-between">
        <div className="py-6">
          <p className="md:text-2xl">{text}</p>
        </div>
        <div className="flex items-center">
          <div className="w-14 h-14 md:w-20 md:h-20 mr-5">
            <img
              alt="Slide Community 1"
              className="object-cover rounded-full userimg"
              src={img}
            />
          </div>
          <div className="flex flex-col items-start justify-center">
            <div>
              <p className="text-primary font-bold text-lg md:text-2xl">{name}</p>
            </div>
            <div className="flex items-center h-10 md:h-14">
              <img
                alt="Slide Community 1"
                className="object-contain"
                src={logo}
              />
            </div>
            <div>
              <p className="text-sm md:text-lg">{role}</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TestimonialSwiperSlide;
