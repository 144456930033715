import Axios from 'axios'
import { currentLanguage } from './i18n'

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {'Language': currentLanguage()}
})

axios.interceptors.request.use(
config => {
    config.headers['Language'] = currentLanguage();
        return config;
    },
    error => {
        if (error.response.status == 503) {
            window.location.href = "/maintenance";
        }
        return Promise.reject(error);
    }
);

export default axios