import { AiOutlineEye } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { TbHandClick } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { currentLanguage } from "../../Config/i18n.js";
import { BsFillPlusCircleFill } from "react-icons/bs";
import LocationIcon from "../../../assets/Dashboard/Requests/RequestLocationIcon.png";
import { useState } from "react";

const RequestCard = ({ request, onDetails, onViewAmenities, onArchive, onDelete, hasSettings = true }) => {

    const { t } = useTranslation();
    const [openSettings, setOpenSettings] = useState(false);

    return (
        <>
        {/* MOBILE */}
        <div className="lg:hidden p-5 h-fit bg-white rounded-3xl w-full my-5 shadow-custom space-y-4">
            {/*PROPERTY*/}
            <div className="flex items-start gap-1">
                <img
                    src={`/svg/properties/${request.property_type_image}`}
                    alt="Property Type Icon"
                    className="w-16"
                />
                <div>
                    <h1 className="font-bold text-black/60">
                        {currentLanguage() === 'en' ? `${request.property_type_name}` : `${request.property_type_name_pt}`}
                    </h1>
                    <h1 className="font-bold text-black/60">
                        {currentLanguage() === 'en' ? `(${request.typology_name})` : `(${request.typology_name_pt})`}
                    </h1>
                </div>
                {hasSettings && 
                    <div className="relative ml-auto">
                        <IoSettingsOutline
                            onClick={() => { setOpenSettings(!openSettings) }}
                            className="text-primary text-3xl cursor-pointer"
                        />
                        {openSettings &&
                            <div className="flex flex-col items-start text-primary absolute top-12 right-0 bg-white rounded-lg w-32 shadow-custom z-[100] overflow-hidden">
                                <button onClick={() => { onArchive() }} className="w-full px-4 py-2 text-start hover:bg-secondary">
                                    {request.archived ? t('Dashboard.admin.unarchive') : t('Dashboard.admin.archive')}
                                </button>
                                <Link
                                    to={`/app/my-requests/edit/${request.id}`}
                                    className="w-full px-4 py-1 hover:bg-secondary"
                                >
                                    {t('Dashboard.admin.edit')}
                                </Link>
                                {request.archived == 1 &&
                                <button
                                    onClick={() => {
                                        onDelete();
                                    }}
                                    className="w-full px-4 py-2 text-start hover:bg-secondary"
                                >
                                    {t("Dashboard.admin.delete")}
                                </button>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <div className="ml-2">
                <div className="flex text-primary items-start">
                    <img
                        src={LocationIcon}
                        alt=""
                        className="text-primary w-4 mr-2"
                    />
                    <p className="line-clamp-2 text-sm">{t('Dashboard.my_requests.in')} {
                        Object.keys(request.locations).map((key, index) => { 
                            return `${request.locations[key].name}${index != Object.keys(request.locations).length - 1 ? ", " : ""}`
                        })
                    }</p>
                </div>
                <p className="bg-primary font-bold text-white rounded-full text-md lg:block hidden text-center w-fit px-2 whitespace-nowrap">
                    {currentLanguage() === 'en' ? request.request_type_name : request.request_type_name_pt}
                </p>
                <p className="text-xs">ID: {request.id}</p>
            </div>
            {/*PRICE && AREA */}
            <div className="md:flex md:items-center md:justify-between">
                <div>
                    <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.max_budget')}</h1>
                    <div className="flex font-bold justify-between text-sm">
                        {(parseFloat(request.price_until) > 0)
                        ? `${request.price_until} €`
                        : t('Dashboard.my_requests.no_limit')}
                    </div>
                </div>
                <div>
                    <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.useful_area')}</h1>
                    <div className="flex font-bold justify-between text-sm">
                        {parseFloat(request.area_from) > 0
                        ? `${request.area_from} m²`
                        : t('Dashboard.my_requests.no_minimum')}
                    </div>
                </div>
            </div>
            {/* SETTINGS */}
            <div className="space-y-1">
                <div className="col-span-1 h-full flex items-center justify-around flex-col gap-3">
                    <div className="flex xl:flex-row flex-col items-center xl:justify-around justify-center w-full relative">
                        {request.top_highlighted === true ||
                        request.color_highlighted === true ? (
                            request.top_highlighted === true ? (
                            <h1 className="font-bold text-primary text-lg">
                                Top Highlighted
                            </h1>
                            ) : (
                            <h1 className="font-bold text-primary text-lg">
                                Color Highlighted
                            </h1>
                            )
                        ) : (
                            <button
                                disabled={true}
                                onClick={() => {
                                    // setSelectForHighlightRequest(request.id);
                                    // setCreateHighlightsModal("");
                                }}
                                className="btn-primary font-bold text-white text-sm py-2 px-5 w-full rounded-md shadow-xl"
                            >
                                {t("Dashboard.my_requests.highlight")}
                            </button>
                        )}
                    </div>
                    <div className="flex justify-around w-full">
                        <div className="flex text-fifth font-bold xl:text-2xl text-xl items-center">
                            <HiOutlineMail />
                            <h1 className="mx-1 xl:text-xl text-lg">{request.chats_count}</h1>
                        </div>
                        <div className="flex text-fifth font-bold xl:text-2xl text-xl items-center">
                            <TbHandClick />
                            <h1 className="mx-1 xl:text-xl text-lg">{request.clicks}</h1>
                        </div>
                        <div className="flex text-fifth font-bold xl:text-2xl text-xl items-center">
                            <AiOutlineEye />
                            <h1 className="mx-1 xl:text-xl text-lg">{request.views}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* DESKTOP */}
        <div className="2xl:py-10 xl:py-8 py-5 lg:h-fit h-40 2xl:px-10 xl:px-8 px-5 bg-white lg:rounded-[3rem] rounded-[2rem] w-full my-5 shadow-custom lg:grid grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 hidden">
            {/*PROPERTY*/}
            <img
                src={`/svg/properties/${request.property_type_image}`}
                alt="Property Type Icon"
                className="col-span-1 w-3/4"
            />
            <div className="space-y-4 col-span-1">
                <h1 className="2xl:text-xl text-base font-bold text-black/60">
                {currentLanguage() === 'en' ? `${request.property_type_name} (${request.typology_name})` : `${request.property_type_name_pt} (${request.typology_name_pt})`}
                </h1>
                <div className="flex text-primary items-center">
                    <div className="lg:mr-2">
                        <img
                            src={LocationIcon}
                            alt="Location Icon"
                            className="text-primary lg:w-[24px] w-[18px]"
                        />
                    </div>
                    <p className="lg:text-base text-sm">{t('Dashboard.my_requests.in')} {
                        Object.keys(request.locations).map((key, index) => { 
                            return `${request.locations[key].name}${index != Object.keys(request.locations).length - 1 ? ", " : ""}`
                        })
                    }</p>
                </div>
                <p className="bg-primary font-bold text-white rounded-full text-md lg:block hidden text-center w-fit px-2 whitespace-nowrap">
                    {currentLanguage() === 'en' ? request.request_type_name : request.request_type_name_pt}
                </p>
                <p className="pt-4 text-base">ID: {request.id}</p>
            </div>
            {/*AMENITIES & DESCRIPTION*/}
            <div className="lg:flex hidden flex-col justify-between xl:px-5 px-2 col-span-2 xl:col-span-3 2xl:col-span-4">
                <div className="2xl:text-lg text-md space-y-1">
                    <h1 className="break-words line-clamp-2">
                        {request.brief_description}
                    </h1>
                    <h1 className="break-words line-clamp-2">
                        {request.detailed_description}
                    </h1>
                    <button
                        onClick={() => onDetails() }
                        className="text-primary inline-block"
                    >
                        <BsFillPlusCircleFill />
                    </button>
                </div>
                <div className="flex flex-col xl:flex-row items-start xl:justify-between w-full gap-2 xl:gap-0">
                    <div className="flex justify-start flex-wrap gap-2">
                        {request?.amenities?.map(
                            (amenity, index) =>
                                index < 3 && (
                                <div
                                    key={index}
                                    className=" bg-lightPrimary rounded-full text-sm px-2"
                                >
                                    { currentLanguage() === 'en' ? amenity.name : amenity.name_pt }
                                </div>
                            )
                        )}
                        <div className="bg-lightPrimary rounded-full text-sm px-2">
                            {parseFloat(request.area_from) > 0
                            ? `${request.area_from} m²`
                            : t('Dashboard.my_requests.no_minimum')}
                        </div>
                    </div>
                    {request.amenities && (
                        <button
                            onClick={() => { onViewAmenities() }}
                            className="underline text-sm xl:text-md whitespace-nowrap"
                        >
                            {t('Dashboard.my_requests.view_all')}
                        </button>
                    )}
                </div>
            </div>
            {/*PRICE & REPORT*/}
            <div className="flex flex-col justify-between items-end col-span-1 mr-6">
                <div className="text-right lg:flex hidden h-full flex-col">
                    <h1 className="text-md">{t('Dashboard.my_requests.max_budget')}</h1>
                    <div className="font-bold 2xl:text-lg mb-10 whitespace-nowrap">
                        <p>
                            {(parseFloat(request.price_until) > 0)
                            ? `${request.price_until} €`
                            : t('Dashboard.my_requests.no_limit')}
                        </p>
                    </div>
                </div>
            </div>
            {/* SETTINGS */}
            <div className="hidden lg:flex items-center justify-center gap-x-5 col-span-1 relative">
                {hasSettings &&
                    <div className="absolute ml-auto top-0 right-0">
                        <IoSettingsOutline
                            onClick={() => { setOpenSettings(!openSettings) }}
                            className="text-primary text-3xl xl:text-4xl cursor-pointer"
                        />
                        {openSettings &&
                            <div className="flex flex-col items-start text-primary text-lg absolute top-12 right-0 bg-white rounded-lg w-32 shadow-custom z-[100] overflow-hidden">
                                <button
                                    onClick={() => {
                                        onArchive();
                                    }}
                                    className="w-full px-4 py-2 text-start hover:bg-secondary"
                                >
                                    {request.archived
                                    ? t("Dashboard.admin.unarchive")
                                    : t("Dashboard.admin.archive")}
                                </button>
                                <Link
                                    to={`/app/my-requests/edit/${request.id}`}
                                    className="w-full px-4 py-1 hover:bg-secondary"
                                >
                                    {t("Dashboard.admin.edit")}
                                </Link>
                                {request.archived == 1 &&
                                <button
                                    onClick={() => {
                                        onDelete();
                                    }}
                                    className="w-full px-4 py-2 text-start hover:bg-secondary"
                                >
                                    {t("Dashboard.admin.delete")}
                                </button>
                                }
                            </div>
                        }
                    </div>
                }
                <div className="col-span-1 h-full flex flex-col items-center justify-end gap-y-10">
                    <div className="flex xl:flex-row flex-col items-center xl:justify-around justify-center w-full relative">
                        {request.top_highlighted === true ||
                        request.color_highlighted === true ? (
                            request.top_highlighted === true ? (
                            <h1 className="font-bold text-primary text-lg">
                                Top Highlighted
                            </h1>
                            ) : (
                            <h1 className="font-bold text-primary text-lg">
                                Color Highlighted
                            </h1>
                            )
                        ) : (
                            <button
                            disabled={true}
                            onClick={() => {
                                // setSelectForHighlightRequest(request.id);
                                // setCreateHighlightsModal("");
                            }}
                            className="btn-primary font-bold text-white xl:text-lg py-2 px-5 xl:w-auto w-full rounded-md shadow-xl"
                            >
                            {t("Dashboard.my_requests.highlight")}
                            </button>
                        )}
                    </div>
                    <div className="flex justify-between w-full">
                        <div className="flex text-fifth font-bold xl:text-2xl text-xl items-center">
                            <HiOutlineMail />
                            <h1 className="mx-1 xl:text-xl text-lg">{request.chats_count}</h1>
                        </div>
                        <div className="flex text-fifth font-bold xl:text-2xl text-xl items-center">
                            <TbHandClick />
                            <h1 className="mx-1 xl:text-xl text-lg">{request.clicks}</h1>
                        </div>
                        <div className="flex text-fifth font-bold xl:text-2xl text-xl items-center">
                            <AiOutlineEye />
                            <h1 className="mx-1 xl:text-xl text-lg">{request.views}</h1>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        </>
    )
}

export default RequestCard;