import CartStore from "../../../components/Dashboard/Store/CartStore";
import ScrollToTop from "../../../components/Router/ScrollToTop";

const Cart = () =>
{
    return (
        <>
            <ScrollToTop />
            <CartStore />
        </>
    )
}

export default Cart;