import { useEffect, useState, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import InputMask from "react-input-mask";
import Select from "../../Inputs/Select.js";
import PropertyTypeCard from "./PropertyTypeCard.js";

const PropertyType = ({ formData, formOptions, onSubmit }) => {

    const customSelect = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: "2px",
            borderColor: "#e5e7eb",
            width: "100%",
            borderRadius: "0.375rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
        }),
    };

    const { t } = useTranslation();
    const { auth } = useAuth();

    const [requestTypes, setRequestTypes] = useState([]);
    const [selectedRequestType, setSelectedRequestType] = useState({ id: 1 });
    const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(formData?.propertyTypes ?? []);
    const [properties, setProperties] = useState([]);

    const handlePropertyTypes = (id) => {
        if (selectedPropertyTypes.includes(id)) {
            let selectedPropertyTypesAux = selectedPropertyTypes;
            selectedPropertyTypesAux.splice(selectedPropertyTypesAux.indexOf(id), 1);
            setSelectedPropertyTypes((current) => [...selectedPropertyTypesAux]);
        } else {
            setSelectedPropertyTypes((current) => [...current, id]);
        }
    };

    const handleSubmit = () => {
        onSubmit({
            requestType: selectedRequestType,
            propertyTypes: selectedPropertyTypes
        })
    }
    
    useEffect(() => {
        setProperties(formOptions?.propertyTypes)
        setRequestTypes(formOptions?.requestTypes);
        if (formOptions.requestTypes?.length > 0) {
            setSelectedRequestType(formData?.requestType ?? formOptions?.requestTypes[0]);
        }

    }, [formOptions])

    return (
        <>
            <h1 className="text-center text-2xl md:text-4xl font-bold mb-2 md:mb-5">
                {t("Dashboard.my_requests.make_a")}{" "}
                {t("Dashboard.my_requests.request")}
            </h1>

            <h2 className="text-primary md:text-xl font-bold text-center">
                {t("Dashboard.my_requests.what_property_looking_for")}
            </h2>
                
            <form className="lg:w-[50vw] w-11/12 md:mt-14 mt-5">
                <div className="md:w-[60%] w-full m-auto">
                    <p className="mb-4">
                        {t("Dashboard.my_requests.type_of_request")}
                    </p>
                    <Select
                        value={selectedRequestType}
                        options={requestTypes}
                        onChange={(e) => {
                            setSelectedRequestType(e);
                        }}
                        style={customSelect}
                    />
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4 xl:mt-36 lg:mt-20 mt-10">
                    {properties &&
                        properties?.map((property, index) => (
                            <PropertyTypeCard
                                key={index}
                                property={property}
                                selectedProperties={selectedPropertyTypes}
                                onClick={(e) => handlePropertyTypes(e) }
                            />
                        ))
                    }
                </div>
            </form>

            <button
                type="button"
                onClick={() => { handleSubmit() }}
                className="md:mt-20 mt-10 md:w-[30%] w-full py-2 bg-primary font-bold text-white text-xl rounded-md"
            >
                {t("Dashboard.my_requests.continue")}
            </button>
        </>
    )
}

export default PropertyType;