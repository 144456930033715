import CreatableSelect from "react-select/creatable";
import { currentLanguage } from "../Config/i18n";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const Select = ({ value = null, placeholder = null, options, style, onChange, disabled = false }) =>
{
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(options?.length === 0);
    }, [options])
    
    return (
        <CreatableSelect
            allowCreateWhileLoading={false}
            isLoading={isLoading}
            placeholder={t(placeholder)}
            getOptionValue={option => option.id}
            getOptionLabel={option => currentLanguage() === 'en' ? option.name : option.name_pt ?? option.name }
            options={options}
            onChange={(e) => { onChange(e) }}
            styles={style}
            value={ value && {id: value?.id, name: value?.name, name_pt: value?.name_pt ?? value?.name }}
            isDisabled={disabled}
        />
    )
}

export default Select;