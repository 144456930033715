import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { STORE_TABS_PATH } from '../../../constants/global';
import { MdKeyboardArrowUp } from "react-icons/md";

const Requests = () => {

    const { t } = useTranslation();
    const location = useLocation();

    const [state, setState] = useState(0);
    const [adds, setAdds] = useState('md:text-2xl text-xl bg-primary text-white md:py-4 py-2 md:px-10 px-5 rounded-2xl shadow-xl mr-2');
    const [plans, setPlans] = useState('md:text-2xl text-xl text-fifth md:py-4 py-2 md:px-10 px-5 rounded-2xl mx-2');

    const urlHasPath = (path) => {
        if(location.pathname.includes(path)) {
            return location.pathname;
        }
    }

    useEffect(() => {
        switch (location.pathname) {
            case urlHasPath(STORE_TABS_PATH.ADDONS_STORE):
                setAdds('md:text-2xl text-xl bg-primary text-white md:py-4 py-2 md:px-10 px-5 rounded-2xl shadow-xl mr-2');
                setPlans('md:text-2xl text-xl text-fifth md:py-4 py-2 md:px-10 px-5 rounded-2xl mx-2');
            break;
            case urlHasPath(STORE_TABS_PATH.PLANS_STORE):
                setPlans('md:text-2xl text-xl bg-primary text-white md:py-4 py-2 md:px-10 px-5 rounded-2xl shadow-xl mr-2');
                setAdds('md:text-2xl text-xl text-fifth md:py-4 py-2 md:px-10 px-5 rounded-2xl mx-2');
            break;
            default:
                setAdds('md:text-2xl text-xl bg-primary text-white md:py-4 py-2 md:px-10 px-5 rounded-2xl shadow-xl mr-2');
                setPlans('md:text-2xl text-xl text-fifth md:py-4 py-2 md:px-10 px-5 rounded-2xl mx-2');
            break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <>
            <section>
                <div className='flex flex-wrap md:justify-start justify-between items-center pb-10'>
                {urlHasPath(STORE_TABS_PATH.CART_STORE) ? (
                    <Link to={ STORE_TABS_PATH.ADDONS_STORE }>
                        <MdKeyboardArrowUp className="text-primary text-6xl -rotate-90 border-2 rounded-full p-1 border-primary" />
                    </Link>
                    ) : (
                    <>
                        <Link to="addons" className={adds}>{t('Dashboard.store.addons')}</Link>
                        <Link to="plans" className={plans}>{t('Dashboard.store.plans')}</Link>
                    </>    
                    )}
                </div>
                <Outlet />
            </section>
        </>
    )
}

export default Requests;