import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ScrollToTop from "../components/Router/ScrollToTop";
import TermsConditionsComp from "../components/TermsConditions";

const TermsConditions = () => {

    return (
    <>
        <ScrollToTop />
        <Navbar />
        <TermsConditionsComp />
        <Footer />
    </>
    );
};

export default TermsConditions;
