import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LocationIcon from '../../../assets/Dashboard/Requests/RequestLocationIcon.png';
import { BsCheck2All } from 'react-icons/bs';
import { BsCheck2Circle } from 'react-icons/bs';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Modal from '../../Modals/RequestInfo';
import ShowAmenities from '../../Modals/ShowAmenities';
import SearchForm from '../SearchForm';
import { useAlert } from '../../../contexts/AlertContext';
import axios from '../../Config/axios';
import { useTranslation } from 'react-i18next';
import { currentLanguage } from "../../Config/i18n";
import DuplicateCard from './DuplicateCard';
import RequestCard from './RequestCard';

const Requests = () => {

    const { t } = useTranslation();
    const { toggleLoader } = useAlert();

    const [requests, setRequests] = useState([]);
    const [duplicatesInfo, setDuplicatesInfo] = useState(null);
    const [requestInfoModal, setRequestInfoModal] = useState('hidden');
    const [showAmenitiesModalClass, setShowAmenitiesModalClass] = useState('hidden');

    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({ per_page: 10, page: currentPage, filter: 3});
    const [isLoading, setIsLoading] = useState(false);

    const getDuplicates = async () => {
		setIsLoading(true);
		const response = await axios.get("/api/duplicates", {
			params: searchParams,
		});
		if (response.status === 200) {            
			setRequests(response.data);
		} else {
            console.log(response);
        }
        setIsLoading(false);
	};

    const sevenDaysAgoTimestamp = new Date();
    sevenDaysAgoTimestamp.setDate(sevenDaysAgoTimestamp.getDate() - 7);

    const checkNewDuplicates = (arrayDuplicates) => {
        let numberOfNew = 0;
        arrayDuplicates.forEach((duplicate, index) => {
            if (duplicate.created_at > sevenDaysAgoTimestamp) {
                numberOfNew++;
            }
        });
        return numberOfNew;
    }

    const showRequestInfoModal = () => {
        setRequestInfoModal('hidden');
    }
    const showAmenitiesModal = () => {
        setShowAmenitiesModalClass('hidden');
    }
    const showAmenitiesModalFromModal = () => {
        setShowAmenitiesModalClass('');
    }

    useEffect(() => {
        getDuplicates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
        <>
            <div className={requestInfoModal}>
                <Modal showmodal={showRequestInfoModal} showAmenities={showAmenitiesModalFromModal} />
            </div>

            <div className={showAmenitiesModalClass}>
                <ShowAmenities showAmenitiesModal={showAmenitiesModal} />
            </div>

            <section>
                <div className='w-full flex justify-between items-center py-5 border-b-2'>
                    <h1 className='text-3xl font-bold text-black'>{t('Dashboard.duplicates.title')}</h1>
                </div>
                <SearchForm searchRequests={ (data) => { setSearchParams({...searchParams, ...data}) }} hasAdditionalFields />

                {requests &&
                    requests.map((request, index) => (
                        <RequestCard key={index} request={request} />
                    ))
                }
            </section>
        </>
    )
}

export default Requests;