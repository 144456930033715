import React from "react";
import { API_PATHS } from "../../../constants/global";
import { useTranslation } from 'react-i18next';

const AuthUserCard = ({ currentUser }) => {

	const { t } = useTranslation();

	return (
		<>
			{
				<div className="md:flex hidden xl:px-28 lg:px-10 md:px-5 pt-20 justify-between items-center">
					<div className="flex items-center">
						<div className="xl:h-[120px] xl:w-[120px] w-[100px] h-[100px] mr-5 rounded-full">
							<img
								src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${currentUser.profile_image}`}
								alt="User Profile"
								className="w-full h-full rounded-full object-cover"
							/>
						</div>
						<div className="flex flex-col">
							<h1 className="font-bold xl:text-3xl text-xl">{currentUser.first_name}</h1>
						</div>
					</div>
					<div className="flex flex-col">
						<h1 className="xl:text-xl text-lg font-bold">{t('Dashboard.team.contact_details')}</h1>
						<p className="xl:text-xl text-lg">{t('Dashboard.team.phone')}: {currentUser.phone}</p>
						<p className="xl:text-xl text-lg">{currentUser.email}</p>
					</div>
					<div className="flex flex-col">
						<h1 className="xl:text-xl text-lg font-bold">{t('Dashboard.team.occupation')}</h1>
						<p className="xl:text-xl text-lg">{currentUser.occupation}</p>
					</div>
				</div>
			}
		</>
	);
};

export default AuthUserCard;
