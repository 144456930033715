import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [hasMessage, setHasMessage] = useState(false);
    const [hasNotifications, setHasNotifications] = useState(false);
    const [location, setLocation] = useState(null);

    return (
        <AuthContext.Provider value={{ auth, setAuth, hasMessage, setHasMessage, hasNotifications, setHasNotifications, location, setLocation }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;