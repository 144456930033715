import { useTranslation } from "react-i18next";
import { currentLanguage } from '../../Config/i18n';
import { IoIosNotificationsOutline } from "react-icons/io";

const NotificationCard = ({ notification, isFirst = false }) => 
{

    const { t } = useTranslation();

    return (
        <div className={`${!isFirst && 'border-t-2'} flex flex-col md:flex-row md:items-center gap-2 md:gap-10 py-5 p-4 md:p-10 w-full`}>
            <div className="flex items-center justify-between md:justify-normal w-full md:w-fit md:gap-2">
                <div className={`${notification?.seen && 'opacity-0'} bg-green-500 rounded-full w-3 h-3 md:w-4 md:h-4 shrink-0 hidden md:block`} />
                <div className={`${(notification?.type) == 'buyer_match' ? 'bg-customPurple' : 'bg-primary' } flex items-center justify-center text-white text-4xl md:text-6xl rounded-full w-10 h-10 md:w-20 md:h-20 shrink-0 overflow-hidden`}>
                    {notification?.icon
                        ? (
                            <img src={notification?.icon} alt={(notification?.type?.replace('_', ' '))} />
                        )
                        : (
                            <IoIosNotificationsOutline />
                        )
                    }
                </div>
                <div className="text-primary text-sm md:text-base md:hidden">
                    {notification?.time}
                </div>
            </div>
            <div className="flex-grow">
                <div className="flex items-center justify-between">
                    <h2 className="md:text-lg">{currentLanguage() === 'en' ? notification?.title : notification?.title_pt}</h2>
                    <div className="text-primary text-sm md:text-base hidden md:block">
                        {notification?.time}
                    </div>
                </div>
                <p className="text-disabled text-sm md:text-base">{currentLanguage() === 'en' ? notification?.description : notification?.description_pt}</p>
            </div>
        </div>
    )
}

export default NotificationCard