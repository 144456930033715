import React from 'react';
import AdminHomepage from '../../components/Dashboard/Admin/index';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Homepage = () => {
    return (
        <>
            <ScrollToTop />
            <AdminHomepage />
        </>
    )
}

export default Homepage