import { useState, useEffect } from "react";
import axios from "../../../Config/axios";
import NotificationCard from "../../Notifications/NotificationCard";
import { useTranslation } from "react-i18next";
import { useAlert } from '../../../../contexts/AlertContext';

const NewToolNotification = () => {

    const { t } = useTranslation();
    const { toggleLoader, triggerPopup } = useAlert();

    const [isLoading, setIsLoading] = useState(false);
    const [notificationTemplate, setNotificationTemplate] = useState(null);
    const [mutableNotificationTemplate, setMutableNotificationTemplate] = useState(null);
    const [dataPt, setDataPt] = useState(null);
    const [dataEn, setDataEn] = useState(null);

    const getNotificationTemplate = async () => {
        await axios
            .get(`/api/get-notification-template`, { params: {type: 'new_tool'}})
            .then((response) => {
                setNotificationTemplate(response.data)
                setMutableNotificationTemplate(response.data)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                //
            })
    }

    const sendNotification = async () => {
        setIsLoading(true);
        await axios
            .post(`/api/send-notification`, { notification: mutableNotificationTemplate })
            .then((response) => {
                triggerPopup(t('Popups.notification_sent'))
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        setMutableNotificationTemplate((prevState) => ({
            ...prevState,
            description: (notificationTemplate?.description)?.replace(':data', dataEn ?? ':data'),
            description_pt: (notificationTemplate?.description_pt)?.replace(':data', dataPt ?? ':data')
        }));
    }, [dataEn, dataPt])

    useEffect(() => {
        toggleLoader(isLoading)
    }, [isLoading])

    useEffect(() => {
        getNotificationTemplate()
    }, [])

    return (
        <>
            <p className="mb-2 md:text-lg">
                {t("Dashboard.admin.tool_name_pt")}
            </p>
            <input
                onChange={(e) => {
                    setDataPt(e.target.value);
                }}
                type="text"
                className="w-full border-2 rounded-md py-3 col-span-1 px-2 placeholder:text-black/60 lg:text-lg focus:outline-none"
                placeholder={t("Dashboard.admin.tool_name_pt")}
                required
            />
            
            <p className="mt-4 mb-2 md:text-lg">
                {t("Dashboard.admin.tool_name_en")}
            </p>
            <input
                onChange={(e) => {
                    setDataEn(e.target.value);
                }}
                type="text"
                className="w-full border-2 rounded-md py-3 col-span-1 px-2 placeholder:text-black/60 lg:text-lg focus:outline-none"
                placeholder={t("Dashboard.admin.tool_name_en")}
                required
            />

            <div className="text-black/60 font-bold text-xl py-4">{t('Dashboard.admin.notification_preview')}</div>
            <div className="shadow-custom rounded-3xl">
                <NotificationCard
                    notification={mutableNotificationTemplate}
                    isFirst
                />
            </div>
            
            <div className="flex">
                <button
                    disabled={isLoading}
                    type="submit"
                    className="text-white btn-primary text-xl font-bold px-12 py-2 rounded-lg shadow-lg mx-auto md:ml-auto md:mr-0 mt-5"
                    onClick={sendNotification}
                >
                    {t("Dashboard.admin.send_notification")}
                </button>
            </div>
        </>
    )
}

export default NewToolNotification;