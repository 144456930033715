import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { COMPANY_ROLES, SETTINGS_TABS_PATH } from '../../../constants/global';

const Settings = () => {

    const { auth } = useAuth();
    const { t } = useTranslation();

    const location = useLocation();
    const [accountTab, setAccountTab] = useState('text-2xl text-fifth p-5 rounded-2xl mx-2');
    const [companyTab, setCompanyTab] = useState('text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2');
    const [billingTab, setBillingTab] = useState('text-2xl text-fifth p-5 rounded-2xl mx-2');

    const urlHasPath = (path) => {
        if(location.pathname.includes(path)) {
            return location.pathname;
        }
    }

    useEffect(() => {
        switch (location.pathname) {
            case urlHasPath(SETTINGS_TABS_PATH.ACCOUNT_SETTINGS):
            setAccountTab('text-lg md:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
            setCompanyTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            setBillingTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            break;
            case urlHasPath(SETTINGS_TABS_PATH.COMPANY_SETTINGS):
            setCompanyTab('text-lg md:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
            setAccountTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            setBillingTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            break;
            case urlHasPath(SETTINGS_TABS_PATH.BILLING_SETTINGS):
            setBillingTab('text-lg md:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
            setAccountTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            setCompanyTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            break;
            default:
            setCompanyTab('text-lg md:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
            setAccountTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            setBillingTab('text-lg md:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
            break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <>
            {/* TODO converter em componente e usar o portal Modal */}
            {/* <div className={savedChangesModal}>
                <div className='bg-white w-[40vw] flex flex-col items-center justify-between p-20 shadow-custom rounded-2xl text-center'>
                    <h1 className='text-3xl text-black font-bold'>Your changes have been saved successfully</h1>
                    <div className='flex flex-col items-center w-[60%] mt-10'>
                        <button onClick={() => { setSavedChangesModal('hidden') }} className='w-full py-2 bg-black text-white text-xl rounded-lg flex justify-center items-center font-bold my-4'><Link to="/dashboard/settings">Back to Settings</Link></button>
                        <button className='w-full py-2 bg-primary text-white text-xl rounded-lg flex justify-center items-center font-bold my-4'><Link to="/dashboard">Take me to the Dashboard</Link></button>
                    </div>
                </div>
            </div> */}
            <section>
                <div className='flex flex-wrap md:justify-start justify-between items-center pb-10'>
                    {auth.company_role_id === COMPANY_ROLES.ADMIN &&
                        <>
                        <Link to="company" className={companyTab}>{t('Dashboard.settings.company')}</Link>
                        <Link to="billing" className={billingTab}>{t('Dashboard.settings.billing')}</Link>
                        </>
                    }
                    <Link to="account" className={accountTab}>{t('Dashboard.settings.account')}</Link>
                </div>
                <Outlet />
            </section>
        </>
    )
}

export default Settings;