import React from 'react';
import DashboardTeam from '../../components/Dashboard/Team';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Team = () => {
    return (
        <>
            <ScrollToTop />
            <DashboardTeam />
        </>
    )
}

export default Team