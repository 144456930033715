import { t } from "i18next";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import CreatableSelect from "react-select/creatable";
import axios from "../Config/axios";
import ErrorMessage from "../UI/ErrorMessage";

const ManageVideo = ({ video = null, onClose, onSubmit, errors }) => 
{

    const [formData, setFormData] = useState({ 
        id: video?.id ?? 0,
        title: video?.title ?? null,
        youtube_link: video?.youtube_link ?? null,
        theme_id: video?.theme_id ?? null,
        theme_name: video?.theme_name ?? null,
        is_new: video?.is_new ?? 1,
        active: video?.active ?? 1
    });
    const [themes, setThemes] = useState([]);
    
    const creatableSelectStyle = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: "2px",
            borderColor: "#e5e7eb",
            width: "100%",
            borderRadius: "0.5rem"
        })
    }

    const getThemes = async () => {
        try {
            const response = await axios.get('/api/theme');   
            if (response.status === 200)
            {
                setThemes(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getThemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <>
            <div className="rounded-3xl bg-white w-1/4 shadow-custom px-10 py-5 space-y-6">
                <div className="w-full flex justify-between items-center">
                    <h1 className="text-2xl text-primary">{ video ? t('Dashboard.admin.edit_video') : t('Dashboard.admin.add_video') }</h1>
                    <button onClick={onClose} className="text-primary text-4xl mx-3">
                        <AiOutlineClose />
                    </button>
                </div>
                <div>
                    <label className="w-full text-black/50 text-lg">{t('Dashboard.admin.theme_name')}</label>
                    <input
                        onChange={(e) => {
                            setFormData({
                            ...formData,
                            title: e.target.value,
                            });
                        }}
                        defaultValue={video?.title ?? ''}
                        type="text"
                        className="w-full border-2 rounded-lg p-1 px-2"
                    />
                    <ErrorMessage errors={errors?.title} />
                </div>
                <div>
                    <label className="w-full text-black/50 text-lg">{t('Dashboard.admin.youtube_link')}</label>
                    <input
                        onChange={(e) => {
                            setFormData({
                            ...formData,
                            youtube_link: e.target.value,
                            });
                        }}
                        defaultValue={video?.youtube_link ?? ''}
                        type="text"
                        className="w-full border-2 rounded-lg p-1 px-2"
                    />
                    <ErrorMessage errors={errors?.youtube_link} />
                </div>
                <div>
                    <label className="w-full text-black/50 text-lg">{t('Dashboard.admin.theme')}</label>
                    <CreatableSelect
                        value={{id: formData.theme_id, name: formData.theme_name}}
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={themes}
                        onChange={(e) => { setFormData({...formData, theme_id: e.id, theme_name: e.name}) }}
                        styles={creatableSelectStyle}
                    />
                    <ErrorMessage errors={errors?.theme_id} />
                </div>
                <div className="flex justify-between items-center">
                    <p className={`${formData.is_new ? "text-primary" : "text-black/50"} text-lg`}>
                        {t('Dashboard.admin.video_is_new')}
                    </p>
                    <label className="switch">
                        <input
                            onChange={(e) => {
                                setFormData({ ...formData, is_new: e.target.checked });
                            }}
                            type="checkbox"
                            defaultChecked={formData.is_new}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="flex justify-between items-center">
                    <p className={`${formData.active ? "text-primary" : "text-black/50"} text-lg`}>
                        {t('Dashboard.admin.video_active')}
                    </p>
                    <label className="switch">
                        <input
                            onChange={(e) => {
                                setFormData({ ...formData, active: e.target.checked });
                            }}
                            type="checkbox"
                            defaultChecked={formData.active}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
                
                <div className="flex w-full justify-center mt-5">
                    <button
                        onClick={() => {onSubmit(formData)}}
                        className="py-2 px-10 bg-primary text-white text-xl rounded-full ml-auto"
                    >
                        { video ? t('Dashboard.admin.save_changes') : t('Dashboard.admin.create')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ManageVideo;