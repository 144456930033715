import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationIcon from "../../assets/Dashboard/Requests/RequestLocationIcon.png";
import { FaPhoneAlt } from "react-icons/fa";
import { BsFillChatLeftFill } from "react-icons/bs";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { currentLanguage } from "../Config/i18n";

const GuestRequestCard = ({ request }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = () => {
        navigate('/pricing')
    }

    return (
        <>
            {/* MOBILE */}
            <div onClick={ () => handleClick() } className={`lg:hidden p-5 h-fit bg-white rounded-3xl w-full my-5 shadow-custom space-y-4 cursor-pointer`}>
                {/*PROPERTY*/}
                <div className="flex items-start gap-1">
                    <img
                        src={`/svg/properties/${request?.property_type_image}`}
                        alt="Property Type Icon"
                        className="w-16"
                    />
                    <div>
                        <p className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `${request?.property_type_name}` : `${request?.property_type_name_pt}`}
                        </p>
                        <p className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `(${request?.typology_name})` : `(${request?.typology_name_pt})`}
                        </p>
                    </div>
                </div>
                <div className="ml-2">
                    <div className="flex text-primary items-start">
                        <img
                            src={LocationIcon}
                            alt=""
                            className="text-primary w-4 mr-2"
                        />
                        <p className="line-clamp-2 text-sm">{t('Dashboard.my_requests.in')} {
                            Object.keys(request?.locations).map((key, index) => { 
                                return `${request?.locations[key].name}${index != Object.keys(request?.locations).length - 1 ? ", " : ""}`
                            })
                        }</p>
                    </div>
                    <p className="bg-primary font-bold text-white rounded-full text-md lg:block hidden text-center w-fit px-2 whitespace-nowrap">
                        {currentLanguage() === 'en' ? request?.request_type_name : request?.request_type_name_pt}
                    </p>
                    <p className="text-xs">ID: {request?.id}</p>
                </div>
                {/*PRICE && AREA*/}
                <div className="md:flex md:items-center md:justify-between">
                    <div>
                        <p className="font-bold text-black/60">{t('Dashboard.my_requests.max_budget')}</p>
                        <div className="flex font-bold justify-between text-sm">
                            {(parseFloat(request?.price_until) > 0)
                            ? `${request?.price_until} €`
                            : t('Dashboard.my_requests.no_limit')}
                        </div>
                    </div>
                    <div>
                        <p className="font-bold text-black/60 mt-2">{t('Dashboard.my_requests.useful_area')}</p>
                        <div className="flex font-bold justify-between text-sm">
                            {parseFloat(request?.area_from) > 0
                            ? `${request?.area_from} m²`
                            : t('Dashboard.my_requests.no_minimum')}
                        </div>
                    </div>
                </div>
                {/* REQUEST OWNER */}
                <div className={`space-y-1 blur-sm`}>
                    <div className="flex items-center justify-between">
                        <div className="w-12 h-12 rounded-full overflow-hidden">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request?.created_by_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                    </div>
                    <div className="flex items-end justify-between text-sm">
                        <div className="space-y-1">
                            <p className="font-bold">
                                {request?.created_by_name}
                            </p>
                            <div className="flex text-primary items-center gap-x-4">
                                {request?.created_by_plan_name && request?.created_by_plan_name_pt &&
                                    <p className="font-bold">
                                        {currentLanguage() === 'en' ? request?.created_by_plan_name : request?.created_by_plan_name_pt }
                                    </p>
                                }
                                
                                <FaPhoneAlt />
                                <BsFillChatLeftFill />
                            </div>
                        </div>
                        <button className="text-primary font-bold">
                            {t('Dashboard.requests.details')}
                        </button>
                    </div>
                </div>
            </div>

            {/* DESKTOP */}
            <div onClick={ () => handleClick() } className={`2xl:py-10 xl:py-8 py-5 lg:h-fit h-40 2xl:px-10 xl:px-8 px-5 bg-white lg:rounded-[3rem] rounded-[2rem] w-full my-5 shadow-custom lg:grid grid-cols-6 xl:grid-cols-7 2xl:grid-cols-11 hidden cursor-pointer`}>
                {/*PROPERTY*/}
                <img
                    src={`/svg/properties/${request?.property_type_image}`}
                    alt="Property Type Icon"
                    className="col-span-1 m-auto w-3/4"
                />
                <div className="space-y-4 2xl:col-span-2">
                    <p className="2xl:text-xl text-base font-bold text-black/60">
                    {currentLanguage() === 'en' ? `${request?.property_type_name} (${request?.typology_name})` : `${request?.property_type_name_pt} (${request?.typology_name_pt})`}
                    </p>
                    <div className="flex text-primary items-center">
                        <div className="lg:mr-2">
                            <img
                                src={LocationIcon}
                                alt="Location Icon"
                                className="text-primary lg:w-[24px] w-[18px]"
                            />
                        </div>
                        <p className="lg:text-base text-sm">{t('Dashboard.my_requests.in')} {
                            Object.keys(request?.locations).map((key, index) => { 
                                return `${request?.locations[key].name}${index != Object.keys(request?.locations).length - 1 ? ", " : ""}`
                            })
                        }</p>
                    </div>
                    <p className="bg-primary font-bold text-white rounded-full text-md lg:block hidden text-center w-fit px-2 whitespace-nowrap">
                        {currentLanguage() === 'en' ? request?.request_type_name : request?.request_type_name_pt}
                    </p>
                    <p className="pt-4 text-base">ID: {request?.id}</p>
                </div>

                {/*AMENITIES & DESCRIPTION*/}
                <div className="lg:flex hidden flex-col justify-between xl:px-5 px-2 col-span-2 xl:col-span-3 2xl:col-span-3">
                    <div className="2xl:text-lg text-md">
                        <p className="break-words">
                            {request?.brief_description}
                        </p>
                        <button
                            className="text-primary inline-block"
                        >
                            <BsFillPlusCircleFill />
                        </button>
                    </div>
                    <div className="flex flex-col xl:flex-row items-start xl:justify-between w-full gap-2 xl:gap-0">
                        <div className="flex justify-start flex-wrap gap-2">
                            {request?.amenities?.map(
                                (amenity, index) =>
                                    index < 3 && (
                                    <div
                                        key={index}
                                        className=" bg-lightPrimary rounded-full text-sm px-2"
                                    >
                                        { currentLanguage() === 'en' ? amenity.name : amenity.name_pt }
                                    </div>
                                )
                            )}
                            <div className="bg-lightPrimary rounded-full text-sm px-2">
                                {parseFloat(request?.area_from) > 0
                                ? `${request?.area_from} m²`
                                : t('Dashboard.my_requests.no_minimum')}
                            </div>
                        </div>
                        {request?.amenities && (
                            <button
                                className="underline text-sm xl:text-md whitespace-nowrap"
                            >
                                {t('Dashboard.my_requests.view_all')}
                            </button>
                        )}
                    </div>
                </div>
                {/*PRICE & REPORT*/}
                <div className="flex flex-col justify-between items-end col-span-1 2xl:col-span-2 mr-6">
                    <div className="text-right lg:flex hidden h-full flex-col">
                        <p className="font-bold text-md">{t('Dashboard.my_requests.max_budget')}</p>
                        <div className="2xl:text-lg mb-10 whitespace-nowrap">
                            <p>
                                {(parseFloat(request?.price_until) > 0)
                                ? `${request?.price_until} €`
                                : t('Dashboard.my_requests.no_limit')}
                            </p>
                        </div>
                    </div>
                </div>
                {/* DESKTOP */}
                <div className={`hidden 2xl:flex items-center justify-center gap-x-5 col-span-2 2xl:col-span-3 blur-sm`}>
                    <div className="flex flex-col items-end justify-center gap-y-2">
                        <p className="font-bold text-lg">
                            {request?.created_by_name}
                        </p>
                        {/* <p className="font-bold text-primary">{request?.company_name}</p> */}
                        <div className="flex text-primary items-center justify-end gap-x-4 w-full">
                            {request?.created_by_plan_name && request?.created_by_plan_name_pt &&
                                <p className="font-bold">
                                    {currentLanguage() === 'en' ? request?.created_by_plan_name : request?.created_by_plan_name_pt }
                                </p>}
                                <button className="text-lg">
                                    <FaPhoneAlt />
                                </button>
								<button className="text-lg">
									<BsFillChatLeftFill />
								</button>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <div className="w-16 h-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request?.created_by_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                    </div>
                </div>
                {/* LAPTOP */}
                <div className={`flex 2xl:hidden items-center justify-center gap-x-5 col-span-1 blur-sm`}>
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <p className="font-bold text-lg">
                            {request?.created_by_name}
                        </p>                            
                        <div className="w-16 h-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${request?.created_by_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                        <div className="space-y-2">
                            <div className="flex text-primary items-center justify-end gap-x-4 w-full">
                                {request?.created_by_plan_name && request?.created_by_plan_name_pt &&
                                    <p className="font-bold">
                                        {currentLanguage() === 'en' ? request?.created_by_plan_name : request?.created_by_plan_name_pt }
                                    </p>
                                }
                                <button className="text-lg">
                                    <FaPhoneAlt />
                                </button>
                                
                                <button className="text-lg">
                                    <BsFillChatLeftFill />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuestRequestCard;
