import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Steps from '../components/Pricing/Steps/index2';
import FixedHeader from '../components/Pricing/FixedHeader';
import ScrollToTop from '../components/Router/ScrollToTop'
import { Helmet } from 'react-helmet';

const Pricing = () => {

  return (
    <>
      <Helmet>
        <title>Fortimy - Adira</title>
        <meta name="description" content="Siga os nossos passos e garanta o acesso exclusivo ao nosso pacote para pioneiros (oferta válida até 31 de dezembro de 2023)." />
        <meta name="keywords" content="Fortimy, Imobiliário, Imóvel, Comprador, Inquilino, Propriedades, Consultor imobiliário" />
        <meta property="og:title" content="Fortimy - Adira" />
        <meta property="og:description" content="Siga os nossos passos e garanta o acesso exclusivo ao nosso pacote para pioneiros (oferta válida até 31 de dezembro de 2023)." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Fortimy" />
        <meta property="og:locale" content="pt_PT" />
        <meta property="og:url" content="https://fortimy.com/pricing" />
        <meta property="og:image" content="https://fortimy.com/logo2.png" />
        <meta property="og:image:secure_url" content="https://fortimy.com/logo2.png" />
        <meta property="article:publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Fortimy - Adira" />
        <meta name="twitter:description" content="Siga os nossos passos e garanta o acesso exclusivo ao nosso pacote para pioneiros (oferta válida até 31 de dezembro de 2023)." />
        <meta name="twitter:image" content="https://fortimy.com/logo2.png" />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <FixedHeader />
      <Steps/>
    </>
  )
}

export default Pricing;