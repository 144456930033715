import React from "react";
import Navbar from "../components/Navbar";
import CreateRequestComponent from "../components/CreateRequest";
import Footer from "../components/Footer";
import ScrollToTop from "../components/Router/ScrollToTop";
import { Helmet } from "react-helmet";

const CreateRequest = () => {
    return (
        <>
        <Helmet>
            <title>Fortimy - Criar Pedido</title>
            <meta name="description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
            <meta name="keywords" content="Fortimy, Imobiliário, Imóvel, Comprador, Inquilino, Propriedades, Consultor imobiliário" />
            <link rel="canonical" href="https://fortimy.com/" />
            <meta property="og:title" content="Fortimy" />
            <meta property="og:description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Fortimy" />
            <meta property="og:locale" content="pt_PT" />
            <meta property="og:url" content="https://fortimy.com/" />
            <meta property="og:image" content="https://fortimy.com/logo2.png" />
            <meta property="og:image:secure_url" content="https://fortimy.com/logo2.png" />
            <meta property="article:publisher" content="https://www.facebook.com/fortimypt" />
            <meta name="publisher" content="https://www.facebook.com/fortimypt" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Fortimy" />
            <meta name="twitter:description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
            <meta name="twitter:image" content="https://fortimy.com/logo2.png" />
        </Helmet>

        <section>
            <ScrollToTop />
            <Navbar />
            <CreateRequestComponent />
            <Footer />
        </section>
        </>
    )
}

export default CreateRequest;