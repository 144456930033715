import { useTranslation } from "react-i18next";
import { useAlert } from "../../../contexts/AlertContext";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import ToggeablePassword from "../../Inputs/ToggleablePassword";
import axios from "../../Config/axios";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { IoCloseCircleSharp } from "react-icons/io5";
import ErrorMessage from "../../UI/ErrorMessage";
import PhoneInput from "react-phone-input-2";

const AccountSettings = () => 
{
    const { t } = useTranslation();
    const { auth } = useAuth();
    const { toggleLoader, triggerPopup } = useAlert();
    const isMounted = useRef(false);

    const [formData, setFormData] = useState({ });
    const [isLoading, setIsLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [errors, setErrors] = useState([]);

    const [chars, setChars] = useState("col-span-1 text-black/60");
	const [upper, setUpper] = useState("col-span-1 text-black/60");
	const [lower, setLower] = useState("col-span-1 text-black/60");
	const [number, setNumber] = useState("col-span-1 text-black/60");
	const [symbol, setSymbol] = useState("col-span-1 text-black/60");
    const [matchPasswords, setMatchPasswords] = useState("text-xs my-1 col-span-1 text-black/60");

    const submit = async () =>
    {
        const fd = new FormData();
        for (let key in formData) {
            fd.append(key, formData[key]);
        }

        setIsLoading(true);
        await axios.post(`/api/users/${auth?.id}`, fd)
        .then((response) => {
            setHasChanges(false);
            triggerPopup('Popups.changes_saved');
        })
        .catch((e) => {
            setErrors(e.response.data.errors);
            triggerPopup('Popups.something_went_wrong');
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const validatePassword = (passwordInput) => {
		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[!@#$%^&*()_+-={}[\]|\\:;'"<>,.?])/;
		const minLengthRegExp = /.{8,}/;
		const uppercasePassword = uppercaseRegExp.test(passwordInput);
		const lowercasePassword = lowercaseRegExp.test(passwordInput);
		const digitsPassword = digitsRegExp.test(passwordInput);
		const specialCharPassword = specialCharRegExp.test(passwordInput);
		const minLengthPassword = minLengthRegExp.test(passwordInput);

		if (uppercasePassword) {
			setUpper("col-span-1 text-primary");
		} else {
			setUpper("col-span-1 text-black/60");
		}
		if (lowercasePassword) {
			setLower("col-span-1 text-primary");
		} else {
			setLower("col-span-1 text-black/60");
		}
		if (digitsPassword) {
			setNumber("col-span-1 text-primary");
		} else {
			setNumber("col-span-1 text-black/60");
		}
		if (specialCharPassword) {
			setSymbol("col-span-1 text-primary");
		} else {
			setSymbol("col-span-1 text-black/60");
		}
		if (minLengthPassword) {
			setChars("col-span-1 text-primary");
		} else {
			setChars("col-span-1 text-black/60");
		}
	};

    const handleImage = (e) =>
    {
        if(e.target.files[0].size < 1000000) {
            setFormData({...formData, profile_picture: e.target.files[0]});
        } else {
            triggerPopup('Popups.filesize_error');
        }
    }

    const handleRemoveImage = () => {
        document.getElementById('uploadFile').value = null;
        setFormData({...formData, profile_picture: null});
    }

    const handleChange = (field, value) => {
		setFormData({ ...formData, [field]: value });
	};

    const passwordMatch = () => {
        if (formData?.new_password === formData?.password_confirmation && formData?.new_password != "") {
            setMatchPasswords("text-xs my-1 col-span-1 text-primary");
            return true;
        } else {
            setMatchPasswords("text-xs my-1 col-span-1 text-black/60");
            return false;
        }
    }

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if(isMounted.current) {
            if ("current_password" in formData && "new_password" in formData && "password_confirmation" in formData) {
                if (passwordMatch()) {
                    setHasChanges(true);
                } else {
                    setHasChanges(false);
                }
            } else if ("profile_picture" in formData) {
                setHasChanges(true);
            } else if ("first_name" in formData || "last_name" in formData || "phone" in formData) {
                setHasChanges(true);
            }
        } else {
            isMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])
    
    return (
        <>
            <div className='bg-white shadow-custom rounded-2xl md:py-10 py-5 md:px-10 px-5 flex flex-col'>
                <div className='flex flex-col'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.general_information')}</h1>
                    </div>
                    <div className='md:grid flex flex-col grid-cols-1 lg:grid-cols-3 justify-center py-5 md:gap-x-5 md:gap-y-2 xl:gap-28'>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.first_name')}</p>
                            <input
                                type="text"
                                defaultValue={auth?.first_name}
                                onChange={ (e) => handleChange("first_name", e.target.value) }
                                placeholder={t('Dashboard.settings.first_name')}
                                className='border-2 shadow-sm rounded-md p-2'
                            />
                            <ErrorMessage errors={errors.first_name} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.last_name')}</p>
                            <input
                                type="text"
                                defaultValue={auth?.last_name}
                                onChange={ (e) => { handleChange("last_name", e.target.value) }}
                                placeholder={t('Dashboard.settings.last_name')}
                                className='border-2 shadow-sm rounded-md p-2'
                            />
                            <ErrorMessage errors={errors.last_name} />
                        </div>
                        <div className='col-span-2 xl:col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.phone')}</p>
                            <PhoneInput
                                defaultMask={"... ... ..."}
                                alwaysDefaultMask
                                value={`${auth?.phone}`}
                                onChange={(number, country) => {
                                    setFormData({
                                        ...formData,
                                        phone: number.replace(country.dialCode, ""),
                                        phone_country_code: `+${country.dialCode}`,
                                    });
                                }}
                                buttonStyle={{ border: "none", backgroundColor: "#FFFFFF"}}
                                inputStyle={{
                                    border: "none",
                                    fontSize: "16px",
                                    lineHeight: "28px",
                                    width: '100%',
                                    backgroundColor: "#FFFFFF",
                                    color: "#000"
                                }}
                                containerStyle={{
                                    border: "2px solid #e5e7eb",
                                    borderRadius: "6px",
                                    backgroundColor: "#FFFFFF"
                                }}
                                className="py-1 px-2"
                                />
                                <ErrorMessage errors={errors.phone} />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.change_password')}</h1>
                    </div>
                    <div className='md:grid flex flex-col grid-cols-1 lg:grid-cols-3 justify-center py-5 md:gap-x-5 md:gap-y-2 xl:gap-28'>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.current_password')}</p>
                            <ToggeablePassword value={formData?.current_password} onChange={ (value) => handleChange("current_password", value) } placeholder={t('Authentication.password')}/>
                            <ErrorMessage errors={errors.current_password} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.enter_new_password')}</p>
                            <ToggeablePassword value={formData?.new_password} onChange={ (value) => { handleChange("new_password", value); validatePassword(value); }} placeholder={t('Authentication.password')}/>
                            <ErrorMessage errors={errors.new_password} />
                            <div className="text-xs flex mt-1 mb-1 gap-x-4 gap-y-2 flex-wrap">
                                <div className={chars}>{t("Pricing.characters")}</div>
                                <div className={upper}>{t("Pricing.uppercase")}</div>
                                <div className={lower}>{t("Pricing.lowercase")}</div>
                                <div className={number}>{t("Pricing.digit")}</div>
                                <div className={symbol}>{t("Pricing.symbol")}</div>
                                <div className="w-[1px]"></div>
                            </div>
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.repeat_new_password')}</p>
                            <ToggeablePassword value={formData?.password_confirmation} onChange={ (value) => handleChange("password_confirmation", value) } placeholder={t('Authentication.password')}/>
                            <ErrorMessage errors={errors.password_confirmation} />
                            <div className={matchPasswords}>{t("Pricing.matchPassword")}</div>
                        </div>
                    </div>
                </div>

                { !auth?.is_individual &&
                    <div className='flex flex-col pt-5'>
                        <div className='flex justify-start border-b-2'>
                            <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.profile_image')}</h1>
                        </div>
                        <div className='flex flex-col justify-center py-5'>
                            <div className="grid grid-cols-3">
                                <div className='flex flex-col space-y-2 py-5 justify-center items-center col-span-1'>
                                    <button className="w-20 h-20 md:w-24 md:h-24 rounded-full">
                                        <img src={ "profile_picture" in formData
                                            ? formData?.profile_picture == null
                                                ? `${process.env.REACT_APP_BACKEND_URL_IMG}/users/placeholder.png`
                                                : URL.createObjectURL(formData?.profile_picture)
                                            :  `${process.env.REACT_APP_BACKEND_URL_IMG}/users/${auth?.profile_image}`
                                        }
                                        className="object-cover rounded-full w-full h-full"
                                        alt=""
                                        />
                                    </button>
                                    <input onChange={(e) => { handleImage(e) }} type='file' className='w-full hidden' name='logo_image' id="uploadFile" />
                                    <p className='text-sm md:text-base text-black/40'>{t('Dashboard.settings.max_filesize')}</p>
                                </div>
                            </div>
                            <div className='grid grid-cols-4'>
                                <label htmlFor="uploadFile" className="md:col-span-1 col-span-2 flex justify-start items-center cursor-pointer">
                                    <div className='text-xl md:text-3xl mx-2 text-primary'><HiOutlinePencilSquare /></div>
                                    <p className='text-sm md:text-lg'>{t('Dashboard.settings.update_logo')}</p>
                                </label>
                                <button onClick={() => { handleRemoveImage() }} className='md:col-span-1 col-span-2 flex justify-start items-center cursor-pointer'>
                                    <div className='text-2xl md:text-4xl mx-2 text-primary'><IoCloseCircleSharp /></div>
                                    <p className='text-sm md:text-lg'>{t('Dashboard.settings.delete_logo')}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                }

                <div className='md:col-start-4 col-start-3 md:col-span-1 col-span-2 flex justify-end items-center md:mt-0 mt-5'>
                    <button onClick={() => { submit() }}
                        disabled={!hasChanges || isLoading}
                        className='btn-primary text-white font-bold rounded-md md:py-3 py-2 px-5 md:text-xl text-lg'
                    >
                        {t('Dashboard.settings.save_changes')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default AccountSettings;