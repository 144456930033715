import React from 'react';
import Banner from '../../assets/Dashboard/Banner.png';
import DashboardNavbar from '../../components/Dashboard/Navbar';
import DashboardRequests from '../../components/Dashboard/Requests';
import ScrollToTop from '../../components/Router/ScrollToTop';
import useAuth from "../../hooks/useAuth";
import ComingSoon from '../ComingSoon';

const Requests = () => {
    const { auth } = useAuth();

    return (
        <>
            <ScrollToTop />
            <section>
                <DashboardRequests />
            </section>
        </>
    )
}

export default Requests