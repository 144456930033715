const BlockedAccessModal = ({ title, description, onConfirm }) => {
    return (
        <div className='fixed top-0 left-0 h-screen w-screen bg-black/10 flex justify-center items-center'>
            <div className='bg-white rounded-3xl flex flex-col'>
                <div className='w-full p-20 text-center flex flex-col items-center justify-center'>
                    <h1 className='font-bold text-primary text-2xl text-center whitespace-normal'>{title}</h1>
                    <p className='text-black text-xl pt-5 pb-10'>{description}</p>
                    <button onClick={() => { onConfirm() }} className='bg-primary text-white font-bold rounded-lg my-3 w-[50%] py-2'>Log out</button>
                </div>
            </div>
        </div>
    )
}

export default BlockedAccessModal;