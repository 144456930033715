import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "../../contexts/AlertContext.js";
import useAuthContext from "../Config/AuthContext.js";
import axios from "../Config/axios.js";

import { useTranslation } from "react-i18next";
import { AMENITIES } from "../../constants/global.js";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth.js";

import Modal from "../Modals/Modal.js";
import RegisterToSubmitRequest from "../Modals/RegisterToSubmitRequest.js";
import Steps from "../Dashboard/MyRequests/Steps";
import ClientData from "../Dashboard/MyRequests/ClientData.js";
import PropertyType from "../Dashboard/MyRequests/PropertyType"
import Location from "../Dashboard/MyRequests/Location"
import BudgetArea from "../Dashboard/MyRequests/BudgetArea.js";
import Caracteristics from "../Dashboard/MyRequests/Caracteristics.js";
import Description from "../Dashboard/MyRequests/Description.js";

const CreateRequest = () => {

    const { toggleLoader, triggerPopup } = useAlert();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { auth } = useAuth();

    const isMounted = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [fieldRequired, setFieldRequired] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [formData, setFormData] = useState({})
    const [formOptions, setFormOptions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const getFormOptions = async () => {
        setIsLoading(true);
        const response = await axios.get("/api/guest/get-request-form-options");
        if (response.status === 200) {
            setFormOptions(response.data);
        }
        setIsLoading(false);
    };

    const changeStep = (value) => {
        switch (value) {
            case 1:
            step !== 1 && setStep(1);
            break;
            case 2:
            step !== 2 && setStep(2);
            break;
            case 3:
            step !== 3 && setStep(3);
            break;
            case 4:
            step !== 4 && setStep(4);
            break;
            case 5:
            step !== 5 && setStep(5);
            break;
            case 6:
            step !== 6 && setStep(6);
            break;
            default:
            step !== 1 && setStep(1);
            break;
        }
    };

    const submit = async () => {
        setOpenModal(true)
        
        if (formData?.amenities.includes(AMENITIES.MORE)) {
            formData?.amenities.splice(formData?.amenityId.indexOf(AMENITIES.MORE), 1);
        }

        localStorage.setItem('guestRequest', JSON.stringify({
            ...formData,
            areaFrom: formData?.areaFrom.value,
            priceUntil: formData?.priceUntil.value,
        }))
        // await axios
        // .post("/api/guest/requests", {
        //     firstName: formData?.firstName,
        //     lastName: formData?.lastName,
        //     nationality: formData?.nationality,
        //     country_code: formData?.phoneIndex,
        //     phoneNumber: formData?.phoneNumber,
        //     showPhone: formData?.showPhone,
        //     showEmail: formData?.showEmail,
        //     buyerTypeId: formData?.buyerType.id,
        //     urgencyId: formData?.urgency.id,
        //     investmentTypeId: formData?.investmentType.id,
        //     requestTypeId: formData?.requestType.id,
        //     selectedPropertyTypes: formData?.propertyTypes,
        //     zone: formData?.zone,
        //     priceUntil: formData?.priceUntil.value,
        //     areaFrom: formData?.areaFrom.value,
        //     conditionId: formData?.condition.id,
        //     typologyId: formData?.typology.id,
        //     bathroomId: formData?.bathroom.id,
        //     selectedAmenities: formData?.amenities,
        //     brief: formData?.brief,
        //     description: formData?.description,
        // })
        // .then((response) => {
        //     //
        // })
        // .finally(() => {
        //     //
        // });
    };

    const isValidRequiredNumberField = (number) => {
        if (number == null) return true;
        return (
            number !== 0 &&
            !Number.isNaN(Number(number.toString().replaceAll(",", ".")))
        );
    };

    const checkFields = () => {
        switch (step) {
            case 1:
                if (
                    // formData?.firstName?.length === 3 &&
                    // formData?.lastName?.length === 3 &&
                    // formData?.nationality !== "" &&
                    // formData?.phoneIndex !== "" &&
                    // formData?.phoneNumber !== "" &&
                    // formData?.phoneNumber.length === 4 &&
                    formData?.urgency != null &&
                    formData?.investmentType != null &&
                    formData?.buyerType != null
                ) {
                    changeStep(step + 1);
                } else {
                    setFieldRequired(true);
                }
            break;
            case 2:
                if (formData?.requestType !== null && formData?.propertyTypes?.length > 0) {
                    changeStep(step + 1);
                } else {
                    setFieldRequired(true);
                }
            break;
            case 3:
                if (formData?.zone.length <= 0) {
                    setFieldRequired(true);
                } else {
                    changeStep(step + 1);
                }
            break;
            case 4:
                if (
                    isValidRequiredNumberField(formData?.priceUntil.value) &&
                    isValidRequiredNumberField(formData?.areaFrom.value)
                ) {
                    changeStep(step + 1);
                } else {
                    setFieldRequired(true);
                }
            break;
            case 5:
                if (
                    formData?.condition != null &&
                    formData?.typology != null &&
                    formData?.bathroom !== null
                ) {
                    changeStep(step + 1);
                } else {
                    setFieldRequired(true);
                }
            break;
            case 6:
                if (
                    formData?.brief !== "" &&
                    formData?.description !== "" &&
                    formData?.brief.length >= 30 &&
                    formData?.description.length >= 30
                ) {
                    submit();
                } else {
                    setFieldRequired(true);
                }
            break;
            default:
                // if (
                //     formData?.firstName !== "" &&
                //     formData?.lastName !== "" &&
                //     formData?.nationality !== "" &&
                //     formData?.phoneIndex !== "" &&
                //     formData?.phoneNumber !== ""
                // ) {
                //     changeStep(step + 1);
                // } else {
                //     setFieldRequired(true);
                // }
            break;
        }
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (!isVisible) {
            setIsVisible(scrollTop > 100);
        }
    };

    const handleStepSubmission = (data) => {
        setFormData({...formData, ...data})
    }

    useEffect(() => {
        getFormOptions();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            checkFields()
        } else {
            isMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (fieldRequired) {
            triggerPopup(t("Dashboard.my_requests.fields_required"));
            setTimeout(() => {
                setFieldRequired(false);
                clearTimeout();
            }, 2500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldRequired]);

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <>

        <Modal isOpen={openModal}>
            <RegisterToSubmitRequest
                onClose={() => {
                    setOpenModal(false);
                }}
                onRegister={() => {
                    setOpenModal(false);
                    navigate(`/pricing`);
                }}
            />
        </Modal>

        <div className="w-full min-h-screen my-20">
            <div className="flex flex-col justify-center items-center w-full">
                <Steps
                    step={step}
                    previousStep={() => {
                        changeStep(step - 1);
                    }}
                    nextStep={() => {
                        changeStep(step + 1);
                    }}
                    checkFields={() => {
                        checkFields();
                    }}
                />
                {step === 1 && (
                    <ClientData
                        formData={formData}
                        formOptions={formOptions}
                        isIndividual={true}
                        onSubmit={(data) => handleStepSubmission(data)}
                    />
                )}

                {step === 2 && (
                    <PropertyType
                        formData={formData}
                        formOptions={formOptions}
                        onSubmit={(data) => handleStepSubmission(data)}
                    />
                )}

                {step === 3 && (
                    <Location
                        formData={formData}
                        onSubmit={(data) => handleStepSubmission(data)}
                    />
                )}

                {step === 4 && (
                    <BudgetArea
                        formData={formData}
                        formOptions={formOptions}
                        onSubmit={(data) => handleStepSubmission(data)}
                    />
                )}

                {step === 5 && (
                    <Caracteristics
                        formData={formData}
                        formOptions={formOptions}
                        onSubmit={(data) => handleStepSubmission(data)}
                    />
                )}

                {step === 6 && (
                    <Description
                        formData={formData}
                        onSubmit={(data) => handleStepSubmission(data)}
                        errors={errors}
                    />
                )}
            </div>
        </div>
        </>
    )
}

export default CreateRequest