import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../UI/ErrorMessage.js";

const Description = ({ formData, onSubmit, errors }) => {

    const { t } = useTranslation();

    const [brief, setBrief] = useState(formData?.brief ?? "");
    const [description, setDescription] = useState(formData?.description ?? "");

    const handleSubmit = () => {
        onSubmit({
            brief: brief,
            description: description
        })
    }

    return (
        <>
            <h1 className="text-center text-2xl md:text-4xl font-bold mb-2 md:mb-5">
                {t("Dashboard.my_requests.make_a")}{" "}
                {t("Dashboard.my_requests.request")}
            </h1>
            <h2 className="text-primary md:text-xl font-bold text-center">
                {t("Dashboard.my_requests.what_description_for_request")}
            </h2>

            <form className="xl:w-[25vw] lg:w-[40vw] md:w-[50vw] md:mt-14 mt-5">
                <div className="flex items-center relative">
                    <input
                        onChange={(e) => {
                        brief.length <= 200 &&
                            e.target.value.length <= 200 &&
                            setBrief(e.target.value);
                        }}
                        value={brief}
                        type="text"
                        className="my-2 w-full border-2 rounded-md md:py-4 py-2 pl-2 pr-16 placeholder:text-black/60 placeholder:text-xl focus:outline-none"
                        placeholder={t("Dashboard.my_requests.brief_description")}
                    />
                    <span
                        className={`${
                        brief.length < 30 ? "text-red-500" : ""
                        } absolute right-3`}
                    >
                        {brief.length}/200
                    </span>
                </div>
                <p className="text-black/40 ml-auto">
                    {t("Dashboard.my_requests.brief_min_characters")}
                </p>
                <div className="flex items-center relative">
                    <textarea
                        onChange={(e) => {
                        description.length <= 1000 &&
                            e.target.value.length <= 1000 &&
                            setDescription(e.target.value);
                        }}
                        value={description}
                        rows="15"
                        className="my-2 w-full border-2 rounded-md md:py-4 py-5 px-5 placeholder:text-black/60 placeholder:text-xl focus:outline-none"
                        placeholder={t(
                        "Dashboard.my_requests.detailed_description"
                        )}
                    />
                    <span
                        className={`${
                        description.length < 30 ? "text-red-500" : null
                        } absolute right-5 bottom-3`}
                    >
                        {description.length}/1000
                    </span>
                </div>
                <p className="text-black/40 ml-auto">
                    {t("Dashboard.my_requests.detailed_min_characters")}
                </p>
            </form>

            <button
                type="button"
                onClick={() => {
                    handleSubmit();
                }}
                className="md:mt-20 mt-10 xl:w-[25vw] lg:w-[40vw] md:w-[50vw] w-full py-2 bg-primary font-bold text-white text-xl rounded-md"
            >
                {t("Dashboard.my_requests.submit")}
                </button>

            {/* ERROR HERE */}
            <div className="mt-10">
                {errors &&
                Object.keys(errors).map((error, index) => (
                    <ErrorMessage key={index} errors={errors[error]} />
                ))}
            </div>
        </>
    )
}

export default Description