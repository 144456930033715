import { t } from "i18next";
import { AiOutlineClose } from "react-icons/ai";
import ErrorMessage from '../UI/ErrorMessage'
import { useState } from "react";

const EditBuyerMatch = ({ buyerMatch, onClose, onSave, errors }) => 
{

    const [formData, setFormData] = useState({ id: buyerMatch?.id ?? 0, name: buyerMatch?.name ?? null });
    
    return (
        <>
            <div className="relative rounded-3xl bg-white w-3/4 md:w-1/2 shadow-custom px-5 md:px-10 py-5 space-y-6">
                <div className="text-lg md:text-2xl text-primary">{t('Modals.buyer_match.edit_buyer_match')}</div>

                <button onClick={onClose} className="absolute top-0 right-5 text-xl md:text-2xl text-primary">
                    <AiOutlineClose />
                </button>
                
                <div className="space-y-2">
                    <label className="w-full text-black/50 md:text-lg">{t('Modals.buyer_match.name_placeholder')}</label>
                    <input
                        onChange={(e) => {
                            setFormData({
                            ...formData,
                            name: e.target.value,
                            });
                        }}
                        defaultValue={buyerMatch?.name ?? ''}
                        type="text"
                        className="w-full border-2 rounded-lg p-1 px-2"
                    />
                    <ErrorMessage errors={errors?.name} />
                </div>
                <div className="flex w-full justify-center mt-5">
                    <button
                        onClick={() => { onSave(formData) }}
                        className="py-2 px-5 md:px-10 bg-primary text-white md:text-xl rounded-full mx-auto md:ml-auto"
                    >
                        {t('Dashboard.admin.save_changes')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default EditBuyerMatch;