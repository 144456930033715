import React from 'react';
import AdminRequests from '../../components/Dashboard/Admin/Requests';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Requests = () => {
    return (
        <>
            <ScrollToTop />
            <AdminRequests />
        </>
    )
}

export default Requests