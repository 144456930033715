import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import useAuthContext from '../Config/AuthContext';

const CreateHighlights = ({ modalHighlights, requestId }) => {

  const { currentUser, createTopHighlight, createColorHighlight } = useAuthContext();

  const [topHighlights, setTopHighlights] = useState(0);
  const [colorHighlights, setColorHighlights] = useState(0);

  const [nextStep, setNextStep] = useState(0);
  const [selectedBtn, setSelectedBtn] = useState(null);
  const [topClassBtn, setTopClassBtn] = useState("flex flex-col items-center text-xl relative rounded-2xl pt-5");
  const [colourClassBtn, setColourClassBtn] = useState("flex flex-col items-center text-xl relative rounded-2xl pt-5");
  const [selectedTop, setSelectedTop] = useState('hidden');
  const [selectedColour, setSelectedColour] = useState('hidden');

  useEffect(() => {
    if (currentUser) {
      setTopHighlights(currentUser.top_highlights);
      setColorHighlights(currentUser.colour_highlights);
    }
  }, [currentUser])


  useEffect(() => {

    switch (selectedBtn) {
      case 0:
        setTopClassBtn("flex flex-col items-center text-xl relative border-r-2 rounded-2xl bg-primary/10 border-2 border-primary border-b-0 pt-5");
        setSelectedTop('absolute text-base -bottom-8');
        setColourClassBtn("flex flex-col items-center text-xl relative rounded-2xl pt-5");
        setSelectedColour('hidden');
        break;
      case 1:
        setColourClassBtn("flex flex-col items-center text-xl relative border-r-2 rounded-2xl bg-primary/10 border-2 border-primary border-b-0 pt-5");
        setSelectedColour('absolute text-base -bottom-8');
        setTopClassBtn("flex flex-col items-center text-xl relative rounded-2xl pt-5");
        setSelectedTop('hidden')
        break;
      default:
        setTopClassBtn("flex flex-col items-center text-xl relative rounded-2xl pt-5");
        setColourClassBtn("flex flex-col items-center text-xl relative rounded-2xl pt-5");
        break;
    }

  }, [selectedBtn]);

  const createHighlight = async (requestId) => {
    console.log("adeus")
    if (selectedBtn === 0) {
      if (topHighlights > 0) {
        console.log("ola")
        await createTopHighlight(requestId).then(response => console.log(response));
        modalHighlights();
      }
    } else if (selectedBtn === 1) {
      if (colorHighlights > 0) {
        console.log("ola")
        await createColorHighlight(requestId).then(response => console.log(response));
        modalHighlights();
      }
    }
  }

  return (
    <div className='fixed top-0 left-0 z-[100] h-screen w-screen bg-black/10 flex justify-center items-center'>
      <div className='xl:w-[35vw] lg:w-[50vw] w-[90%] bg-white rounded-3xl flex flex-col shadow-custom'>
        <button onClick={() => { modalHighlights(); setNextStep(0) }} className='flex justify-end text-primary text-4xl m-3'><AiOutlineClose /></button>

        {

          nextStep === 0 &&

          <div className='flex flex-col justify-center items-center md:px-20'>
            <h1 className='text-2xl font-bold text-primary text-center'>What kind of highlight <br />do you want to use?</h1>
            <p className='text-xl my-5'>What you have available</p>
            <div className='grid grid-cols-2 w-full items-center'>
              <div className='col-span-1 border-r-2 px-5'>
                <div className={topClassBtn}>
                  <p className=''>Top</p>
                  <p className='font-bold mt-2'>{topHighlights}</p>
                  <button onClick={() => { selectedBtn === 0 ? setSelectedBtn(null) : setSelectedBtn(0) }} className="-mb-1 w-full mt-5 bg-white shadow-xl text-primary text-base border-2 border-primary rounded-full py-1">Top Highlight</button>
                  <span className={selectedTop}>Selected</span>
                </div>
              </div>
              <div className='col-span-1 px-5'>
                <div className={colourClassBtn}>
                  <p className=''>Color</p>
                  <p className='font-bold mt-2'>{colorHighlights}</p>
                  <button onClick={() => { selectedBtn === 1 ? setSelectedBtn(null) : setSelectedBtn(1) }} className="-mb-1 w-full mt-5 shadow-xl bg-white text-primary text-base border-2 border-primary rounded-full py-1">Colour Highlight</button>
                  <span className={selectedColour}>Selected</span>
                </div>
              </div>
            </div>
            <div className='px-20 flex flex-col items-center w-full mt-20 text-xl mb-16'>
              <Link to="/dashboard/store" className='w-full bg-black font-bold text-white rounded-md py-2 text-center'>Add more</Link>
              <button onClick={() => { selectedBtn !== null && setNextStep(1) }} className='w-full bg-primary font-bold text-white rounded-md py-2 mt-8'>Next</button>
            </div>
          </div>

        }

        {

          nextStep === 1 &&

          <div className='flex flex-col justify-center items-center md:px-20'>
            <h1 className='text-2xl font-bold text-primary text-center'>Are you sure you want to <br />highlight this request?</h1>
            <p className='text-xl my-5'>You can't go back on this action</p>
            <div className='md:px-20 flex flex-col items-center w-full mt-20 text-xl mb-16'>
              <button onClick={() => { modalHighlights(); setNextStep(0) }} className='w-full bg-black font-bold text-white rounded-md py-2'>Cancel</button>
              <button onClick={() => { createHighlight(requestId); modalHighlights(); setNextStep(0) }} className='w-full bg-primary font-bold text-white rounded-md py-2 mt-8'>Yes, I'm sure</button>
            </div>
          </div>

        }

      </div>
    </div>
  )
}

export default CreateHighlights