const buyerMatchSVG = () =>
{
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="29.786" viewBox="0 0 33 29.786">
                <path id="Icon_akar-heart-blue" data-name="Icon akar-heart-blue" d="M10.5,4.5A7.463,7.463,0,0,0,3,11.925c0,3.311,1.313,11.167,14.232,19.11a1.478,1.478,0,0,0,1.536,0C31.688,23.093,33,15.236,33,11.925A7.463,7.463,0,0,0,25.5,4.5C21.358,4.5,18,9,18,9S14.642,4.5,10.5,4.5Z" transform="translate(-1.5 -3)" fill="#fff" stroke="#6846f5" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </svg>
        </>
    )
}

export default buyerMatchSVG