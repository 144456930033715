import React, { useEffect, useRef, useState } from 'react';
import NextStepArrow from '../../../assets/Pricing/NextStepArrow.png';
import { useTranslation } from 'react-i18next';
import PlanCard from './PlanCard';
import { PERMANENCE_PERIODS, PLANS, PLAN_TYPES } from '../../../constants/global';

const StepPlan = ({  selectedPlanType, nextStep, prevStep, onPlanSelection }) => {

    const { t } = useTranslation();

    const [selectedPlan, setSelectedPlan] = useState({plan: null, permanencePeriod: null});
    const [fieldRequired, setFieldRequired] = useState(false);
    const [businessBasicPrice, setBusinessBasicPrice] = useState(t('Pricing.step2.business_plan.basic.no_loyalty_price'));
    const [businessElitePrice, setBusinessElitePrice] = useState(t('Pricing.step2.business_plan.elite.no_loyalty_price'));

    const isMounted = useRef(false);

    const individualPlanOptions = [
        {   name: t('Pricing.step2.individual_plan.option_users'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.individual_plan.option_requests'),
            isChecked: true
        }
    ]

    const businessBasicPlanOptions = [
        {   name: t('Pricing.step2.business_plan.basic.option_users'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.basic.option_requests'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.basic.option_duplicates'),
            isChecked: false
        },
        {
            name: t('Pricing.step2.business_plan.basic.option_branding'),
            isChecked: false
        }
    ]
    const businessBasicPermanenceOptions = [
        {   name: t('Pricing.step2.business_plan.permanence_period_options.no_loyalty'),
            value: PERMANENCE_PERIODS.NO_LOYALTY
        },
        {   name: t('Pricing.step2.business_plan.permanence_period_options.6_months'),
            value: PERMANENCE_PERIODS.SIX_MONTHS
        },
    ]

    const businessElitePlanOptions = [
        {   name: t('Pricing.step2.business_plan.elite.option_users'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.elite.option_requests'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.elite.option_duplicates'),
            isChecked: true
        },
        {
            name: t('Pricing.step2.business_plan.elite.option_branding'),
            isChecked: true
        }
    ]
    const businessElitePermanenceOptions = [
        {   name: t('Pricing.step2.business_plan.permanence_period_options.no_loyalty'),
            value: PERMANENCE_PERIODS.NO_LOYALTY
        },
        {   name: t('Pricing.step2.business_plan.permanence_period_options.3_months'),
            value: PERMANENCE_PERIODS.THREE_MONTHS
        },
        {   name: t('Pricing.step2.business_plan.permanence_period_options.6_months'),
            value: PERMANENCE_PERIODS.SIX_MONTHS
        },
    ]

    const handlePlanSelection = (plan, permanencePeriod = null) => {
        let planAux = {plan: null, permanencePeriod: null}
        if (selectedPlan.plan === plan && (selectedPlan.permanencePeriod === permanencePeriod || permanencePeriod == null)) {
            planAux = {plan: null, permanencePeriod: null}
        } else if (permanencePeriod) {
            planAux = {plan: null, permanencePeriod: permanencePeriod}
        } else {
            planAux = {plan: plan, permanencePeriod: permanencePeriod}
        }

        setSelectedPlan(planAux)
        onPlanSelection(planAux)
    }

    const handlePrice = () => {
        if (selectedPlan.plan == PLANS.BUSINESS_BASIC) {
            if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.NO_LOYALTY) {
                setBusinessBasicPrice(t('Pricing.step2.business_plan.basic.no_loyalty_price'))
            }
            else if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.SIX_MONTHS) {
                setBusinessBasicPrice(t('Pricing.step2.business_plan.basic.six_months_price'))
            }
        } else if (selectedPlan.plan == PLANS.BUSINESS_ELITE) {
            if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.NO_LOYALTY) {
                setBusinessElitePrice(t('Pricing.step2.business_plan.elite.no_loyalty_price'))
            }
            else if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.THREE_MONTHS) {
                setBusinessElitePrice(t('Pricing.step2.business_plan.elite.three_months_price'))
            }
            else if (selectedPlan.permanencePeriod == PERMANENCE_PERIODS.SIX_MONTHS) {
                setBusinessElitePrice(t('Pricing.step2.business_plan.elite.six_months_price'))
            }
        }
    }

    useEffect(() => {
        if (fieldRequired) {
            setTimeout(() => {
                setFieldRequired(false);
                clearTimeout();
            }, 1500)
        }
    }, [fieldRequired])

    useEffect(() => {
      handlePrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlan])

    useEffect(() => {
        if(isMounted.current) {
            if (selectedPlan.plan) {
                nextStep()
            }
        } else {
            isMounted.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlan.plan])
    
    return (
        <>
            {fieldRequired && <div className='fixed bottom-20 w-screen flex justify-center'><div className='bg-primary text-white text-lg px-5 py-2 rounded-full border-2 border-white'>Selecione um plano para avançar!</div></div>}

            <section className='flex flex-col justify-center'>
                <div className='flex justify-center lg:my-7'>
                    <button onClick={() => { prevStep() }}><img src={NextStepArrow} alt='Previous Step' className='w-20 lg:w-full' /></button>
                </div>
                <h1 className='text-primary lg:text-xl text-center'><span className='font-bold'>{t('Pricing.step2.title_highlight')}</span>{t('Pricing.step2.title')}</h1>

                <div className="flex lg:flex-row flex-col items-center justify-center mt-12 md:px-20 xl:px-40 gap-8">
                    {selectedPlanType === PLAN_TYPES.INDIVIDUAL ?
                        (
                            <PlanCard
                                title={t('Pricing.step2.individual_plan.card_title')}
                                options={individualPlanOptions}
                                price={t('Pricing.step2.individual_plan.price')}
                                isSelected={ selectedPlan.plan === PLANS.INDIVIDUAL_BASIC }
                                onSelect={() => { handlePlanSelection(PLANS.INDIVIDUAL_BASIC) }}
                            />
                        ) : (
                            <>
                                <PlanCard
                                    title={t('Pricing.step2.business_plan.basic.card_title')}
                                    options={businessBasicPlanOptions}
                                    price={businessBasicPrice}
                                    permanencePeriodOptions={businessBasicPermanenceOptions}
                                    hasVat
                                    has20Discount
                                    isSelected={ selectedPlan.plan === PLANS.BUSINESS_BASIC }
                                    onSelect={(selectedPermanencePeriod) => { handlePlanSelection(PLANS.BUSINESS_BASIC, selectedPermanencePeriod) }}
                                />
                                <PlanCard
                                    title={t('Pricing.step2.business_plan.elite.card_title')}
                                    options={businessElitePlanOptions}
                                    price={businessElitePrice}
                                    permanencePeriodOptions={businessElitePermanenceOptions}
                                    hasVat
                                    isPopular
                                    has20Discount
                                    has35Discount
                                    isSelected={ selectedPlan.plan === PLANS.BUSINESS_ELITE }
                                    onSelect={(selectedPermanencePeriod) => { handlePlanSelection(PLANS.BUSINESS_ELITE, selectedPermanencePeriod) }}
                                />
                            </>
                        )
                    }
                </div>

                <div className='text-primary mx-auto mt-10 mb-20 space-y-4'>
                    <h2 className='font-bold text-2xl lg:text-3xl'>{t('Pricing.step2.disclaimer.title')}</h2>
                    {(selectedPlanType === PLAN_TYPES.INDIVIDUAL) &&
                        <p>{t('Pricing.step2.disclaimer.subtitle')}</p>
                    }
                    {(selectedPlanType !== PLAN_TYPES.INDIVIDUAL) &&
                        <div>
                            <p>{t('Pricing.step2.disclaimer.automatic_payment')}</p>
                            <p>{t('Pricing.step2.disclaimer.vat')}</p>
                            <p>{t('Pricing.step2.disclaimer.cancelling')}</p>
                        </div>
                    }
                </div>

                {/* <div className='flex justify-center my-10 lg:my-20'>
                    <button onClick={() => { selectedPlan.plan ? nextStep() : setFieldRequired(true) }}><img alt='Next Step' className='w-20 lg:w-full' src={NextStepArrowUnselected} /></button>
                </div> */}
            </section>
        </>
    )
}

export default StepPlan;