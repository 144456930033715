import React from 'react';
import ArchivedComponent from '../../../components/Dashboard/MyRequests/Archived';
import ScrollToTop from '../../../components/Router/ScrollToTop';

const MyRequests = () => {
    return (
        <>
            <ScrollToTop />
            <ArchivedComponent />
        </>
    )
}

export default MyRequests