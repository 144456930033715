import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../UI/ErrorMessage";
import useAuthContext from "../Config/AuthContext";
import { useAlert } from "../../contexts/AlertContext";
import { useTranslation } from 'react-i18next';

const EditUser = ({ onClose, user, member, updateTeam }) => {
	const { editUser } = useAuthContext();
	const { triggerPopup, toggleLoader } = useAlert();
	const { t } = useTranslation();

	const [formData, setFormData] = useState({
		id: user.id,
		profile_picture: [],
	});
	const [errors, setErrors] = useState([]);

	const handleProfilePicture = (e) =>
    {
        if(e.target.files[0].size < 1000000) {
            setFormData({
				...formData,
				profile_picture: e.target.files[0] || [],
			});
        } else {
            triggerPopup('Popups.filesize_error');
        }
    }

	const submitForm = async () => {
		toggleLoader(true);

		const fd = new FormData();
		// convert obj to FormData
		Object.entries(formData).forEach(([key, value]) => {
			if ((key == "profile_picture" && value.length != 0) || key != "profile_picture") {
				fd.append(key, value);
			}
		});

		const response = await editUser(user.id, fd);
		if (response.status === 204) {
			toggleLoader(false);
			triggerPopup('Popups.changes_saved');
			updateTeam();
			onClose();
		} else {
			setErrors(response.data.errors);
		}
	};

	return (
		user && (
			<div className="w-screen h-screen z-[100] fixed bg-black/10 top-0 left-0 flex items-center justify-center">
      			<div className="bg-white rounded-3xl w-4/5 lg:max-w-3xl h-4/5 shadow-custom p-5 2xl:p-10 overflow-x-hidden overflow-y-auto">
					<div className="w-full flex justify-between items-center">
						<h1 className="text-lg md:text-2xl text-primary">{t('Dashboard.team.edit_user')}</h1>
						<button onClick={onClose} className="text-primary text-2xl md:text-4xl md:mx-3">
							<AiOutlineClose />
						</button>
					</div>
					<div className="flex flex-col items-center w-full mt-3">
						<div className="flex justify-start w-full border-b-2 pb-1">
							<h1 className="text-lg md:text-2xl">{t('Dashboard.team.profile')}</h1>
						</div>
						<div className="w-full flex flex-col justify-center items-center pt-5 pb-2">
							<button className="w-20 h-20 md:w-24 md:h-24 rounded-full">
								<img
									src={
										formData.profile_picture.length == 0
											? `${process.env.REACT_APP_BACKEND_URL_IMG}/users/${user.profile_image}`
											: URL.createObjectURL(formData.profile_picture)
									}
									className="object-cover rounded-full w-full h-full"
									alt="New User"
								/>
							</button>
							<div className="mt-2 text-center text-sm md:text-base">
								<p className='text-black/40'>{t('Dashboard.team.max_filesize')}</p>
								<label
									htmlFor="uploadFile"
									className="text-primary hover:text-primary/70 text-base cursor-pointer"
								>
									{t('Dashboard.team.change_photo')}
								</label>
							</div>
							
							<input
								className="hidden"
								type="file"
								id="uploadFile"
								onChange={(e) => { handleProfilePicture(e) }}
							></input>
						</div>
						<div className="w-full flex flex-col">
							<div className="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5 mb-2 md:my-5">
								<div>
									<input
										onChange={(e) => {
											setFormData({ ...formData, first_name: e.target.value });
										}}
										type="text"
										className="w-full border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg"
										placeholder="First Name"
										defaultValue={user.first_name}
									/>
									<ErrorMessage errors={errors.first_name} />
								</div>
								<div>
									<input
										onChange={(e) => {
											setFormData({ ...formData, last_name: e.target.value });
										}}
										type="text"
										className="w-full border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg"
										placeholder="Last Name"
										defaultValue={user.last_name}
									/>
									<ErrorMessage errors={errors.last_name} />
								</div>
							</div>
							{/* <input
								onChange={(e) => {
									setFormData({ ...formData, user_type: e.target.value });
								}}
								type="text"
								className="border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 text-lg"
								placeholder="Type of user"
							/>
							<ErrorMessage errors={errors.user_type} /> */}
							<input
								onChange={(e) => {
									setFormData({ ...formData, occupation: e.target.value });
								}}
								type="text"
								className="border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg"
								placeholder="Occupation"
								defaultValue={user.occupation}
							/>
							<ErrorMessage errors={errors.occupation} />
						</div>
					</div>
					<div className="flex flex-col w-full">
						<div className="flex justify-start w-full border-b-2 md:mt-3 mt-1">
							<h1 className="text-lg md:text-2xl">{t('Dashboard.team.access')}</h1>
						</div>
						<div className="relative border-2 rounded-lg w-full mt-3">
						<input
							onChange={(e) => {
								setFormData({ ...formData, email: e.target.value });
							}}
							type="email"
							disabled={user.verified}
							className="w-full placeholder:text-black/50 placeholder:text-lg md:text-lg p-1 rounded-lg"
							placeholder="Email Address"
							defaultValue={user.email}
						/>
						{user.verified && <p className="absolute text-black/50 right-1 top-1/2 -translate-y-1/2">{t('Dashboard.team.verified')}</p>}
						</div>
						<ErrorMessage errors={errors.email} />

						{user.id !== member.id && (
							<div className="w-full flex justify-between items-center mt-3">
								<div className="flex items-center">
									<label className="switch mr-5">
										<input
											onChange={(e) => {
												setFormData({ ...formData, blocked: e.target.checked });
											}}
											type="checkbox"
											defaultChecked={user.blocked ?? formData.blocked}
										/>
										<span className="slider round"></span>
									</label>
									<p className={`${formData.blocked ? "text-primary" : "text-black/40"} text-base`}>
										{t('Dashboard.team.block_access')}
									</p>
								</div>
							</div>
						)}
					</div>
					<div className="flex flex-col w-full">
						<div className="flex justify-start w-full border-b-2 mt-3 pb-1">
							<h1 className="text-lg md:text-2xl">{t('Dashboard.team.contact_details')}</h1>
						</div>
						<input
							onChange={(e) => {
								setFormData({ ...formData, contact_email: e.target.value });
							}}
							type="email"
							className="border-2 rounded-lg placeholder:text-black/50 placeholder:text-lg p-1 md:text-lg w-full mt-3"
							placeholder="Contact Email"
							value={formData.contact_email ?? user.contact_email}
						/>
						<ErrorMessage errors={errors.contact_email} />
						<button
							onClick={() => {
								setFormData({
									...formData,
									contact_email: formData.email ?? user.email,
								});
							}}
							className="underline text-primary text-base w-full text-start mt-1"
						>
							{t('Dashboard.team.same_as_above')}
						</button>
						<PhoneInput
							value={user.phone}
							onChange={(number, country) => {
								setFormData({
									...formData,
									phone: number.replace(country.dialCode, ""),
									phone_country_code: `+${country.dialCode}`,
								});
							}}
							buttonStyle={{ border: "none", backgroundColor: "white" }}
							inputStyle={{
								height: "100%",
								width: "100%",
								border: "none",
								fontSize: "18px",
								lineHeight: "28px",
							}}
							containerStyle={{
								fontSize: "18px",
								lineHeight: "28px",
								padding: "4px",
								border: "2px solid #e5e7eb",
								borderRadius: "8px",
								marginTop: "12px",
							}}
							enableSearch={true}
							disableDropdown={false}
							defaultMask="... ... ..."
							placeholder="Telephone Number 1"
						/>
						<ErrorMessage errors={errors.phone} />
					</div>
					<div className="flex w-full justify-center mt-3">
						<button onClick={submitForm} className="py-2 px-4 md:px-10 lg:px-20 bg-primary text-white text-sm md:text-xl rounded-full">
						{t('Dashboard.team.save_changes')}
						</button>
					</div>
				</div>
			</div>
		)
	);
};

export default EditUser;
