import React from 'react';
import {Link} from 'react-router-dom';
import LogoBranco from '../../../assets/Index/Logo-Branco.png';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const GetStarted = () => {

  const location = useLocation();
  const { t } = useTranslation();

  return (
    <section className='flex flex-col items-center justify-center bg-top bg-no-repeat bg-footerBg md:bg-auto bg-auto h-[490px] bg-white'>
      <h1 className='text-white md:text-5xl text-3xl font-bold pb-8'>{t('Footer.getStartedToday')}</h1>
      <div className='flex md:flex-row flex-col justify-center items-center text-center'>
        {location.pathname !== '/pricing' &&
          <Link to="/pricing" className='bg-white text-primary lg:text-xl py-1 md:px-3 rounded-full shadow-lg md:mx-2 md:w-auto w-[120%] md:my-0 my-2'>{t('Footer.signUp')}</Link>
        }
        {location.pathname !== '/login' &&
          <Link to="/login" className='bg-transparent text-white lg:text-xl border-2 border-white py-1 md:px-3 rounded-full shadow-lg md:mx-2 md:w-auto w-[120%] md:my-0 my-2'>{t('Footer.alreadyHaveAccount')}</Link>
        }
      </div>
      <div className='mt-8 lg:mt-16'>
        <img alt='Fortimy Logo' className='h-[4rem]' src={LogoBranco} />
      </div>
    </section>
  )
}

export default GetStarted