import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ScrollToTop from '../components/Router/ScrollToTop'
import RequestsComp from '../components/Requests';
import { Helmet } from 'react-helmet';

const Requests = () => {

    return (
        <>
            <Helmet>
                <title>Fortimy - Pedidos</title>
                <meta property="og:title" content="Fortimy - Pedidos" />
                <meta property="og:description" content="Siga os nossos passos e garanta o acesso exclusivo ao nosso pacote para pioneiros (oferta válida até 31 de dezembro de 2023)." />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Fortimy" />
                <meta property="og:locale" content="pt_PT" />
                <meta property="og:url" content="https://fortimy.com/pricing" />
                <meta property="og:image" content="https://fortimy.com/logo2.png" />
                <meta property="og:image:secure_url" content="https://fortimy.com/logo2.png" />
                <meta property="article:publisher" content="https://www.facebook.com/fortimypt" />
                <meta name="publisher" content="https://www.facebook.com/fortimypt" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Fortimy - Pedidos" />
                <meta name="twitter:description" content="Siga os nossos passos e garanta o acesso exclusivo ao nosso pacote para pioneiros (oferta válida até 31 de dezembro de 2023)." />
                <meta name="twitter:image" content="https://fortimy.com/logo2.png" />
            </Helmet>

            <ScrollToTop />
            <Navbar />
            <section className="lg:mx-[12vw] pb-20 min-h-[150vh] xl:px-20 md:px-10 px-3 pt-20">
                <RequestsComp />
            </section>
            <Footer />
        </>
    )
}

export default Requests;