import CreatableSelect from "react-select/creatable";
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from "react";
import { RANGE_FILTER } from "../../../constants/global";

const AreaRange = ({ range, requestType = 1, loading, filterMax = true, onChange, clear, changeClear }) => {

    const { t } = useTranslation();

    const areaRangeMinRef = useRef(null);
    const areaRangeMaxRef = useRef(null);

    const [areaFrom, setAreaFrom] = useState(null);
    const [areaFromLabel, setAreaFromLabel] = useState(null);
    const [areaUntil, setAreaUntil] = useState(null);
    const [areaUntilLabel, setAreaUntilLabel] = useState(null);
    const [minimumAreaRange, setMinimumAreaRange] = useState([]);
    const [areaRange, setAreaRange] = useState([]);

    const [minIsOpen, setMinIsOpen] = useState(false);
    const [maxIsOpen, setMaxIsOpen] = useState(false);

    const handleArea = (selectedOption, from = true) => {
        let areaValue = null;
        let newAreaFrom = areaFrom;
        let newAreaUntil = areaUntil;
        if (selectedOption != null) {
            areaValue =
            selectedOption.value != null
                ? selectedOption.value.toString().replaceAll(",", " ")
                : selectedOption.value;
        }
        if (from) {
            if (selectedOption != null) {
                if (Number(areaValue) > Number(areaUntil?.value) && areaUntil?.value != null) {
                    newAreaUntil = {value: null, label: t("Dashboard.my_requests.max")}
                    setAreaUntil(newAreaUntil)
                    setAreaUntilLabel(t("Dashboard.my_requests.max"))
                }
                if (Number.isNaN(Number(areaValue))) {
                    newAreaFrom = {value: null, label: t("Dashboard.my_requests.min")}
                    setAreaFrom(newAreaFrom)
                    setAreaFromLabel(t("Dashboard.my_requests.min"))
                } else {
                    newAreaFrom = selectedOption
                    setAreaFrom(newAreaFrom)
                    setAreaFromLabel(formatLabel(selectedOption.label))
                }
            } else {
                newAreaFrom = selectedOption
                setAreaFrom(newAreaFrom)
            }
        } else {
            if (selectedOption != null) {
                if ((Number(areaValue) < Number(areaFrom?.value) || Number.isNaN(Number(areaValue))) && areaUntil?.value != null) {
                    newAreaFrom = {value: null, label: t("Dashboard.my_requests.min")}
                    setAreaFrom(newAreaFrom)
                    setAreaFromLabel(t("Dashboard.my_requests.min"))
                }
                if (Number.isNaN(Number(areaValue))) {
                    newAreaUntil = {value: null, label: t("Dashboard.my_requests.max")}
                    setAreaUntil(newAreaUntil)
                    setAreaUntilLabel(t("Dashboard.my_requests.max"))
                } else {
                    newAreaUntil = selectedOption
                    setAreaUntil(newAreaUntil)
                    setAreaUntilLabel(formatLabel(selectedOption.label))
                }
            } else {
                newAreaUntil = selectedOption
                setAreaUntil(newAreaUntil)
            }
        }

        if (minIsOpen || maxIsOpen) {
            setMinIsOpen(false)
            setMaxIsOpen(false)
        }

        onChange(newAreaFrom, newAreaUntil)
    };

    const filterRange = () => {
        setMinimumAreaRange([]);
        setAreaRange([]);

        if (requestType) {
            range?.forEach(area => {
                if (area.type === requestType) {
                    setMinimumAreaRange(minimumAreaRange => [...minimumAreaRange, area]);
                    setAreaRange(areaRange => [...areaRange, area]);
                }
            });
        } else {
            let areaOptions = filterByUnique(range, 'value').sort((a, b) => a.value - b.value);
            setMinimumAreaRange(areaOptions);
            setAreaRange(areaOptions);
        }
        setMinimumAreaRange(minimumAreaRange => [{value: null, label: t("Dashboard.my_requests.min")}, ...minimumAreaRange]);
        setAreaRange(areaRange => [{value: null, label: t("Dashboard.my_requests.max")}, ...areaRange]);
    };

    const formatLabel = (value) => {
        if (Number.isNaN(Number(value))) {
            return value;
        }
        let formattedValue = value;

        formattedValue = Intl.NumberFormat("en-US")
            .format(formattedValue)
            .replaceAll(",", " ");

        return formattedValue;
    }

    const filterByUnique = (array, key) =>
    {
        return [...new Map(array?.map(item => [item[key], item])).values()]
    }

    const customStyle = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: "2px",
            borderRadius: "0.375rem",
            borderColor: "#2684ff"
        }),
        menu: (provided, state) => ({
            ...provided,
            marginTop: 0,
            marginLeft: -10,
            borderColor: "#e5e7eb",
            borderRadius: "0 0 0.375rem 0.375rem"
        }),
        container: (provided, state) => ({
            ...provided,
            borderWidth: "1px",
            borderColor: "#e5e7eb",
            borderRadius: "0.375rem 0.375rem 0 0",
            backgroundColor: "white",
            position: "absolute",
            width: "100%",
            padding: 10,
            zIndex: 3,
            marginTop: 8,
        }),
    };

    const DropdownIndicator = ( DropdownIndicatorProps ) => {
        return (
            <components.DropdownIndicator {...DropdownIndicatorProps}>
                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z" fill="currentColor" fillRule="evenodd"></path></svg>
            </components.DropdownIndicator>
        );
    };

    const IndicatorSeparator = ('');

    const handleMinClickOutside = (event) => {
        if (areaRangeMinRef.current && !areaRangeMinRef.current.contains(event.target)) {
            setMinIsOpen(false);
        }
    };
    const handleMaxClickOutside = (event) => {
        if (areaRangeMaxRef.current && !areaRangeMaxRef.current.contains(event.target)) {
            setMaxIsOpen(false);
        }
    };

    const handleClear = (field) => {
        if (field === RANGE_FILTER.MIN_AREA) {
            setAreaFromLabel(null)
            setAreaFrom(null)
        } else if (field === RANGE_FILTER.MAX_AREA) {
            setAreaUntilLabel(null)
            setAreaUntil(null)
        }
        changeClear(field);
    }

    useEffect(() => {
        if (range) {
            filterRange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [range]);

    useEffect(() => {
        if (clear) {
            setAreaFromLabel(null)
            setAreaUntilLabel(null)
            changeClear(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clear]);

    useEffect(() => {
        document.addEventListener('click', handleMinClickOutside, true);
        document.addEventListener('click', handleMaxClickOutside, true);
        return () => {
            document.removeEventListener('click', handleMinClickOutside, true);
            document.removeEventListener('click', handleMaxClickOutside, true);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`${filterMax ? 'md:grid md:grid-cols-2 space-y-2 md:space-y-0 md:gap-2 xl:gap-5 h-full text-black' : ''}`}>

                <div ref={areaRangeMinRef} className="relative">
                    <button
                        onClick={() => setMinIsOpen(!minIsOpen)}
                        className={`${areaFromLabel ? '' : 'text-[#808080]' } flex items-center justify-between rounded-md border-2 border-[#e5e7eb] hover:border-[#b3b3b3] py-[2px] pl-3 text-left w-full h-full duration-200`}
                    >
                        { areaFromLabel ?? t('Dashboard.my_requests.min_area_placeholder')}
                        <div className="flex items-center">
                            { areaFromLabel &&
                                <button onClick={() => {handleClear(RANGE_FILTER.MIN_AREA)}} className="p-2 absolute right-10 z-10">
                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="!fill-[#ccc] !hover:fill-[#666]"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                </button>
                            }
                            <div className="py-2">
                                <div className="border-l border-[#ccc] px-2">
                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill={ minIsOpen ? "#666" : "#ccc"}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                                </div>
                            </div>
                        </div>
                    </button>
                    {minIsOpen &&
                        <CreatableSelect
                            allowCreateWhileLoading={false}
                            isLoading={loading}
                            placeholder={t('Dashboard.my_requests.other')}
                            components={{ DropdownIndicator, IndicatorSeparator }}
                            value={formatLabel(areaFrom)}
                            menuIsOpen
                            options={minimumAreaRange}
                            onChange={(selectedOption) => {
                                handleArea(selectedOption);
                            }}
                            onInputChange={setAreaFrom}
                            escapeClearsValue={false}
                            formatCreateLabel={(inputValue) => {
                                return formatLabel(inputValue)
                            }}
                            styles={customStyle}
                        />
                    }
                </div>

                { filterMax &&
                    <div ref={areaRangeMaxRef} className="relative">
                        <button
                            onClick={() => setMaxIsOpen(!maxIsOpen)}
                            className={`${areaUntilLabel ? '' : 'text-[#808080]' } flex items-center justify-between rounded-md border-2 border-[#e5e7eb] hover:border-[#b3b3b3] py-[2px] pl-3 text-left w-full h-full duration-200`}
                        >
                            { areaUntilLabel ?? t('Dashboard.my_requests.max_area_placeholder')}
                            <div className="flex items-center">
                                { areaUntilLabel &&
                                    <button onClick={() => {handleClear(RANGE_FILTER.MAX_AREA)}} className="p-2 absolute right-10 z-10">
                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="!fill-[#ccc] !hover:fill-[#666]"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                    </button>
                                }
                                <div className="py-2">
                                    <div className="border-l border-[#ccc] px-2">
                                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill={ maxIsOpen ? "#666" : "#ccc"}><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                                    </div>
                                </div>
                            </div>
                        </button>
                        { maxIsOpen &&
                            <CreatableSelect
                                allowCreateWhileLoading={false}
                                isLoading={loading}
                                placeholder={t('Dashboard.my_requests.other')}
                                components={{ DropdownIndicator, IndicatorSeparator }}
                                value={formatLabel(areaUntil)}
                                menuIsOpen
                                options={areaRange}
                                onChange={(selectedOption) => {
                                    handleArea(selectedOption, false);
                                }}
                                onInputChange={setAreaUntil}
                                escapeClearsValue={false}
                                formatCreateLabel={(inputValue) => {
                                    return formatLabel(inputValue)
                                }}
                                styles={customStyle}
                            />
                        }
                    </div>
                }
            </div>
        </>
    )
}
export default AreaRange;