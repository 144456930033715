import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import StoreInfo from "../../Modals/StoreInfo";
import Highlights from '../../../assets/Dashboard/Store/Highlights.png';
import RequestsIcon from '../../../assets/Dashboard/Store/Requests.png';
import Users from '../../../assets/Dashboard/Store/Users.png';
import ApplePay from '../../../assets/Dashboard/Store/ApplePay.png';
import PaymentMethods from '../../../assets/Dashboard/Store/PaymentMethods.png';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from "react-router-dom";
import StoreItem from "./StoreItem";
import { STORE_ITEMS } from "../../../constants/global";

const CartStore = () => 
{    

    const { t } = useTranslation();
    const { auth } = useAuth();

    const [topHighlights, setTopHighlights] = useState(auth.top_highlights_number);
    const [colourHighlights, setColourHighlights] = useState(auth.colour_highlights_number);
    const [myRequests, setMyRequests] = useState(auth.request_number);
    const [users, setUsers] = useState(auth.users_number);

    return (
        <>
            <div className='bg-white shadow-custom rounded-2xl md:py-10 py-5 md:px-10 px-5 space-y-5'>
                <div className='flex justify-start border-b-2 md:pb-10 pb-5'>
                    <h1 className='text-3xl font-bold'>{t('Dashboard.store.cart')}</h1>
                </div>

                <StoreItem
                    image={Highlights}
                    name={t('Dashboard.store.highlights')}
                    quantity={topHighlights}
                    minLimit={auth.top_highlights_number}
                    inCart
                    onChange={(e) => console.log(e)}
                />
                <StoreItem
                    image={Highlights}
                    name={t('Dashboard.store.colour_highlights')}
                    quantity={colourHighlights}
                    minLimit={auth.colour_highlights_number}
                    inCart
                    onChange={(e) => console.log(e)}
                />
                <StoreItem
                    image={RequestsIcon}
                    name={t('Dashboard.store.my_requests')}
                    quantity={myRequests}
                    minLimit={auth.request_number}
                    isRequest
                    inCart
                    onChange={(e) => console.log(e)}
                />
                <StoreItem
                    image={Users}
                    name={t('Dashboard.store.users')}
                    quantity={users}
                    minLimit={auth.users_number}
                    inCart
                    onChange={(e) => console.log(e)}
                />

                <div className="md:w-1/2 space-y-10 mt-10">
                    <div className="flex items-center justify-between md:w-1/3">
                        <div>
                            <p className="font-bold text-xl">{t('Dashboard.store.number_items')}</p>
                            <p>22</p>
                        </div>
                        <div>
                            <p className="font-bold text-xl">{t('Dashboard.store.total')}</p>
                            <p>XXX€</p>
                        </div>
                    </div>

                    <div className="space-y-5">
                        <p className="font-bold text-xl">{t('Dashboard.store.payment_method')}</p>
                        <div className="flex items-center gap-x-10">
                            <div className="flex items-center justify-center bg-black rounded-2xl w-60 h-16">
                                <img src={ApplePay} alt="apple pay"/>
                            </div>
                            <div className="flex items-center justify-center bg-white rounded-2xl w-60 h-16">
                                <img src={PaymentMethods} alt={t('Dashboard.store.payment_method')} />
                            </div>
                        </div>

                        <p className="text-primary">{t('Dashboard.store.apple_pay_notice')}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CartStore;