import React from "react";

const FaqCard = ({ index, imagePath, title, setSelectedModal }) => {
  return (
    <>
      <button
        onClick={() => {
          setSelectedModal(index);
        }}
        className="relative"
      >
        <img className="w-full object-cover" alt={`Faq${index}`} src={imagePath} />
        <div className="absolute px-5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full">
          <h1 className="xl:text-3xl lg:text-xl md:text-2xl text-2xl font-bold text-white">
            {title}
          </h1>
        </div>
      </button>
    </>
  );
};

export default FaqCard;
