import React, { useState, useEffect} from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ADMIN_TABS_PATH } from "../../../constants/global";
import { useTranslation } from "react-i18next";

export default function Dashboard() {

  const { t } = useTranslation();
  const location = useLocation();
  const [logbook, setLogbook] = useState('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2');
  const [usersTab, setUsersTab] = useState('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2');
  const [companies, setCompanies] = useState('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2');
  const [requests, setRequests] = useState('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2');
  const [learning, setLearning] = useState('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2');
  const [notifications, setNotifications] = useState('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2');
  // const [info, setInfo] = useState('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2');

  const urlHasPath = (path) => {
    if(location.pathname.includes(path)) {
      return location.pathname;
    }
  }

  useEffect(() => {

    switch (location.pathname) {
      case urlHasPath(ADMIN_TABS_PATH.MANAGE_USERS):
        setUsersTab('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
        setLogbook('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setCompanies('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        // state !== 0 && setInfo('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setRequests('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLearning('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setNotifications('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        break;
      case urlHasPath(ADMIN_TABS_PATH.LOGBOOK):
        setLogbook('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2');
        setUsersTab('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setCompanies('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        // state !== 1 && setInfo('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setRequests('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLearning('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setNotifications('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        break;
      case urlHasPath(ADMIN_TABS_PATH.MANAGE_COMPANIES):
        setCompanies('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
        setUsersTab('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLogbook('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        // state !== 2 && setInfo('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setRequests('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLearning('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setNotifications('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        break;
      case urlHasPath(ADMIN_TABS_PATH.MANAGE_REQUESTS):
        setRequests('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
        setUsersTab('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLogbook('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setCompanies('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLearning('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setNotifications('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        // state !== 3 && setInfo('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        break;
      case urlHasPath(ADMIN_TABS_PATH.MANAGE_LEARNING):
        setLearning('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
        setUsersTab('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLogbook('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setCompanies('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setRequests('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setNotifications('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        // state !== 3 && setInfo('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        break;
      case urlHasPath(ADMIN_TABS_PATH.MANAGE_NOTIFICATIONS):
        setNotifications('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
        setUsersTab('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLogbook('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setCompanies('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setRequests('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLearning('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        // state !== 3 && setInfo('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        break;
      // case 4:
      //   state !== 4 && setState(4);
      //   state !== 4 && setInfo('lg:text-xl xl:text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
      //   state !== 4 && setUsersTab('lg:text-xl xl:text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
      //   state !== 4 && setLogbook('lg:text-xl xl:text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
      //   state !== 4 && setRequests('lg:text-xl xl:text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
      //   state !== 4 && setCompanies('lg:text-xl xl:text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
      //   break;
      default:
        setUsersTab('text-lg 2xl:text-2xl bg-primary text-white p-3 rounded-2xl shadow-xl mr-2 focus:outline-none');
        setLogbook('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setCompanies('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        // state !== 0 && setInfo('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setRequests('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setLearning('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        setNotifications('text-lg 2xl:text-2xl text-fifth p-5 rounded-2xl mx-2 focus:outline-none');
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  
  return (
    <>
        <div className='flex flex-wrap md:justify-start justify-between items-center pb-10'>
          <Link to="users" className={usersTab}>{t('Dashboard.admin.manage_users')}</Link>
          <Link to="logs" className={logbook}>{t('Dashboard.admin.logbook')}</Link>
          <Link to="companies" className={companies}>{t('Dashboard.admin.manage_companies')}</Link>
          <Link to="requests" className={requests}>{t('Dashboard.admin.manage_requests')}</Link>
          <Link to="learning" className={learning}>{t('Dashboard.admin.manage_learning')}</Link>
          <Link to="notifications" className={notifications}>{t('Dashboard.admin.manage_notifications')}</Link>
        </div>

        <Outlet />
    </>
  );
}