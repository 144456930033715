import { Link } from "react-router-dom";
import InboxCard from "./Cards/InboxCard"
import { useTranslation } from "react-i18next";

const Inbox = ({ chats }) =>
{
    const { t } = useTranslation();
    
    const unreadMessages = () => {
        let count = 0;
        if (chats?.data?.length > 0) {
            chats?.data?.map((chat) => 
                count += chat?.unread_messages
            );
        }
        return count;
    }

    return (
        <>
            <div className='w-full flex justify-between items-center py-5 border-b-2'>
                <h1 className='hidden lg:block text-3xl font-bold text-black'>{t('Dashboard.dashboard.inbox')}</h1>
                <Link to={"/app/messages"} className='bg-primary text-white px-5 py-1 shadow-xl font-bold rounded-full text-lg'>
                    {unreadMessages()} {t('Dashboard.dashboard.new_messages')}
                </Link>
            </div>
            
            {chats?.data?.map((chat, index) => (
                (index < 5 && chat.has_messages == 1) &&
                <InboxCard key={index} chat={chat} />
            ))}
            {chats?.data?.length > 1 &&
                <Link to={"/app/messages"} className="bg-white shadow-lg border-2 rounded-3xl font-bold text-primary p-1 px-4 mx-auto w-fit flex">
                    {t('Dashboard.dashboard.show_more')}
                </Link>
            }

            {chats?.data?.length === 0 &&
                <div className="w-full flex justify-center text-primary text-2xl font-bold mt-2">
                    {t("Dashboard.dashboard.no_messages")}
                </div>
            }
        </>
    )
}

export default Inbox;