import { useState, useEffect } from "react";
import axios from "../../../Config/axios";
import { useTranslation } from "react-i18next";
import { useAlert } from '../../../../contexts/AlertContext';
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { IoCloseCircleSharp } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { currentLanguage } from '../../../Config/i18n';
import ErrorMessage from "../../../UI/ErrorMessage";

const CustomNotification = () => {

    const { t } = useTranslation();
    const { toggleLoader, triggerPopup } = useAlert();

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(
        {
            type: null,
            time: '0 min.'
        }
    );
    const [errors, setErrors] = useState({});

    const sendNotification = async () => {
        setErrors({})
        setIsLoading(true);

		const fd = new FormData();
		// convert obj to FormData
		Object.entries(formData).forEach(([key, value]) => {
			if ((key == "icon" && value.length != 0) || key != "icon") {
				fd.append(key, value);
			}
		});

        await axios
            .post(`/api/send-custom-notification`, fd)
            .then((response) => {
                triggerPopup(t('Popups.notification_sent'))
            })
            .catch((e) => {
                setErrors(e.response.data.errors);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleImage = (e) =>
    {
        if(e.target.files[0].size < 1000000) {
            setFormData({...formData, icon: e.target.files[0]});
        } else {
            triggerPopup('Popups.filesize_error');
        }
    }

    const handleRemoveImage = () => {
        document.getElementById('uploadFile').value = null;
        setFormData({...formData, icon: null});
    }

    useEffect(() => {
        toggleLoader(isLoading)
    }, [isLoading])

    return (
        <>
            {/* <p className="mb-2 md:text-lg">
                {t("Dashboard.admin.notification_icon")}
            </p>
            <div className="flex items-center gap-20">
                {formData?.icon == null
                    ? (
                        <div className="flex items-center justify-center bg-primary text-white text-4xl md:text-6xl rounded-full w-10 h-10 md:w-20 md:h-20 shrink-0">
                            <IoIosNotificationsOutline />
                        </div>
                    )
                    : (
                        <img src={URL.createObjectURL(formData?.icon)}
                            className="w-20 h-20 md:w-24 md:h-24 rounded-full object-cover"
                            alt=""
                        />
                    )
                }
                <div className="flex items-center gap-2">
                    <label htmlFor="uploadFile" className="md:col-span-1 col-span-2 flex justify-start items-center cursor-pointer">
                        <div className='text-xl md:text-3xl mx-2 text-primary'><HiOutlinePencilSquare /></div>
                        <p className='text-sm md:text-lg'>{t('Dashboard.admin.add_notification_icon')}</p>
                    </label>
                    <button onClick={() => { handleRemoveImage() }} className='md:col-span-1 col-span-2 flex justify-start items-center cursor-pointer'>
                        <div className='text-2xl md:text-4xl mx-2 text-primary'><IoCloseCircleSharp /></div>
                        <p className='text-sm md:text-lg'>{t('Dashboard.admin.remove_notification_icon')}</p>
                    </button>
                </div>
            </div>
            <input onChange={(e) => { handleImage(e) }} type='file' className='w-full hidden' name='icon' id="uploadFile" />
            <p className='mt-4 text-sm md:text-base text-black/40'>{t('Dashboard.settings.max_filesize')}</p>
            <ErrorMessage errors={errors.icon} /> */}


            {/* TITLE */}
            {/* <p className="mt-10 mb-2 md:text-lg">
                {t("Dashboard.admin.notification_title_pt")}
            </p>
            <input
                onChange={(e) => {
                    setFormData({...formData, title_pt: e.target.value});
                }}
                type="text"
                className="w-full border-2 rounded-md py-3 col-span-1 px-2 placeholder:text-black/60 lg:text-lg focus:outline-none"
                placeholder={t("Dashboard.admin.notification_title_pt")}
                required
            />
            <ErrorMessage errors={errors.title_pt} />
            
            <p className="mt-4 mb-2 md:text-lg">
                {t("Dashboard.admin.notification_title")}
            </p>
            <input
                onChange={(e) => {
                    setFormData({...formData, title: e.target.value});
                }}
                type="text"
                className="w-full border-2 rounded-md py-3 col-span-1 px-2 placeholder:text-black/60 lg:text-lg focus:outline-none"
                placeholder={t("Dashboard.admin.notification_title")}
                required
            />
            <ErrorMessage errors={errors.title} /> */}

            {/* DESCRIPTION */}
            <p className="mt-10 mb-2 md:text-lg">
                {t("Dashboard.admin.notification_description_pt")}
            </p>
            <textarea
                onChange={(e) => {
                    setFormData({...formData, description_pt: e.target.value});
                }}
                rows="2"
                className="w-full border-2 rounded-md py-3 col-span-1 px-2 placeholder:text-black/60 lg:text-lg focus:outline-none"
                placeholder={t("Dashboard.admin.notification_description_pt")}
                required
            />
            <ErrorMessage errors={errors.description_pt} />
            
            <p className="mt-4 mb-2 md:text-lg">
                {t("Dashboard.admin.notification_description")}
            </p>
            <textarea
                onChange={(e) => {
                    setFormData({...formData, description: e.target.value});
                }}
                rows="2"
                className="w-full border-2 rounded-md py-3 col-span-1 px-2 placeholder:text-black/60 lg:text-lg focus:outline-none"
                placeholder={t("Dashboard.admin.notification_description")}
                required
            />
            <ErrorMessage errors={errors.description} />

            <div className="mt-10 text-black/60 font-bold text-xl py-4">{t('Dashboard.admin.notification_preview')}</div>
            <div className="shadow-custom rounded-3xl">
                <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-10 py-5 p-4 md:p-10 w-full">
                    <div className="flex items-center justify-between md:justify-normal w-full md:w-fit md:gap-2">
                        <div className={`${formData?.seen && 'opacity-0'} bg-green-500 rounded-full w-3 h-3 md:w-4 md:h-4 shrink-0 hidden md:block`} />
                        <div className={`${(formData?.type) == 'buyer_match' ? 'bg-customPurple' : 'bg-primary' } flex items-center justify-center text-white text-4xl md:text-6xl rounded-full w-10 h-10 md:w-20 md:h-20 shrink-0`}>
                            <img src="/svg/notifications/white-logo.png" alt="" />
                        </div>
                        <div className="text-primary text-sm md:text-base md:hidden">
                            {formData?.time}
                        </div>
                    </div>

                    <div className="flex-grow">
                        <div className="flex items-center justify-between">
                            <p className="md:text-lg">Fortimy</p>
                            <div className="text-primary text-sm md:text-base hidden md:block">
                                {formData?.time}
                            </div>
                        </div>
                        <p className="text-disabled text-sm md:text-base">{currentLanguage() === 'en'
                            ? formData?.description ?? t("Dashboard.admin.notification_description")
                            : formData?.description_pt ?? t("Dashboard.admin.notification_description_pt")}
                        </p>
                    </div>
                </div>
            </div>
            
            <div className="flex">
                <button
                    disabled={isLoading}
                    type="submit"
                    className="text-white btn-primary text-xl font-bold px-12 py-2 rounded-lg shadow-lg mx-auto md:ml-auto md:mr-0 mt-5"
                    onClick={sendNotification}
                >
                    {t("Dashboard.admin.send_notification")}
                </button>
            </div>
        </>
    )
}

export default CustomNotification;