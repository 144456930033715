import React from 'react';
// import Background from '../../../assets/Index/homepage-background.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Hero = () => {

    const { t } = useTranslation();

    return (
        <section className="w-full h-[60vh] bg-homepageHero bg-cover bg-center">
            <div className='flex mx-10 md:mx-20 max-w-xs lg:max-w-3xl 2xl:max-w-7xl md:max-w-sm h-full lg:m-auto'>
                <div className='text-white max-w-[280px] md:max-w-xl my-auto'>
                    <h1 className='font-bold text-2xl md:text-4xl xl:text-5xl leading-tight md:text-left'>{t("Homepage.hero.title")}</h1>
                    <p className='md:text-2xl leading-normal mt-2 mb-8 md:my-4 max-w-[240px] md:max-w-sm'>{t("Homepage.hero.description")}</p>
                    <Link
                        to="/create-request"
                        className='font-bold py-2 px-4 md:px-8 bg-customPurple text-sm md:text-base text-center rounded-md'
                    >
                        {t("Homepage.make_your_request")}
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Hero;