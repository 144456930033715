import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

const ToggeablePassword = ({ value, onChange, placeholder = null }) => {

    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <div className='flex items-center w-full relative'>
                <input value={value} onChange={(e) => { onChange(e.target.value) }} type={showPassword ? "text" : "password"} className='w-full border-2 shadow-sm rounded-md p-2' placeholder={placeholder} />
                <button type='button' onClick={() => { setShowPassword(!showPassword) }} className='absolute right-3 text-xl text-black'>{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</button>              
            </div>
        </>
    )
}

export default ToggeablePassword;