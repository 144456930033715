import { useEffect, useState } from "react";
import { BsFillHouseDoorFill, BsFillPlusCircleFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { IoChatbubbles } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const Overview = (props) =>
{
    const { t } = useTranslation();

    const [timePeriod, setTimePeriod] = useState(0);
    const [last7days, setLast7days] = useState('rounded-full bg-primary text-white py-1 my-2 font-bold shadow-lg');
    const [last30days, setLast30days] = useState('rounded-full bg-white text-primary py-1 my-2 font-bold shadow-lg');

    const checkTimePeriod = (value) => {
        switch (value) {
            case 0:
                timePeriod !== 0 && setTimePeriod(0);
                timePeriod !== 0 && setLast7days('rounded-full bg-primary text-white py-1 my-2 font-bold shadow-lg');
                timePeriod !== 0 && setLast30days('rounded-full bg-white text-primary py-1 my-2 font-bold shadow-lg');
                break;
            case 1:
                timePeriod !== 1 && setTimePeriod(1);
                timePeriod !== 1 && setLast30days('rounded-full bg-primary text-white py-1 my-2 font-bold shadow-lg');
                timePeriod !== 1 && setLast7days('rounded-full bg-white text-primary py-1 my-2 font-bold shadow-lg');
                break;
            default:
                timePeriod !== 0 && setTimePeriod(0);
                timePeriod !== 0 && setLast7days('rounded-full bg-primary text-white py-1 my-2 font-bold shadow-lg');
                timePeriod !== 0 && setLast30days('rounded-full bg-white text-primary py-1 my-2 font-bold shadow-lg');
                break;
        }
    }

    return (
        <>
        <div className='hidden lg:flex lg:flex-row flex-col w-full rounded-[2.5rem] from-primary/20 via-primary/20 bg-gradient-to-r p-12 my-20'>
            <div className='lg:hidden flex'>
                <h1 className='text-4xl font-bold text-primary'>{t('Dashboard.dashboard.important_data')}</h1>
            </div>
            <div className='lg:flex grid grid-cols-5'>
                <div className='flex flex-col lg:justify-between col-span-1 lg:pr-0 pr-4 justify-center'>
                    <h1 className='lg:flex hidden text-4xl font-bold text-primary'>{t('Dashboard.dashboard.important_data')}</h1>
                    <div className='flex flex-col'>
                        <button className={last7days} onClick={() => { checkTimePeriod(0) }} >{t('Dashboard.dashboard.last_seven_days')}</button>
                        <button className={last30days} onClick={() => { checkTimePeriod(1) }} >{t('Dashboard.dashboard.last_thirty_days')}</button>
                    </div>
                </div>
                <div className='flex h-full lg:pl-10 col-span-1'>
                    <div className='h-full flex flex-col xl:w-[9rem] w-[8rem] items-center lg:mx-3 mx-1 pt-10'>
                        <div className='flex items-center z-[10]'>
                            <div className='rounded-full bg-primary text-white text-2xl p-2'>
                                <IoChatbubbles />
                            </div>
                        </div>
                        <div className='bg-white rounded-3xl w-full shadow-xl xl:h-[9rem] h-[8rem] z-[9] -mt-2 flex flex-col justify-center items-center'>
                            <h2 className='text-md text-center'>{t('Dashboard.dashboard.all_messages')}</h2>
                            <p className='font-bold text-5xl pt-4'>{ timePeriod == 0 ? props.overview.lastWeekChatCount : props.overview.lastMonthChatCount }</p>
                        </div>
                    </div>
                </div>
                <div className='flex h-full col-span-1'>
                    <div className='h-full flex flex-col xl:w-[9rem] w-[8rem] items-center lg:mx-3 mx-1 pt-10'>
                        <div className='flex items-center z-[10]'>
                            <div className='rounded-full bg-primary text-white text-2xl p-2'>
                                <FaUserCircle />
                            </div>
                        </div>
                        <div className='bg-white rounded-3xl w-full shadow-xl xl:h-[9rem] h-[8rem] z-[9] -mt-2 flex flex-col justify-center items-center'>
                            <h2 className='text-md text-center'>{t('Dashboard.dashboard.contact_views')}</h2>
                            <p className='font-bold text-5xl pt-4'>-</p>
                        </div>
                    </div>
                </div>
                <div className='flex h-full col-span-1'>
                    <div className='h-full flex flex-col xl:w-[9rem] w-[8rem] items-center lg:mx-3 mx-1 pt-10'>
                        <div className='flex items-center z-[10]'>
                            <div className='rounded-full bg-primary text-white text-2xl p-2'>
                                <BsFillHouseDoorFill />
                            </div>
                        </div>
                        <div className='bg-white rounded-3xl w-full shadow-xl xl:h-[9rem] h-[8rem] z-[9] -mt-2 flex flex-col justify-center items-center'>
                            <h2 className='text-md text-center'>{t('Dashboard.dashboard.active_requests')}</h2>
                            <p className='font-bold text-5xl pt-4'>{ timePeriod == 0 ? props.overview.lastWeekActiveRequestsCount : props.overview.lastMonthActiveRequestsCount }</p>
                        </div>
                    </div>
                </div>
                <div className='flex h-full col-span-1'>
                    <div className='h-full flex flex-col xl:w-[9rem] w-[8rem] items-center lg:mx-3 mx-1 pt-10'>
                        <div className='flex items-center z-[10]'>
                            <div className='rounded-full bg-primary text-white text-2xl p-2'>
                                <BsFillPlusCircleFill />
                            </div>
                        </div>
                        <div className='bg-white rounded-3xl w-full shadow-xl xl:h-[9rem] h-[8rem] z-[9] -mt-2 flex flex-col justify-center items-center px-2'>
                            <h2 className='text-md text-center'>{t('Dashboard.dashboard.archived_requests')}</h2>
                            <p className='font-bold text-5xl pt-4'>{ timePeriod == 0 ? props.overview.lastWeekArchivedRequestsCount : props.overview.lastMonthArchivedRequestsCount }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Overview;