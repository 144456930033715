import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAuthContext from "../Config/AuthContext";
import { useAlert } from "../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../UI/ErrorMessage";

const Index = () => {
	const { createPassword } = useAuthContext();
	const { toggleLoader, triggerPopup } = useAlert();

	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setpasswordConfirmation] = useState("");
	const [chars, setChars] = useState("col-span-1 text-black/60");
	const [upper, setUpper] = useState("col-span-1 text-black/60");
	const [lower, setLower] = useState("col-span-1 text-black/60");
	const [number, setNumber] = useState("col-span-1 text-black/60");
	const [symbol, setSymbol] = useState("col-span-1 text-black/60");
	const [validationPassword, setValidationPassword] = useState(false);
	const [matchPasswords, setMatchPasswords] = useState("col-span-1 text-black/60");

	const { id_encrypt } = useParams();

	const { t } = useTranslation();

	const validatePassword = (passwordInput) => {
		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[#?!@$%^&*-.])/;
		const minLengthRegExp = /.{8,}/;
		const uppercasePassword = uppercaseRegExp.test(passwordInput);
		const lowercasePassword = lowercaseRegExp.test(passwordInput);
		const digitsPassword = digitsRegExp.test(passwordInput);
		const specialCharPassword = specialCharRegExp.test(passwordInput);
		const minLengthPassword = minLengthRegExp.test(passwordInput);

		if (uppercasePassword) {
			setUpper("col-span-1 text-primary");
		} else {
			setUpper("col-span-1 text-black/60");
		}
		if (lowercasePassword) {
			setLower("col-span-1 text-primary");
		} else {
			setLower("col-span-1 text-black/60");
		}
		if (digitsPassword) {
			setNumber("col-span-1 text-primary");
		} else {
			setNumber("col-span-1 text-black/60");
		}
		if (specialCharPassword) {
			setSymbol("col-span-1 text-primary");
		} else {
			setSymbol("col-span-1 text-black/60");
		}
		if (minLengthPassword) {
			setChars("col-span-1 text-primary");
		} else {
			setChars("col-span-1 text-black/60");
		}

		if (
			uppercasePassword &&
			lowercasePassword &&
			digitsPassword &&
			specialCharPassword &&
			minLengthPassword &&
			password === passwordConfirmation
		) {
			setValidationPassword(true);
		} else {
			setValidationPassword(false);
		}
	};

	useEffect(() => {
		if (password === passwordConfirmation && password !== "") {
			setMatchPasswords("col-span-1 text-primary");
		} else {
			setMatchPasswords("col-span-1 text-black/60");
		}
	}, [passwordConfirmation, password]);

	const submitForm = async (event) => {
		setIsLoading(true);
		setErrors([]);
		event.preventDefault();
		const response = await createPassword({ id_encrypt, password, passwordConfirmation });
		if (response) {
			toggleLoader(false);
			if (response.status === 204) {
				triggerPopup("Authentication.createPasswordSuccess");
				setTimeout(() => {
					window.location.href = "/login";
				}, 2000);
			} else {
				setIsLoading(false);
				setErrors(response.data.errors);
			}
		} else {
			triggerPopup("Popups.something_went_wrong");
			setIsLoading(false);
		}
	};

	useEffect(() => {
		toggleLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	return (
		<>
			<section className="w-full flex flex-col py-40">
				<div className="w-full flex justify-center items-center py-5 text-center">
					<h1 className="text-black font-bold text-3xl pt-2">{t("Authentication.createPassword")}</h1>
				</div>
				<div className="md:grid md:grid-cols-10 flex flex-col md:px-0 px-10">
					<div className="flex flex-col xl:col-start-5 xl:col-end-7 lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9">
						<form className="" onSubmit={(e) => e.preventDefault()}>
							<input
								onChange={(e) => {
									setPassword(e.target.value);
									validatePassword(e.target.value);
								}}
								value={password}
								type="password"
								className="w-full my-2 p-2 border-[1px] rounded-md border-fifth placeholder:text-xl placeholder:text-black"
								placeholder={t("Authentication.newPassword")}
							/>
							<ErrorMessage errors={errors.password} />
							<div className="text-xs flex flex-col mt-1 mb-1 gap-y-1">
								<div className="grid grid-cols-3 items-center">
									<div className={chars}>{t("Pricing.characters")}</div>
									<div className={upper}>{t("Pricing.uppercase")}</div>
									<div className={lower}>{t("Pricing.lowercase")}</div>
								</div>
								<div className="grid grid-cols-3 items-center">
									<div className={number}>{t("Pricing.digit")}</div>
									<div className={symbol}>{t("Pricing.symbol")}</div>
									<div className="w-[1px]"></div>
								</div>
							</div>
							<input
								onChange={(e) => setpasswordConfirmation(e.target.value)}
								value={passwordConfirmation}
								type="password"
								className="w-full my-2 p-2 border-[1px] rounded-md border-fifth placeholder:text-xl placeholder:text-black"
								placeholder={t("Authentication.confirmPassword")}
							/>
							<ErrorMessage errors={errors.password_confirmation} />
							<div className="text-xs flex my-1">
								<div className={matchPasswords}>{t("Pricing.matchPassword")}</div>
							</div>
							<button
								onClick={(e) => { if(!isLoading) submitForm(e) }}
								disabled={isLoading}
								type="button"
								className="w-full font-bold text-white text-xl bg-black p-2 shadow-lg rounded-full mt-5"
							>
								{t("Authentication.createPassword")}
							</button>
						</form>
					</div>
				</div>
			</section>
		</>
	);
};

export default Index;
