import React from 'react'

const Banner = () => {
    return (
        <section className='bg-bannerFaq xl:h-[730px] lg:h-[490px] md:h-[400px] h-[290px] bg-no-repeat md:bg-contain bg-cover bg-top w-full grid grid-cols-8 items-center'>
            {/*<div className='col-start-3 col-end-7 flex flex-col items-center h-full justify-center'>
                <h1 className='text-white font-bold text-7xl leading-tight'>Frequently Asked Questions</h1>
                <p className='text-2xl text-white mt-10'>The real estate software with propery requests. Amplify your reach! Be proactive, cut costs, sell more, sell faster, innovative, and increase market transparency!</p>
            </div>*/}
        </section>
    )
}

export default Banner