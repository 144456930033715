import CompanySettings from "../../../components/Dashboard/Settings/CompanySettings";
import ScrollToTop from "../../../components/Router/ScrollToTop";

const Company = () =>
{
    return (
        <>
            <ScrollToTop />
            <CompanySettings />
        </>
    )
}

export default Company;