import React, { createContext, useState, useContext } from "react";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const triggerPopup = (text) => setValue(text);
  const clearPopup = () => setValue();
  const toggleLoader = (value) => setIsLoading(value);

  return (
    <AlertContext.Provider value={{ value, triggerPopup, clearPopup, isLoading, toggleLoader }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
