const trashSVG = () =>
{
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 39.75 39.75">
            <path id="Icon_akar-trash-can" data-name="Icon akar-trash-can" d="M6.675,10.35h29.4l-2.9,26.129a3.675,3.675,0,0,1-3.653,3.271H13.231a3.675,3.675,0,0,1-3.653-3.271Zm6.146-5.242A3.675,3.675,0,0,1,16.145,3H26.6A3.675,3.675,0,0,1,29.93,5.108L32.4,10.35H10.35ZM3,10.35H39.75M17.7,19.537v9.187m7.35-9.187v9.187" transform="translate(-1.5 -1.5)" fill="none" stroke="#ff6600" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </svg>
        </>
    )
}

export default trashSVG