import React, { useEffect, useState } from "react";
import axios from "../../Config/axios";
import InputModal from "./InputModal";
import ResponseModal from "./ResponseModal";
import { useTranslation } from "react-i18next";

const Logs = () => {
  const { t } = useTranslation();
  const [input, setInput] = useState(null);
  const [showInputModal, setShowInputModal] = useState("hidden");
  const [response, setResponse] = useState(null);
  const [code, setCode] = useState(null);
  const [showResponseModal, setShowResponseModal] = useState("hidden");

  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const getLogs = async (page) => {
    setLoading(true);
    await axios.get(`/api/logs?per_page=10&page=${page}`).then((response) => {
      const newLogs = response.data.data;
      setLogs((prevLogs) => [...prevLogs, ...newLogs]);
      setCurrentPage((prevPage) => prevPage + 1);
      setLoading(false);
    });
  };

  const loadMoreLogs = () => {
    getLogs(currentPage);
  };

  useEffect(() => {
    getLogs(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeInputModal = () => {
    setInput(null);
    setShowInputModal("hidden");
  };
  const closeResponseModal = () => {
    setResponse(null);
    setCode(null);
    setShowResponseModal("hidden");
  };

  return (
    <>
      <div className={showInputModal}>
        <InputModal showmodal={closeInputModal} input={input} />
      </div>
      <div className={showResponseModal}>
        <ResponseModal
          showmodal={closeResponseModal}
          response={response}
          code={code}
        />
      </div>

      <div className="w-full">
        <div className="grid grid-cols-5 font-bold text-2xl py-5 text-center">
          <div>{t("Dashboard.admin.date")}</div>
          <div>{t("Dashboard.admin.user")}</div>
          <div>{t("Dashboard.admin.method_path")}</div>
          <div>{t("Dashboard.admin.input")}</div>
          <div>{t("Dashboard.admin.response")}</div>
        </div>

        {logs.map((data, index) => (
          <div key={data.id} className="grid grid-cols-5 border-b-2 text-center">
            <div className="border-r-2 py-5">
              <p>{data.created_at.substring(0, 10)}</p>
              <p>{data.created_at.substring(11, 19)}</p>
            </div>
            <div className="border-r-2 py-5">
              <p className="font-bold">ID: {data.user_id}</p>
              <p>IP: {data.ip}</p>
            </div>
            <div className="border-r-2 py-5">
              <p>
                <b>{data.method}</b>
              </p>
                to
              <p>
                <u>{data.path}</u>
              </p>
            </div>
            <div className="border-r-2 py-5">
              {data.input === "[]" ? (
                "null"
              ) : (
                <button
                  onClick={() => {
                    setInput(data.input);
                    setShowInputModal("");
                  }}
                  className="underline text-primary font-bold"
                >
                  SHOW INPUT
                </button>
              )}
            </div>
            <div className="font-bold py-5">
              <p>{data.status_code}</p>
              <button
                onClick={() => {
                  setResponse(data.response);
                  setCode(data.status_code);
                  setShowResponseModal("");
                }}
                className="underline text-primary font-bold"
              >
                SHOW RESPONSE
              </button>
            </div>
          </div>
        ))}

        {loading && <div>Loading...</div>}
        {!loading && (
          <button onClick={loadMoreLogs} disabled={loading}>
            Load More
          </button>
        )}
      </div>
    </>
  );
};

export default Logs;
