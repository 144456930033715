import { t } from "i18next";
import { useState } from "react";
import { IoSettingsOutline } from "react-icons/io5"

const VideoCard = ({ video, onEdit, onDelete }) => 
{

    const [openSettings, setOpenSettings] = useState(false);

    return (
        <>
        <div className="w-full rounded-2xl bg-white text-primary flex items-center justify-between px-8 py-6 shadow-custom my-3 transition-all">
                <div>
                    <h1 className="font-bold text-xl md:text-3xl">F{video.id} {video.title} <span className="font-normal">({video.theme_name})</span></h1>
                </div>
                <div className="relative text-5xl hover:cursor-pointer">
                    <IoSettingsOutline onClick={() => { setOpenSettings(!openSettings) }} />
                    {openSettings &&
                        <div className="flex flex-col items-start text-primary text-lg absolute top-12 bg-white rounded-lg w-32 shadow-2xl z-[100] overflow-hidden">
                            <button
                                onClick={() => { onEdit(video) }}
                                className="w-full px-4 py-2 text-start hover:bg-secondary"
                            >
                                {t('Dashboard.admin.edit')}
                            </button>
                            <button
                                onClick={() => { onDelete(video) }}
                                className="w-full px-4 py-2 text-start hover:bg-secondary"
                            >
                                {t('Dashboard.admin.delete')}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default VideoCard;