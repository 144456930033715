import { useState, useEffect } from "react";
import axios from "../../../Config/axios";
import { useTranslation } from "react-i18next";
import { useAlert } from '../../../../contexts/AlertContext';
import NewToolNotification from "./NewToolNotification";
import CustomNotification from "./CustomNotification";
import CreatableSelect from "react-select/creatable";

const Notifications = () => {

    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState({
        id: 1,
        label: t('Dashboard.admin.new_tool_template')
    });

    const options = [
        {
            id: 1,
            label: t('Dashboard.admin.new_tool_template')
        },
        {
            id: 2,
            label: t('Dashboard.admin.custom_template')
        }
    ]

    const creatableSelectStyle = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: "2px",
            borderColor: "#e5e7eb",
            width: "100%",
            borderRadius: "0.375rem",
            paddingTop: "0.25rem",
            paddingBottom: "0.25rem",
        }),
    };

    const handleChange = (option) => {
        setSelectedOption(option)
    }

    return (
        <>
            <label>{t('Dashboard.admin.notification_template')}</label>
            <CreatableSelect
                getOptionValue={option => option.id}
                getOptionLabel={option => option.label}
                value={selectedOption}
                options={options}
                onChange={handleChange}
                styles={creatableSelectStyle}
            />

            <br/>
            <br/>

            {selectedOption?.id == 1
                ? (
                    <NewToolNotification />
                )
                : (
                    <CustomNotification />
                )
            }
        </>
    )
}

export default Notifications;