import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const ChangePlan = ({ showmodal }) => {

    return (
        <div className='fixed top-0 left-0 z-[100] h-screen w-screen bg-black/10 flex justify-center items-center'>
            <div className='md:w-[35vw] bg-white rounded-3xl flex flex-col'>
                <button onClick={() => { showmodal(); }} className='flex justify-end text-primary text-4xl m-3'><AiOutlineClose /></button>
                <div className='w-full px-5 text-center flex flex-col items-center justify-center pb-10'>
                    <h1 className='font-bold text-primary text-2xl text-center'>Are you sure you want to change<br />your plan?</h1>
                    <p className='text-black text-xl pt-5 pb-10'>Your plan will be changed after receiving your new contact from Fortimy agents and the corresponding amendments.</p>
                    <button onClick={() => { showmodal() }} className='bg-black text-white font-bold rounded-lg my-3 w-[50%] py-2'>Cancel</button>
                    <button onClick={() => { showmodal() }} className='bg-primary text-white font-bold rounded-lg my-3 w-[50%] py-2'>Yes, I'm sure</button>
                </div>
            </div>

        </div>
    )
}

export default ChangePlan