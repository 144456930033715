import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import PT from "../../lang/pt/translation.json";
import EN from "../../lang/en/translation.json";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "pt",
        transSupportBasicHtmlNodes: true,
        resources: {
            pt: {translation: PT}, 
            en: {translation: EN}
        },
        interpolation: {
            escapeValue: false
        }
    });

    export function currentLanguage()
    {
        return i18n.language;
    }