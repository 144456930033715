import React from 'react';
import Logo from '../assets/Logo-Branco.png';
import axios from "../components/Config/axios";

const Maintenance = () => {

    const getCurrentUser = async () => {
        await axios.get("/api/user")
            .then((response) => {
                if (response.status != 503) {
                    window.location.href = "/"
                }
            })
            .catch((e) => {
                if (e.response.status != 503) {
                    window.location.href = "/"
                }
            })
            .finally(() => {
                // 
            });
    };
    getCurrentUser()

    return (
        <div className='bg-primary w-screen h-screen flex flex-col justify-center items-center text-white'>
            <div className='flex flex-col justify-center items-center'>
                <img src={Logo} alt='Logo' className='' />
            </div>
            <h1 className='text-2xl'>Em manutenção</h1>
            <p>Pedimos desculpa pelo transtorno. Voltaremos em breve!</p>
        </div>
    )
}

export default Maintenance