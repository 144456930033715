import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const StoreInfo = ({ selectedItem, onClose }) => {

    const [title, setTitle] = useState('What are Top Highlights and Colour Highlights?');

    useEffect(() => {
        switch (selectedItem) {
            case 1 || 2:
                setTitle('What are Top Highlights and Colour Highlights?');
                break;
            case 3:
                setTitle('What are Requests for?');
                break;
            case 4:
                setTitle('What are Users for?');
                break;
            default:
                setTitle('What are Top Highlights and Colour Highlights?');
                break;
        }
    }, [selectedItem])

    return (
        <div className='fixed top-0 left-0 z-[100] h-screen w-screen bg-black/10 flex justify-center items-center'>
            <div className='w-4/5 bg-white rounded-3xl flex flex-col'>
                <button onClick={() => onClose() } className='flex justify-end text-primary text-4xl m-3'><AiOutlineClose /></button>
                <div className='w-full px-5 text-center flex flex-col items-center justify-center pb-10'>
                    <h1 className='font-bold text-black text-3xl text-center'>{title}</h1>
                    <p className='text-primary text-xl pt-5 pb-10'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur viverra sapien vitae ipsum gravida efficitur. Duis tempor, nisl ac accumsan accumsan, libero dui vestibulum orci, id feugiat risus erat ultrices nisi. </p>
                </div>
            </div>

        </div>
    )
}

export default StoreInfo