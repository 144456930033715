import { Link } from "react-router-dom"
import IconTeam from '../../../assets/Dashboard/Homepage/IconTeam.png';
import { useTranslation } from "react-i18next";
import { MdManageAccounts } from "react-icons/md";
import useAuth from "../../../hooks/useAuth";
import { COMPANY_ROLES } from "../../../constants/global";

const Team = ({ team }) => {

    const { t } = useTranslation();
    const { auth } = useAuth();

    return (
        <>
            <div className='bg-fourth col-span-1 rounded-[2rem] border-2 pt-2 xl:px-10 px-4 w-full flex flex-col justify-between'>
                <div className="flex justify-between  border-b-2 pb-2">
                    <h1 className='text-black font-bold text-2xl'>{t('Dashboard.navbar.team')}</h1>
                    {auth?.company_role_id == COMPANY_ROLES.ADMIN &&
                        <Link to={"/app/team"} className='text-primary text-3xl'>
                            <MdManageAccounts />
                        </Link>
                    }
                </div>
                <div className='grid grid-cols-5 py-5'>
                    <img src={IconTeam} alt="icon" className='rounded-full h-9 w-9 2xl:h-16 2xl:w-16 col-span-1' />
                    {team &&
                        team?.map((member, index) => (
                            index <= 3 &&
                            <div key={index} className='h-9 w-9 2xl:h-16 2xl:w-16 rounded-full'>
                                <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${member.profile_image}`} alt={member.first_name} className='rounded-full w-full h-full object-cover' />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default Team