import React from 'react';
import AdminCompanies from '../../components/Dashboard/Admin/Companies';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Companies = () => {
    return (
        <>
            <ScrollToTop />
            <AdminCompanies />
        </>
    )
}

export default Companies