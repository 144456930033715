import { Link } from "react-router-dom";
import LocationIcon from '../../../../assets/Dashboard/Homepage/RequestLocationIcon.png';
import { currentLanguage } from "../../../Config/i18n";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import { BsCheck2All } from "react-icons/bs";

const RequestCard = ({ request }) => 
{
    const { t } = useTranslation();

    return (
        <>
            <div className="py-5 xl:py-8 px-3 md:px-5 xl:px-8 bg-white rounded-[2rem] md:rounded-[3rem] my-5 shadow-custom relative">
                <div className='flex w-full'>
                    <img src={`/svg/properties/${request?.property_type_image}`}
                        alt={currentLanguage() === 'en' ? `${request?.property_type_name}` : `${request?.property_type_name_pt}`}
                        className='w-20 md:w-24'
                    />
                    <div className='flex flex-col w-full justify-around pl-2'>
                        <div className='flex justify-between w-full'>
                            <div>
                                <h1 className='md:text-xl font-bold text-black/60'>
                                    {currentLanguage() === 'en' ? `${request?.property_type_name} (${request?.typology_name})` : `${request?.property_type_name_pt} (${request?.typology_name_pt})`}
                                </h1>
                                <p className='text-xs md:text-sm'>ID: {request?.id}</p>
                            </div>
                            <Link to={`/app/my-requests/edit/${request?.id}`}
                                className="absolute lg:static top-5 right-5">
                                <MdEdit className='text-primary text-3xl' />
                            </Link>
                        </div>
                        <div className='hidden md:flex items-center'>
                            <img src={LocationIcon} alt="Location Icon" />
                            <p className='px-2 text-black/30 font-bold'>{t('Dashboard.my_requests.in')} {" "}
                            {Object.keys(request?.locations).map((key, index) => { 
                                return `${request?.locations[key].name}${index != Object.keys(request?.locations).length - 1 ? ", " : ""}`
                            })}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='md:hidden flex items-center mt-2'>
                    <img src={LocationIcon} alt="Location Icon" />
                    <p className='px-2 text-black/30 font-bold text-sm md:text-base'>{t('Dashboard.my_requests.in')} {" "}
                        {Object.keys(request?.locations).map((key, index) => { 
                            return `${request?.locations[key].name}${index != Object.keys(request?.locations).length - 1 ? ", " : ""}`
                        })}
                    </p>
                </div>
                <div className="md:flex justify-between my-3 md:my-5 items-center space-y-5 md:space-y-0">
                    
                    <div className='flex flex-wrap gap-1'>
                        {request?.amenities.map((amenity, index) => (
                            index < 3 &&
                            <div className='text-white bg-primary/50 rounded-full text-xs flex justify-center items-center h-5 px-4'>
                                {currentLanguage() === 'en' ? `${amenity.name}` : `${amenity.name_pt}`}
                            </div>
                        ))}
                    </div>
                    
                    <div className='flex md:flex-col 2xl:flex-row md:items-end md:text-lg 2xl:text-xl md:text-end gap-1'>
                        <div>
                            <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.useful_area')}</h1>
                            <div className="2xl:text-lg whitespace-nowrap">
                                {parseFloat(request?.area_from) > 0
                                ? `${request?.area_from} m²`
                                : t('Dashboard.my_requests.no_minimum')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='hidden md:flex gap-3 h-9'>
                        <button onClick={() => { /*setEnquiriesModal('')*/ }} className='bg-white text-primary shadow-md font-bold text-sm 2xl:text-md rounded-full border-2 px-4 text-center'>
                            {t('Dashboard.dashboard.view_enquiries')}
                        </button>    
                        <button onClick={() => { /*setSelectForHighlightRequest(request?.id); setCreateHighlightsModal('')*/ }} className='bg-white text-primary font-bold shadow-md rounded-full border-2 px-4 text-sm 2xl:text-md'>
                            {t('Dashboard.dashboard.highlight')}
                        </button>
                        {/* <Link to={"/app/duplicates"}>
                            <BsCheck2All className='text-primary text-4xl' />
                        </Link> */}
                    </div>
                    <div className='flex md:flex-col 2xl:flex-row md:items-end md:text-lg 2xl:text-xl md:text-end gap-1'>
                        <div>
                            <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.max_budget')}</h1>
                            <div className="2xl:text-lg whitespace-nowrap">
                                {(parseFloat(request?.price_until) > 0)
                                ? `${request?.price_until} €`
                                : t('Dashboard.my_requests.no_limit')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex md:hidden gap-3 h-9 mt-3 justify-around'>
                    <button onClick={() => { /*setEnquiriesModal('')*/ }} className='bg-white text-primary shadow-md font-bold text-sm 2xl:text-md rounded-full border-2 px-4 text-center'>
                        {t('Dashboard.dashboard.view_enquiries')}
                    </button>    
                    <button onClick={() => { /*setSelectForHighlightRequest(request?.id); setCreateHighlightsModal('')*/ }} className='bg-white text-primary font-bold shadow-md rounded-full border-2 px-4 text-sm 2xl:text-md'>
                        {t('Dashboard.dashboard.highlight')}
                    </button>
                    {/* <Link to={"/app/duplicates"}>
                        <BsCheck2All className='text-primary text-4xl' />
                    </Link> */}
                </div>
            </div>
        </>
    )
}

export default RequestCard;