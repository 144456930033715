import React from 'react';
import Banner from '../../assets/Dashboard/Banner.png';
import DashboardNavbar from '../../components/Dashboard/Navbar';
import DashboardDuplicates from '../../components/Dashboard/Duplicates';
import ScrollToTop from '../../components/Router/ScrollToTop';
import ComingSoon from '../ComingSoon';
import useAuth from '../../hooks/useAuth';

const Duplicates = () => {
    const {auth} = useAuth();
    return (
        <>
            <ScrollToTop />
            <section>
                {auth.is_dynamik_test ? (
                    <DashboardDuplicates />
                    ) : (
                    <ComingSoon />
                    )
                }
            </section>
        </>
    )
}

export default Duplicates;