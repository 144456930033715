import { t } from 'i18next';
import React from 'react';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';

const Steps = ({ step, previousStep, nextStep, checkFields }) => {

    return (
        <>
            <div className="hidden md:flex items-center justify-center gap-x-10 md:mb-20 mb-10">
                <button
                    className="text-3xl text-primary disabled:text-primary/50"
                    disabled={step > 1 ? false : true}
                    onClick={() => {
                        step > 1 && previousStep(step - 1);
                    }}
                    >
                    <MdOutlineArrowBackIos />
                </button>
                <div className="flex flex-col xl:w-[800px] md:w-[600px] w-full items-center justify-center text-xl text-center">
                    <div className="md:flex justify-between w-full md:ml-6 md:text-lg hidden relative">
                        <div className='absolute top-1/4 -translate-y-1/2 left-0 border-primary border-2 w-11/12 ml-10 -z-10'></div>
                        <div className="flex flex-col items-center gap-2 w-20">
                            <p className={`flex justify-center items-center w-14 h-14 border-4 rounded-full ${step === 1 ? 'bg-primary text-white' : 'bg-white text-primary'} border-primary font-bold text-2xl`}>
                                1
                            </p>
                            {t('Dashboard.my_requests.clients_data')}
                        </div>
                        <div className="flex flex-col items-center gap-2 w-20">
                            <p className={`flex justify-center items-center w-14 h-14 border-4 rounded-full ${step === 2 ? 'bg-primary text-white' : 'bg-white text-primary'} border-primary font-bold text-2xl`}>
                                2
                            </p>
                            {t('Dashboard.my_requests.property_type')}
                        </div>
                        <div className="flex flex-col items-center gap-2 w-20">
                            <p className={`flex justify-center items-center w-14 h-14 border-4 rounded-full ${step === 3 ? 'bg-primary text-white' : 'bg-white text-primary'} border-primary font-bold text-2xl`}>
                                3
                            </p>
                            {t('Dashboard.my_requests.location')}
                        </div>
                        <div className="flex flex-col items-center gap-2 w-20">
                            <p className={`flex justify-center items-center w-14 h-14 border-4 rounded-full ${step === 4 ? 'bg-primary text-white' : 'bg-white text-primary'} border-primary font-bold text-2xl`}>
                                4
                            </p>
                            {t('Dashboard.my_requests.price_and_area')}
                        </div>
                        <div className="flex flex-col items-center gap-2 w-20">
                            <p className={`flex justify-center items-center w-14 h-14 border-4 rounded-full ${step === 5 ? 'bg-primary text-white' : 'bg-white text-primary'} border-primary font-bold text-2xl`}>
                                5
                            </p>
                            {t('Dashboard.my_requests.building_and_amenities')}
                        </div>
                        <div className="flex flex-col items-center gap-2 w-20">
                            <p className={`flex justify-center items-center w-14 h-14 border-4 rounded-full ${step === 6 ? 'bg-primary text-white' : 'bg-white text-primary'} border-primary font-bold text-2xl`}>
                                6
                            </p>
                            {t('Dashboard.my_requests.description')}
                        </div>
                    </div>
                </div>
                <button
                    className="text-3xl text-primary disabled:text-primary/50 ml-5"
                    disabled={step < 6 ? false : true}
                    onClick={() => {
                        step < 6 && checkFields() && nextStep(step + 1);
                    }}
                    >
                    <MdOutlineArrowForwardIos />
                </button>
            </div>
        </>
    )
}

export default Steps;