import { AMENITIES } from "../../../constants/global";
import { currentLanguage } from "../../Config/i18n";

const AmenityCard = ({ amenity, amenities, selectedAmenities, onClick }) => 
{
    return (
        <>
        { amenity.image && (
            <div
                className={`${
                amenity.id === AMENITIES.MORE
                    ? "relative"
                    : selectedAmenities.includes(amenity.id)
                    ? "border-primary bg-lightPrimary border-2 rounded-xl"
                    : "grayscale border-transparent"
                } p-2`}
            >
                <button
                    onClick={() => { onClick(amenity.id) }}
                    type="button"
                    className={`${(amenity.id === AMENITIES.MORE
                        && selectedAmenities.includes(AMENITIES.MORE)
                            ? 'flex absolute right-5'
                            : 'flex items-center flex-col mx-auto'
                    )}`}
                >
                    <div
                        className={`flex justify-center items-center bg-white border-primary border-2 rounded-full overflow-hidden
                        ${
                            amenity.id === AMENITIES.MORE
                            ? selectedAmenities.includes(AMENITIES.MORE)
                                ? "w-16 h-16 p-4 z-50"
                                : "lg:p-0 p-2 w-24 h-24 md:w-32 md:h-32"
                            : "lg:p-0 p-2 w-24 h-24 md:w-32 md:h-32"
                        }`}
                    >
                        <img
                        src={`/svg/amenities/${amenity.image}`}
                        alt="Amenity"
                        />
                    </div>
                    <p
                        className={`text-sm md:text-xl ${
                            (amenity.id === AMENITIES.MORE
                                && selectedAmenities.includes(AMENITIES.MORE)
                            ) ? 'hidden'
                            : selectedAmenities.includes(amenity.id)
                                ? "text-primary font-bold"
                                : "text-black/60"
                        } mt-2 md:mt-5`}
                    >
                        { currentLanguage() === 'en' ? amenity.name : amenity.name_pt }
                    </p>
                </button>
                {/* MORE AMENITIES DROPDOWN */}
                {amenity.id === AMENITIES.MORE && (
                <div
                    className={`${
                        selectedAmenities.includes(AMENITIES.MORE) ? "block" : "hidden"
                    } absolute top-0 right-0 bg-fourth space-y-1 p-4 pt-20 w-60 md:w-80 z-40`}
                >
                    {amenities.map((amenity, index) =>
                        !amenity.image && (
                        <p
                            key={index}
                            onClick={() => onClick(amenity.id)}
                            className={`whitespace-nowrap cursor-pointer transition-all
                                    ${
                                        index + 1 ===
                                        amenities.length
                                        ? ""
                                        : "border-b-2 border-fifth pb-1"
                                    }
                                    ${
                                        selectedAmenities.includes(amenity.id)
                                        ? "text-primary font-bold"
                                        : ""
                                    }`}
                        >
                            { currentLanguage() === 'en' ? amenity.name : amenity.name_pt }
                        </p>
                        )
                    )}
                </div>
                )}
            </div>
        )}
        </>
    )
}

export default AmenityCard;