import React from 'react';
import { useTranslation } from 'react-i18next';

const RegisterToSubmitRequest = ({ onRegister, onClose }) => {

    const { t } = useTranslation();

    return (
        <div className='fixed top-0 left-0 z-50 flex items-center justify-center h-screen w-screen bg-black/5'>
            <div className='flex flex-col items-center justify-center bg-white w-72 md:w-[40vw] h-fit max-h-96 lg:max-h-[700px] px-8 lg:px-10 py-10 shadow-2xl rounded-3xl'>
                <div className='space-y-3 text-center'>
                    <h1 className='text-2xl lg:text-3xl text-black font-bold'>{t('Modals.register_to_submit.title')}</h1>
                    <p className='lg:text-xl text-black'>{t('Modals.register_to_submit.content')}</p>
                </div>
                <div className='flex flex-col lg:flex-row items-center justify-center gap-5 text-center w-full'>
                    <button onClick={ onClose } className='bg-black text-white font-bold rounded-lg mb-2 md:my-3 w-fit p-2'>{t('Modals.cancel')}</button>
                    <button onClick={ onRegister } className='bg-primary text-white font-bold rounded-lg md:my-3 w-fit p-2'>{t('Modals.register')}</button>
                </div>
            </div>
        </div>
    )
}

export default RegisterToSubmitRequest;