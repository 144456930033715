import React from 'react';
import { useTranslation } from 'react-i18next';

const RegistrationSucess = ({ isBusiness, onClose }) => {

    const { t } = useTranslation();

    return (
        <div className='fixed top-0 left-0 z-50 flex items-center justify-center h-screen w-screen bg-black/5'>
            <div className='flex flex-col items-center justify-center bg-white w-72 md:w-[40vw] h-fit max-h-96 lg:max-h-[700px] px-8 lg:px-10 py-10 shadow-2xl rounded-3xl'>
                <div className='space-y-3 text-center'>
                    <h1 className='text-2xl lg:text-3xl text-black font-bold'>{t('Pricing.registrationSuccessModalTitle')}</h1>
                    <p className='lg:text-xl text-black'>{ isBusiness ? t('Pricing.businessAccountregistrationSuccessModalDescription') : t('Pricing.individualAccountRegistrationSuccessModalDescription') }</p>
                </div>
                <div className='flex flex-col items-center w-fit mt-4 lg:mt-8'>
                    <button onClick={ onClose } className='w-full py-2 px-10 bg-black text-white lg:text-xl rounded-lg flex justify-center items-center font-bold my-4'>{t('Pricing.registrationSuccessModalButton')}</button>
                </div>
            </div>
        </div>
    )
}

export default RegistrationSucess;