import React, { useEffect, useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdOutlineLockOpen, MdOutlineLock } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../UI/ErrorMessage";
import { useAlert } from "../../../contexts/AlertContext";
import axios from "../../Config/axios";
import InputMask from "react-input-mask";
import Tooltip from "../../UI/Tooltip";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { t } from "i18next";

const CompanyCard = ({ company, disable, onVerify, onEdit, onDelete, onBlock }) => {
	const { id } = useParams();
	const { toggleLoader, triggerPopup } = useAlert();
	const [openCard, setOpenCard] = useState(false);
	const [formData, setFormData] = useState({ id: company.id });
	const [hasChanges, setHasChanges] = useState(false);
	const [errors, setErrors] = useState([]);

	const handleChange = (e, field) => {
		if (!hasChanges) {
			setHasChanges(true);
		}
		setFormData({ ...formData, [field]: e.target.value });
	};

	const updateCompany = async () => {
		toggleLoader(true);
		try {
			const response = await axios.put(`/api/companies/${formData.id}`, formData);
			if (response.status === 204) {
				triggerPopup("Changes saved successfully");
				setErrors([]);
				onEdit();
			}
		} catch (error) {
			setErrors(error.response.data.errors);
		}
		toggleLoader(false);
	};

	useEffect(() => {
		setFormData({ id: company.id });
		setErrors([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCard]);

	useEffect(() => {
		if (company.id == id) {
			setOpenCard(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex flex-col" key={company.id}>
				<div
					onClick={() => {
						setOpenCard((prevOpenCard) => !prevOpenCard);
					}}
					className={`bg-white shadow-custom hover:cursor-pointer relative rounded-2xl flex items-center justify-between w-full h-[200px] p-10
                    ${openCard && "border-2 border-primary border-b-0"}`}
				>
					<div className="flex items-start gap-x-3">
						<h1 className="text-primary text-3xl font-bold">FO{company.id}</h1>
						<div className="flex flex-col items-start">
							<h1 className="text-primary text-3xl font-bold">
								{company.trademark}, {company.city}
							</h1>
							<h1 className="text-primary text-2xl">{company.email}</h1>
						</div>
					</div>
					<div className="flex items-center gap-10">
						<Tooltip message={company.blocked ? t('Dashboard.admin.block') : t('Dashboard.admin.unblock')}>
							<button onClick={onBlock} className="text-primary text-2xl md:text-5xl">
								{company.blocked ? <MdOutlineLock /> : <MdOutlineLockOpen />}
							</button>
						</Tooltip>
						{/* <button onClick={ onDelete } className='text-primary text-2xl md:text-4xl'>
                                <BsTrashFill />
                            </button> */}
						<div className="text-primary md:text-3xl text-xl border-2 rounded-full p-1 border-primary">
							{openCard ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
						</div>
					</div>
				</div>
				{openCard && (
					<div className="grid grid-cols-4 gap-5 rounded-b-2xl items-center bg-white border-2 border-t-0 border-primary z-50 -mt-5 p-10">
						<h1 className="col-span-4 text-2xl text-primary font-bold">{t('Dashboard.admin.company_info')}</h1>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.trademark')}</h1>
							<input
								type="text"
								defaultValue={company.trademark}
								name="trademark"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "trademark")}
							/>
							<ErrorMessage errors={errors.trademark} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.corporate_name')}</h1>
							<input
								type="text"
								defaultValue={company.corporate_name}
								name="corporate_name"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "corporate_name")}
							/>
							<ErrorMessage errors={errors.corporate_name} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.ami')}</h1>
							<input
								type="number"
								defaultValue={company.ami}
								name="ami"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "ami")}
							/>
							<ErrorMessage errors={errors.ami} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.email')}</h1>
							<input
								type="text"
								disabled
								defaultValue={company.email}
								name="email"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "email")}
							/>
							<ErrorMessage errors={errors.email} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.phone')}</h1>
							<PhoneInput
								value={company.phone}
								onChange={(number, country) => {
									if (!hasChanges) {
										setHasChanges(true);
									}
									setFormData({
										...formData,
										company_phone: number.replace(country.dialCode, ""),
										company_phone_country_code: `+${country.dialCode}`,
									});
								}}
								buttonStyle={{ border: "none", backgroundColor: "white" }}
								inputStyle={{
									height: "100%",
									width: "100%",
									border: "none",
									fontSize: "18px",
									lineHeight: "28px",
								}}
								containerStyle={{
									fontSize: "18px",
									lineHeight: "28px",
									padding: "8px",
									border: "2px solid #ff6600",
									borderRadius: "12px",
								}}
								enableSearch={true}
								disableDropdown={false}
								defaultMask="... ... ..."
							/>
							<ErrorMessage errors={errors.company_phone} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.address')}</h1>
							<input
								type="text"
								defaultValue={company.address}
								name="address"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "address")}
							/>
							<ErrorMessage errors={errors.address} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.city')}</h1>
							<input
								type="text"
								defaultValue={company.city}
								name="city"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "city")}
							/>
							<ErrorMessage errors={errors.city} />
						</div>
						{/* TODO ver forma melhor para mostrar descrição completa */}
						{/* <div className='flex flex-col gap-2 text-primary text-lg'>
                            <h1 className='text-black/80'>Description</h1>
                            <input type='text'  defaultValue={company.description} name='description' className='border-primary border-2 p-2 rounded-xl' onChange={(e) => handleChange(e)} />
                        </div> */}
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.postal_code')}</h1>
							<InputMask
								mask="9999-999"
								type="text"
								defaultValue={company.postal_code}
								name="postal_code"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "postal_code")}
							/>
							<ErrorMessage errors={errors.postal_code} />
						</div>

						<h1 className="col-span-4 text-2xl text-primary mt-5 font-bold">{t('Dashboard.admin.billing_info')}</h1>

						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.corporate_name')}</h1>
							<input
								type="text"
								defaultValue={company.billing_corporate_name}
								name="billing_corporate_name"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "billing_corporate_name")}
							/>
							<ErrorMessage errors={errors.billing_corporate_name} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.nif')}</h1>
							<InputMask
								mask="999 999 999"
								type="text"
								defaultValue={company.billing_nif}
								name="billing_nif"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "billing_nif")}
							/>
							<ErrorMessage errors={errors.billing_nif} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.headquarters_address')}</h1>
							<input
								type="text"
								defaultValue={company.billing_address}
								name="billing_address"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "billing_address")}
							/>
							<ErrorMessage errors={errors.billing_address} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.city')}</h1>
							<input
								type="text"
								defaultValue={company.billing_city}
								name="billing_city"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "billing_city")}
							/>
							<ErrorMessage errors={errors.billing_city} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.postal_code')}</h1>
							<InputMask
								mask="9999-999"
								type="text"
								defaultValue={company.billing_postal_code}
								name="billing_postal_code"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "billing_postal_code")}
							/>
							<ErrorMessage errors={errors.billing_postal_code} />
						</div>
						<div className="flex flex-col gap-2 text-primary text-lg">
							<h1 className="text-black/80">{t('Dashboard.admin.email')}</h1>
							<input
								type="text"
								defaultValue={company.billing_email}
								name="billing_email"
								className="border-primary border-2 p-2 rounded-xl"
								onChange={(e) => handleChange(e, "billing_email")}
							/>
							<ErrorMessage errors={errors.billing_email} />
						</div>
						{hasChanges && (
							<div className="col-span-4 flex w-full justify-end">
								<button
									onClick={() => updateCompany()}
									className="bg-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
								>
									{t('Dashboard.admin.save_changes')}
								</button>
							</div>
						)}
						{!company.verified ? (
							<div className="col-span-4 flex w-full justify-end">
								<button
									disabled={disable}
									onClick={onVerify}
									className="btn-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
								>
									{t('Dashboard.admin.verify_company')}
								</button>
							</div>
						) : (
							!company.user?.verified && (
								<div className="col-span-4 flex w-full justify-end">
									<Link
										disabled={disable}
										to={`/app/admin/users/${company.user?.id}`}
										className="btn-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
									>
										{t('Dashboard.admin.verify_user')}
									</Link>
								</div>
							)
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default CompanyCard;
