import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { VscInfo } from "react-icons/vsc";

const StoreItem = (props) => {

    const [quantity, setQuantity] = useState(props.quantity)
    const [total, setTotal] = useState('XXX')

    const onChange = (quantity) => {
        setQuantity(quantity)
        props.onChange(quantity)
    }

    return (
        <>
        <div className={`${props.inCart && 'bg-fourth'} grid grid-cols-7 py-2 md:p-3 rounded-xl md:rounded-3xl`}>
            <img src={props.image} alt="Highlights Icon" className={ props.isRequest ? 'ml-2 md:ml-4 max-w-10 md:max-w-16' : 'max-w-12 md:max-w-20' } />
            <div className='col-span-4 lg:col-span-2 flex flex-col lg:flex-row lg:items-center gap-y-2 ml-4 md:ml-0'>
                <div className="flex">
                    <h1 className='text-sm md:text-lg font-bold'>{props.name}</h1>
                    { !props.inCart &&
                        <button
                            onClick={() => props.onInfo() }
                            className='text-primary text-lg md:text-2xl -mt-5 ml-2'
                        >
                            <VscInfo />
                        </button>
                    }
                </div>

                <div className='lg:hidden flex items-center gap-x-2 text-primary text-3xl md:text-5xl lg:text-6xl'>
                    <button
                        disabled={ quantity <= props.minLimit }
                        className={ quantity <= props.minLimit && 'text-zinc-400 cursor-not-allowed' }
                        onClick={() => { onChange(quantity - 1) }}
                    >
                        <CiCircleMinus />
                    </button>
                    <p className="lg:hidden text-base md:text-xl text-black">{quantity}</p>
                    <button onClick={() => { onChange(quantity + 1) }}><CiCirclePlus /></button>
                </div>

            </div>

            <div className='col-span-2 hidden lg:flex items-center mx-auto gap-x-2 text-primary text-3xl md:text-5xl lg:text-6xl'>
                <button
                    disabled={ quantity <= props.minLimit }
                    className={ quantity <= props.minLimit && 'text-zinc-400 cursor-not-allowed' }
                    onClick={() => { onChange(quantity - 1) }}
                >
                    <CiCircleMinus />
                </button>
                <p className="lg:hidden text-base md:text-xl text-black">{quantity}</p>
                <button onClick={() => { onChange(quantity + 1) }}><CiCirclePlus /></button>
            </div>

            <div className='hidden lg:flex text-xl items-center font-bold'>
                <p>{quantity}</p>
                <span className='text-3xl'><AiOutlineClose /></span>
                <p>XXX€</p>
            </div>
            <p className="text-primary font-bold text-lg md:text-2xl md:pr-10 m-auto">{total}€</p>
        </div>
        </>
    )
}

export default StoreItem;