import React, { useEffect, useState } from 'react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Icon from "../../../assets/Product/LogoPretoPequeno.png";
import VideoSlider from './VideoSlider';
import axios from "../../Config/axios";
import { useAlert } from '../../../contexts/AlertContext';
import { useTranslation } from 'react-i18next';

const DashboardLearning = () => {
    const { t } = useTranslation();
    const { toggleLoader } = useAlert();
    const [isLoading, setIsLoading] = useState(true);
    const [themes, setThemes] = useState([]);
    const [videos, setVideos] = useState([]);

    const getVideos = async () => {
        try {
            const response = await axios.get('/api/learning');    
            if(response.status === 200){
                setVideos(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getThemes = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/theme');    
            if(response.status === 200){
                setThemes(response.data.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(() => {
        getVideos();
        getThemes();
    }, []);
    
    return (
        <>
            <section className='mt-10 space-y-12 md:space-y-28 text-xl md:text-2xl lg:text-4xl 2xl:text-5xl'>

                {themes.length > 0 &&
                    <>
                    <div className='flex flex-col justify-between items-center w-full gap-y-5 md:gap-y-0'>
                        <div className='flex w-full items-end gap-x-4 md:gap-x-8'>
                            <img className='w-8 md:w-10' src={Icon} alt='Logo' />
                            <h1 className='font-bold text-left'>New for you</h1>
                        </div>
                        <div className="w-full md:pl-16">
                            <VideoSlider
                                videos={videos}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col justify-between items-center w-full gap-y-5 md:gap-y-10'>
                        <div className='flex w-full items-end gap-x-4 md:gap-x-8'>
                            <img className='w-8 md:w-10' src={Icon} alt='Logo' />
                            <h1 className='font-bold text-left'>Learning</h1>
                        </div>

                        {themes.map((theme, index) => (
                            <div className='w-full md:pl-16'>
                                <VideoSlider
                                    key={index}
                                    theme={theme}
                                    videos={videos}
                                />
                            </div>
                        ))}
                    </div>
                    </>
                }
                { !isLoading && themes.length === 0 && (
                    <p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.learning.no_content')}</p>
                )}
            </section>
        </>
    )
}

export default DashboardLearning;