import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const InputModal = ({ showmodal, input }) => {

    return (
        <div className='fixed top-0 left-0 z-[100] h-screen w-screen bg-black/10 flex justify-center items-center'>
            <div className='md:w-[50vw] bg-white rounded-3xl flex flex-col'>
                <button onClick={() => { showmodal(); }} className='flex justify-end text-primary text-4xl m-3'><AiOutlineClose /></button>
                <div className='w-full px-5 text-center flex flex-col items-center justify-center pb-10'>
                    <p className='text-black text-lg pt-5 pb-10'>{input}</p>
                </div>
            </div>
        </div>
    )
}

export default InputModal