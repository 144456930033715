import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import DuplicateCard from "./DuplicateCard";
import LocationIcon from '../../../assets/Dashboard/Requests/RequestLocationIcon.png';
import { currentLanguage } from "../../Config/i18n";
import { BsCheck2All, BsCheck2Circle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const RequestCard = ({ request }) =>
{
    const { t } = useTranslation();
    const [openDuplicates, setOpenDuplicates] = useState(false)

    return (
        <>
        <div className="bg-secondary rounded-[2rem] lg:rounded-[3rem] w-full my-5">
            {/* MOBILE */}
            <div className="lg:hidden p-5 h-fit bg-white rounded-3xl w-full shadow-custom space-y-4">
                {/*PROPERTY*/}
                <div className="flex items-start gap-1">
                    <img
                        src={`/svg/properties/${request.property_type_image}`}
                        alt="Property Type Icon"
                        className="w-16"
                    />
                    <div>
                        <h1 className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `${request.property_type_name}` : `${request.property_type_name_pt}`}
                        </h1>
                        <h1 className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `(${request.typology.name})` : `(${request.typology.name_pt})`}
                        </h1>
                    </div>
                    <button onClick={() => { setOpenDuplicates(!openDuplicates) }} className='ml-auto w-8 h-8 flex items-center justify-center text-primary text-xl border rounded-full border-primary'>
                        {openDuplicates ? <MdKeyboardArrowUp />: <MdKeyboardArrowDown /> }
                    </button>
                </div>
                <div className="ml-2">
                    <div className="flex text-primary items-start">
                        <img
                            src={LocationIcon}
                            alt=""
                            className="text-primary w-4 mr-2"
                        />
                        <p className="line-clamp-2 text-sm">{t('Dashboard.my_requests.in')} {
                            Object.keys(request.locations).map((key, index) => { 
                                return `${request.locations[key].zone?.name ?? request.locations[key].parish?.name ?? request.locations[key].municipality?.name ?? request.locations[key].district?.name}${index != Object.keys(request.locations).length - 1 ? ", " : ""}`
                            })
                        }</p>
                    </div>
                    <p className="bg-primary font-bold text-white rounded-full text-md lg:block hidden text-center w-fit px-2 whitespace-nowrap">
                        {currentLanguage() === 'en' ? request.request_type.name : request.request_type.name_pt}
                    </p>
                    <p className="text-xs">ID: {request.id}</p>
                </div>
                {/*PRICE && AREA */}
                <div className="md:flex md:items-center md:justify-between">
                    <div>
                        <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.max_budget')}</h1>
                        <div className="flex font-bold justify-between text-sm">
                            {(parseFloat(request.price_until) > 0)
                            ? `${request.price_until} €`
                            : t('Dashboard.my_requests.no_limit')}
                        </div>
                    </div>
                    <div>
                        <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.useful_area')}</h1>
                        <div className="flex font-bold justify-between text-sm">
                            {parseFloat(request.area_from) > 0
                            ? `${request.area_from} m²`
                            : t('Dashboard.my_requests.no_minimum')}
                        </div>
                    </div>
                </div>
                {/* DUPLICATES COUNT */}
                <div className='flex items-center justify-between'>
                    <div className='text-fifth font-bold'>
                        <div className='flex gap-x-1'>
                            <div className='text-2xl'><BsCheck2All /></div>
                            <p className='text-base'>0</p>
                        </div>
                        <p className='text-sm'>{t('Dashboard.duplicates.new_duplicates')}</p>
                    </div>
                    <div className='text-fifth font-bold'>
                        <div className='flex gap-x-1'>
                            <div className='text-2xl'><BsCheck2Circle /></div>
                            <p className='text-base'>{request.duplicates.length}</p>
                        </div>
                        <p className='text-sm'>{t('Dashboard.duplicates.total_duplicates')}</p>
                    </div>
                </div>
                {/* <button
                    onClick={() => { onViewAmenities() }}
                    className="text-primary font-bold text-sm ml-auto"
                >
                    {t('Dashboard.my_requests.view_all')}
                </button> */}
            </div>

            {/* DESKTOP */}
            <div className="py-5 xl:py-8 2xl:py-10 h-40 lg:h-fit px-5 xl:px-8 2xl:px-10 bg-white rounded-[2rem] lg:rounded-[3rem] w-full shadow-custom lg:grid lg:grid-cols-5 lg:grid-cols-6 hidden">
                <div className='col-span-1 h-full flex items-center justify-center'>
                    <img src={`/svg/properties/${request.property_type_image}`} alt={request.property_type_name} />
                </div>
                <div className='col-span-2 pr-5 border-r-2'>
                    <div className="flex justify-between">
                        <div className="space-y-4">
                            <h1 className="2xl:text-xl text-base font-bold text-black/60">
                            {currentLanguage() === 'en' ? `${request.property_type_name} (${request.typology.name})` : `${request.property_type_name_pt} (${request.typology.name_pt})`}
                            </h1>
                            <div className="flex text-primary items-center">
                                <div className="lg:mr-2">
                                    <img
                                        src={LocationIcon}
                                        alt="Location Icon"
                                        className="text-primary lg:w-[24px] w-[18px]"
                                    />
                                </div>
                                <p className="lg:text-base text-sm">{t('Dashboard.my_requests.in')} {
                                    Object.keys(request.locations).map((key, index) => { 
                                        return `${request.locations[key].zone?.name ?? request.locations[key].parish?.name ?? request.locations[key].municipality?.name ?? request.locations[key].district?.name}${index != Object.keys(request.locations).length - 1 ? ", " : ""}`
                                    })
                                }</p>
                            </div>
                            <p className="bg-primary font-bold text-white rounded-full text-md lg:text-sm text-center w-fit px-2 whitespace-nowrap">
                                {currentLanguage() === 'en' ? request.request_type.name : request.request_type.name_pt}
                            </p>
                            <p className="text-base">ID: {request.id}</p>
                        </div>

                        <div className="text-right h-full">
                            <h1 className="font-bold text-md">{t('Dashboard.my_requests.max_budget')}</h1>
                            <div className="2xl:text-lg mb-6 whitespace-nowrap">
                                <p>
                                    {(parseFloat(request.price_until) > 0)
                                    ? `${request.price_until} €`
                                    : t('Dashboard.my_requests.no_limit')}
                                </p>
                            </div>
                            <h1 className="font-bold text-md">{t('Dashboard.my_requests.useful_area')}</h1>
                            <div className="2xl:text-lg mb-6">
                                <p>
                                    {parseFloat(request.area_from) > 0
                                    ? `${request.area_from} m²`
                                    : t('Dashboard.my_requests.no_minimum')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>  

                <div className='lg:col-span-1 lg:col-span-2 flex flex-col lg:flex-row items-center justify-around lg:px-2 lg:px-5 border-r-2'>
                    <div className='flex flex-col items-center text-black/50'>
                        <div className='flex'>
                            <div className='lg:text-4xl text-2xl'><BsCheck2All /></div>
                            <p className='lg:mx-3 mx-1 text-xl'>0</p>
                        </div>
                        <div className=''>
                            <p className='text-black/20 text-sm lg:text-lg lg:block hidden'>{t('Dashboard.duplicates.new_duplicates')}</p>
                        </div>
                    </div>
                    <div className='flex flex-col items-center text-black/50'>
                        <div className='flex'>
                            <div className='lg:text-4xl text-2xl'><BsCheck2Circle /></div>
                            <p className='lg:mx-3 mx-1 text-xl'>{request.duplicates.length}</p>
                        </div>
                        <div>
                            <p className='text-black/20 text-sm lg:text-lg lg:block hidden'>{t('Dashboard.duplicates.total_duplicates')}</p>
                        </div>
                    </div>
                </div>
                <div className='col-span-1 flex flex-col items-center justify-center'>
                    <button onClick={() => { setOpenDuplicates(!openDuplicates) }} className='text-primary lg:text-3xl text-2xl border-2 rounded-full p-1 border-primary'>
                        {openDuplicates ? <MdKeyboardArrowUp />: <MdKeyboardArrowDown /> }
                    </button>
                </div>
            </div>


            {openDuplicates &&
                request.duplicates.map((duplicate, index) => (
                    <div className={(request.duplicates?.length > 1 && index +1 !== request.duplicates?.length) && 'border-b-2'}>
                        <DuplicateCard key={index} duplicate={duplicate} />
                    </div>
                ))
            }
        </div>
        </>
    )
}
export default RequestCard;