import { t } from "i18next";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Modal from "../../../Modals/Modal";
import ManageVideo from "../../../Modals/ManageVideo";
import { useEffect, useState } from "react";
import { useAlert } from "../../../../contexts/AlertContext";
import axios from "../../../Config/axios";
import VideoCard from "./VideoCard";
import ActionConfirmationModal from "../../../Modals/ActionConfirmationModal";

const Videos = () =>
{

    const { toggleLoader, triggerPopup } = useAlert();
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [errors, setErrors] = useState([]);

    const getVideos = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/learning');
            if(response.status === 200){
                setVideos(response.data);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const handleEditVideo = (video) => {
        setSelectedVideo(video);
        setOpenModal(true);
    }

    const handleDeleteVideo = (video) => {
        setSelectedVideo(video);
        setOpenDeleteModal(true);
    }

    const handleClose = () =>
    {
        setOpenModal(false);
        setSelectedVideo(null);
        setErrors([]);
    }

    const submitForm = async (formData) => {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/learning', { ...formData });
            if (response.status === 200) {
                triggerPopup( formData.id === 0 ? 'Popups.video_created' : 'Popups.changes_saved');
                setOpenModal(false);
                setSelectedVideo(null);
                getVideos();
            }   
        } catch (error) {
            setErrors(error.response.data.errors);
        }
        setIsLoading(false);
    }

    const deleteVideo = async (video) =>
    {
        setIsLoading(true);
        try {
            const response = await axios.delete('/api/learning/destroy', {
                params: {
                    id: video.id
                }
            });
            if (response.status === 204)
            {
                triggerPopup('Popups.video_deleted');
                setOpenDeleteModal(false);
                getVideos();
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getVideos();
    }, []);

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
    

    return (
        <>
            <Modal isOpen={openModal}>
                <ManageVideo video={selectedVideo} onClose={() => { handleClose() }} onSubmit={(formData) => { submitForm(formData) }} errors={errors} />
            </Modal>

            <Modal isOpen={ openDeleteModal }>
                <ActionConfirmationModal
                    title={t('Modals.delete_video_title')}
                    description={t('Modals.delete_video_subtitle')}
                    onCancel={ () => { setOpenDeleteModal(false) } }
                    onConfirm={ () => { deleteVideo(selectedVideo) } }
                />
            </Modal>
            
            <button onClick={() => { setOpenModal(true) }} className="w-full rounded-2xl bg-primary/20 text-primary  hover:bg-primary hover:text-white flex items-center px-8 py-6 shadow-custom my-3 transition-all">
                <span className="text-5xl md:mr-5 mr-3">
                    <AiOutlinePlusCircle />
                </span>
                <span className="font-bold md:text-3xl text-xl">{t('Dashboard.admin.add_video')}</span>
            </button>
            {videos.map((video, index) => (
                <VideoCard key={index} video={video} onEdit={(video) => { handleEditVideo(video) }} onDelete={(video) => { handleDeleteVideo(video) }} />
            ))}
        </>
    )
}

export default Videos;