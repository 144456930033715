// import { PLAN_TYPES } from '../../../constants/global';
const PlanTypeCard = ({ onClick, isSelected, icon, text, iconClass = '' }) => {

    return (
        <button
            onClick={() => onClick()}
            className={`${isSelected ? 'text-white bg-primary' : 'text-primary bg-white'}
                w-40 h-40 md:w-60 md:h-60 flex flex-col justify-center items-center border-4 border-primary rounded-2xl mx-2 text-xlmd:my-0 my-5
            `}
        >
            <img className={iconClass} alt={text} src={icon} />
            <h1 className="lg:text-2xl pt-5">{text}</h1>
        </button>
    )
}

export default PlanTypeCard