import React from 'react';
import getVideoID from '../../../helpers/getVideoID';

const VideoCard = ({ video, onClick }) =>
{
    return (
        <>
            <div className='hover:cursor-pointer group' onClick={() => { onClick() }}>
                <img className='rounded-3xl w-72 md:w-fit md:max-w-md' src={`https://img.youtube.com/vi/${getVideoID(video.youtube_link)}/maxresdefault.jpg`} alt={video.title} />
                <div className='md:space-y-1 text-start pt-2 md:pt-5'>
                    <h1 className='font-bold text-base md:text-2xl group-hover:text-primary transition-all line-clamp-2'>{video.title}</h1>
                    <h2 className='font-bold text-black/50 text-sm md:text-lg'>By Fortimy</h2>
                    {/* <p className='text-black/20 md:text-lg text-base'>{video.time}</p> */}
                </div>
            </div>
        </>
    )
}

export default VideoCard;