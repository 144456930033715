import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../assets/LogoIcon.png';
import MyRequestsSVGSelected from '../../../assets/Dashboard/Navbar/myrequestsSelected.svg';
import RequestsSVGSelected from '../../../assets/Dashboard/Navbar/requestsSelected.svg';
import MyRequestsSVGLighterActive from '../../../assets/Dashboard/Navbar/myrequestsLighterActive.svg';
import RequestsSVGLighterActive from '../../../assets/Dashboard/Navbar/requestsLighterActive.svg';
import LogoPreto from '../../../assets/Logo.png';
import useAuth from "../../../hooks/useAuth";
import useAuthContext from '../../Config/AuthContext';
import { useTranslation } from "react-i18next";
import { MdLanguage, MdDashboard, MdChatBubble, MdDoneAll, MdGroup, MdLogout } from "react-icons/md";
import { FaLightbulb } from "react-icons/fa";
import { IoMdCart, IoIosSettings, IoIosNotifications } from "react-icons/io";
import { RiAdminFill } from 'react-icons/ri';
import { APP_PATHS } from '../../../constants/global';
import { MdEdit } from "react-icons/md";
import { useAlert } from '../../../contexts/AlertContext';
import { HiMenu } from "react-icons/hi";
import axios from '../../Config/axios';

const DasboardNavbar = () => {

    const { logout } = useAuthContext();
    const { auth, hasMessage, hasNotifications } = useAuth();
    const { triggerPopup } = useAlert();

    const { t, i18n } = useTranslation();

    const lngs = {
        pt: { nativeName: "PT" },
        en: { nativeName: "EN" },
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const location = useLocation();
    const fileInput = useRef(null)
    const [profileImage, setProfileImage] = useState(null);

    const [dashboardIcon, setDashboardIcon] = useState('text-primary/80 text-4xl md:text-6xl');
    const [adminIcon, setAdminIcon] = useState('text-3xl md:text-5xl text-primary/80');
    const [requestsIcon, setRequestsIcon] = useState(RequestsSVGLighterActive);
    const [messagesIcon, setMessagesIcon] = useState('text-primary/80 text-4xl md:text-6xl');
    const [messagesNotification, setMessagesNotification] = useState('absolute rounded-full w-3 h-3 bg-customPurple top-0 right-0');
    const [myRequestsIcon, setMyRequestsIcon] = useState(MyRequestsSVGLighterActive);
    const [duplicatesIcon, setDuplicatesIcon] = useState(`text-4xl md:text-6xl ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
    const [teamIcon, setTeamIcon] = useState('text-4xl md:text-6xl text-primary/80');
    const [learningIcon, setLearningIcon] = useState('text-primary/80 text-3xl md:text-5xl');
    const [storeIcon, setStoreIcon] = useState(`text-4xl md:text-6xl ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
    const [settingsIcon, setSettingsIcon] = useState('text-primary/80 text-4xl md:text-6xl');
    const [notificationIcon, setNotificationIcon] = useState('text-primary/80 text-4xl md:text-6xl');

    const [dashboardName, setDashboardName] = useState('text-primary/80 text-sm font-bold');
    const [requestsName, setRequestsName] = useState('text-primary/80 text-sm font-bold');
    const [messagesName, setMessagesName] = useState('text-primary/80 text-sm font-bold');
    const [myRequestsName, setMyRequestsName] = useState('text-sm font-bold text-primary/80');
    const [duplicatesName, setDuplicatesName] = useState(`text-sm font-bold ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
    const [teamName, setTeamName] = useState('text-sm font-bold text-primary/80');
    const [learningName, setLearningName] = useState('text-primary/80 text-sm font-bold');
    const [storeName, setStoreName] = useState(`text-sm font-bold ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
    const [settingsName, setSettingsName] = useState('text-primary/80 text-sm font-bold');
    const [adminName, setAdminName] = useState('text-sm font-bold text-primary/80');
    const [notificationName, setNotificationName] = useState('text-sm font-bold text-primary/80');

    const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false);

    const handleProfileIconClick = () => {
        fileInput.current.click()
    }

    const handleImage = (e) => {
        if(e.target.files[0].size < 1000000) {
            setProfileImage(e.target.files[0])
            submit(e.target.files[0])
        } else {
            triggerPopup('Popups.filesize_error');
        }
    }

    const submit = async (image) =>
    {
        const fd = new FormData();
        fd.append('profile_picture', image)

        await axios.post(`/api/users/${auth?.id}`, fd)
        .then((response) => {
            triggerPopup('Popups.changes_saved');
        })
        .catch((e) => {
            triggerPopup('Popups.something_went_wrong');
        })
        .finally(() => {
            //
        });
    }

    useEffect(() => {

        setDashboardIcon('text-4xl md:text-6xl text-primary/80');
        setRequestsIcon(RequestsSVGLighterActive);
        setMessagesIcon('text-primary/80 text-4xl md:text-6xl');
        setMyRequestsIcon(MyRequestsSVGLighterActive);
        setDuplicatesIcon(`text-4xl md:text-6xl ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
        setTeamIcon('text-4xl md:text-6xl text-primary/80');
        setLearningIcon('text-primary/80 text-3xl md:text-5xl');
        setStoreIcon(`text-4xl md:text-6xl ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
        setSettingsIcon('text-primary/80 text-4xl md:text-6xl');
        setAdminIcon('text-3xl md:text-5xl text-primary/80');
        setNotificationIcon('text-4xl md:text-6xl text-primary/80');

        setAdminName('text-sm font-bold text-primary/80');
        setDashboardName('text-sm font-bold text-primary/80');
        setRequestsName('text-primary/80 text-sm font-bold');
        setMessagesName('text-primary/80 text-sm font-bold');
        setMyRequestsName('text-sm font-bold text-primary/80');
        setDuplicatesName(`text-sm font-bold ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
        setTeamName('text-sm font-bold text-primary/80');
        setLearningName('text-primary/80 text-sm font-bold');
        setStoreName(`text-sm font-bold ${auth.is_dynamik_test ? 'text-primary/80' : 'text-disabled'}`);
        setSettingsName('text-primary/80 text-sm font-bold');
        setNotificationName('text-primary/80 text-sm font-bold');

        setMessagesNotification(`absolute rounded-full w-3 h-3 bg-customPurple top-0 right-0`);

        switch (true) {
            case location.pathname.includes(APP_PATHS.ADMIN):
                setAdminIcon('text-3xl md:text-5xl text-customPurple')
                setAdminName('text-sm font-bold text-customPurple');
                break;
            // case location.pathname.includes(APP_PATHS.ABOUT):
            //     // code...
            //     break;
            case location.pathname.includes(APP_PATHS.REQUESTS):
                setRequestsIcon(RequestsSVGSelected);
                setRequestsName('text-sm font-bold text-customPurple');
                break;
            case location.pathname.includes(APP_PATHS.DASHBOARD):
                setDashboardIcon('text-4xl md:text-6xl text-customPurple');
                setDashboardName('text-sm font-bold text-customPurple');
                break;
            case location.pathname.includes(APP_PATHS.MESSAGES):
                setMessagesIcon('text-4xl md:text-6xl text-customPurple');
                setMessagesNotification(`hidden`);
                setMessagesName('text-sm font-bold text-customPurple');
                break;
            case location.pathname.includes(APP_PATHS.MY_REQUESTS):
                setMyRequestsIcon(MyRequestsSVGSelected);
                setMyRequestsName('text-sm font-bold text-customPurple');
                break;
            case location.pathname.includes(APP_PATHS.DUPLICATES):
                setDuplicatesIcon(`text-4xl md:text-6xl ${auth.is_dynamik_test ? 'text-customPurple' : 'text-disabled'}`);
                setDuplicatesName(`text-sm font-bold ${auth.is_dynamik_test ? 'text-customPurple' : 'text-disabled'}`);
                break;
            case location.pathname.includes(APP_PATHS.TEAM):
                setTeamIcon('text-4xl md:text-6xl text-customPurple');
                setTeamName('text-sm font-bold text-customPurple');
                break;
            case location.pathname.includes(APP_PATHS.LEARNING):
                setLearningIcon('text-3xl md:text-5xl text-customPurple');
                setLearningName('text-sm font-bold text-customPurple');
                break;
            case location.pathname.includes(APP_PATHS.STORE):
                setStoreIcon(`text-4xl md:text-6xl ${auth.is_dynamik_test ? 'text-customPurple' : 'text-disabled'}`);
                setStoreName(`text-sm font-bold ${auth.is_dynamik_test ? 'text-customPurple' : 'text-disabled'}`);
                break;
            case location.pathname.includes(APP_PATHS.SETTINGS):
                setSettingsIcon('text-4xl md:text-6xl text-customPurple');
                setSettingsName('text-sm font-bold text-customPurple');
                break;
            case location.pathname.includes(APP_PATHS.NOTIFICATIONS):
                setNotificationIcon('text-4xl md:text-6xl text-customPurple');
                setNotificationName('text-sm font-bold text-customPurple');
                break;
            default:
                // setDashboardIcon(DashboardImgSelected);
                // setDashboardName('text-sm font-bold text-customPurple');
                break;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <>
            {/* MOBILE NAVBAR */}
            {!burgerMenuIsOpen &&
                <button
                    onClick={() => { setBurgerMenuIsOpen(!burgerMenuIsOpen) }}
                    className='fixed text-3xl lg:hidden p-3 top-5 right-5 rounded-full bg-white shadow-custom z-[999] text-primary'
                >
                    <HiMenu />
                </button>
            }

            {burgerMenuIsOpen &&
            <div className='fixed flex items-center justify-center top-0 left-0 h-screen w-screen z-[999] lg:hidden bg-black/20'>
                <div className='relative bg-white w-11/12 flex flex-col items-center p-10 rounded-xl'>
                    <button onClick={() => { setBurgerMenuIsOpen(!burgerMenuIsOpen) }} className='absolute top-5 right-5 font-bold text-primary text-3xl'>X</button>
                    <img src={LogoPreto} alt="Fortimy" className='h-12' />
                    <div className="flex justify-center items-center">
                        {Object.keys(lngs).map((lng) =>
                            i18n.resolvedLanguage !== lng ? (
                            <button
                                type="submit"
                                key={lng}
                                className="text-2xl text-primary/80 flex items-center"
                                onClick={() => changeLanguage(lng)}
                            >
                                <MdLanguage />
                                {lngs[i18n.resolvedLanguage].nativeName}
                            </button>
                            ) : null
                        )}
                    </div>
                    <div className='grid grid-cols-2 md:grid-cols-3 gap-y-3 mx-auto w-full my-8'>
                        <Link
                            to={APP_PATHS.NOTIFICATIONS}
                            className='flex flex-col justify-center items-center'
                            onClick={() => setBurgerMenuIsOpen(false)}
                        >
                            <div className={`${notificationIcon} relative`}>
                                <IoIosNotifications/>
                                {hasNotifications &&
                                    <div className={messagesNotification} />
                                }
                            </div>
                            <h2 className={notificationName}>{t('Dashboard.navbar.notifications')}</h2>
                        </Link>
                        {!auth.is_individual &&
                        <>
                            <Link
                                to={APP_PATHS.DASHBOARD}
                                className='flex flex-col justify-center items-center my-5'
                                onClick={() => setBurgerMenuIsOpen(false)}
                            >
                                <div className={dashboardIcon}><MdDashboard/></div>
                                <h2 className={dashboardName}>{t('Dashboard.navbar.dashboard')}</h2>
                            </Link>
                            
                            {/* <div className='flex flex-col justify-center items-center cursor-not-allowed opacity-40'>
                                <div className={dashboardIcon}><MdDashboard/></div>
                                <h2 className={dashboardName}>{t('Dashboard.navbar.dashboard')}</h2>
                            </div> */}

                            <Link
                                to={APP_PATHS.REQUESTS}
                                className='flex flex-col justify-center items-center'
                                onClick={() => setBurgerMenuIsOpen(false)}
                            >
                                <img src={requestsIcon} className="w-10 md:w-20" alt="Requests Icon" />
                                <h2 className={requestsName}>{t('Dashboard.navbar.requests')}</h2>
                            </Link>
                        </>
                        }

                        
                        <Link
                            to={APP_PATHS.MESSAGES}
                            className='flex flex-col justify-center items-center'
                            onClick={() => setBurgerMenuIsOpen(false)}
                        >
                            <div className={`${messagesIcon} relative`}>
                                <MdChatBubble/>
                                {hasMessage &&
                                    <div className={messagesNotification} />
                                }
                            </div>
                            <h2 className={messagesName}>{t('Dashboard.navbar.messages')}</h2>
                        </Link>

                        <Link
                            to={APP_PATHS.MY_REQUESTS}
                            className='flex flex-col justify-center items-center'
                            onClick={() => setBurgerMenuIsOpen(false)}
                        >
                            <img src={myRequestsIcon} className="w-10 md:w-20" alt="My Requests Icon" />
                            <h2 className={myRequestsName}>{t('Dashboard.navbar.my_requests')}</h2>
                        </Link>

                    {!auth.is_individual &&
                    <>
                        {auth.is_dynamik_test ? (
                            <Link
                                to={APP_PATHS.DUPLICATES}
                                className='flex flex-col justify-center items-center'
                                onClick={() => setBurgerMenuIsOpen(false)}
                            >
                                <div className={duplicatesIcon}><MdDoneAll/></div>
                                <h2 className={duplicatesName}>{t('Dashboard.navbar.duplicates')}</h2>
                            </Link>
                            ) : (
                                <div className='flex flex-col justify-center items-center'>
                                    <div className={duplicatesIcon}><MdDoneAll/></div>
                                    <h2 className={duplicatesName}>{t('Dashboard.navbar.duplicates')}</h2>
                                </div>
                            )
                        }

                        <>
                            <Link
                                to={APP_PATHS.TEAM}
                                className='flex flex-col justify-center items-center'
                                onClick={() => setBurgerMenuIsOpen(false)}
                            >
                                <div className={teamIcon}><MdGroup/></div>
                                <h2 className={teamName}>{t('Dashboard.navbar.team')}</h2>
                            </Link>
                        
                            <Link
                                to={APP_PATHS.LEARNING}
                                className='flex flex-col justify-center items-center space-y-1'
                                onClick={() => setBurgerMenuIsOpen(false)}
                            >
                                <div className={learningIcon}><FaLightbulb/></div>
                                <h2 className={learningName}>{t('Dashboard.navbar.learning')}</h2>
                            </Link>
                        </>

                        { auth.is_dynamik_test ? (
                            <Link
                                to={APP_PATHS.STORE}
                                className='flex flex-col justify-center items-center'
                                onClick={() => setBurgerMenuIsOpen(false)}
                            >
                                <div className={storeIcon}><IoMdCart/></div>
                                <h2 className={storeName}>{t('Dashboard.navbar.store')}</h2>
                            </Link>
                            ) : (
                            <div className='flex flex-col justify-center items-center'>
                                <div className={storeIcon}><IoMdCart/></div>
                                <h2 className={storeName}>{t('Dashboard.navbar.store')}</h2>
                            </div>
                            )
                        }
                    </>
                    }


                        { // auth.company_role_id === COMPANY_ROLES.ADMIN &&
                            <Link
                                to={APP_PATHS.SETTINGS}
                                className='flex flex-col justify-center items-center'
                                onClick={() => setBurgerMenuIsOpen(false)}
                            >
                                <div className={settingsIcon} alt="Settings Icon"><IoIosSettings/></div>
                                <h2 className={settingsName}>{t('Dashboard.navbar.settings')}</h2>
                            </Link>
                        }
                        { auth.is_admin === 1 &&
                        <Link
                            to={APP_PATHS.ADMIN}
                            className='flex flex-col justify-center items-center col-span-1'
                            onClick={() => setBurgerMenuIsOpen(false)}
                        >
                            <div className={adminIcon}><RiAdminFill /></div>
                            <h2 className={adminName}>{t('Dashboard.navbar.admin')}</h2>
                        </Link>
                        }
                        <button onClick={logout} className='flex flex-col justify-center items-center'>
                            <div className='text-4xl md:text-5xl text-primary/80 rotate-180'><MdLogout/></div>
                        </button>
                    </div>
                </div>
            </div>
            }

            {/* DESKTOP NAVBAR */}
            <div className='absolute top-[15vh] xl:left-[6vw] lg:left-[1vw] lg:flex hidden bg-white shadow-custom rounded-3xl flex-col z-[100] w-[120px] justify-center items-center py-10 px-5 text-center space-y-6'>
                <div className="flex justify-center items-center">
                    {Object.keys(lngs).map((lng) =>
                        i18n.resolvedLanguage !== lng ? (
                        <button
                            type="submit"
                            key={lng}
                            className="text-2xl text-primary/80 flex items-center"
                            onClick={() => changeLanguage(lng)}
                        >
                            <MdLanguage />
                            {lngs[i18n.resolvedLanguage].nativeName}
                        </button>
                        ) : null
                    )}
                </div>
                <div className='w-full flex justify-center'>
                    <img src={Logo} alt='Fortimy Logo' className="w-[50%]" />
                </div>
                {/* <Link to={APP_PATHS.ABOUT} className='w-full flex justify-center'>
                    <img src={Logo} alt='Fortimy Logo' className="w-[50%]" />
                </Link> */}
                <div className='flex justify-center items-center w-[70px] h-[70px] rounded-full m-auto'>
                    { auth.profile_image !== null ? (
                        auth.is_individual ? (
                                <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${auth.profile_image}`} alt="user-pic" className='rounded-full w-full h-full object-cover' />
                            ) : (
                                <>
                                <div className='relative hover:cursor-pointer w-[70px] h-[70px]' onClick={() => handleProfileIconClick()}>
                                    <img
                                        src={ profileImage
                                            ? URL.createObjectURL(profileImage)
                                            : `${process.env.REACT_APP_BACKEND_URL_IMG}/users/${auth?.profile_image}`
                                        }
                                        alt="user-pic" className='rounded-full w-full h-full object-cover'
                                    />
                                    <MdEdit className='text-primary text-xl absolute -top-2 -right-2' />
                                    <input type='file' id='file' ref={fileInput} onChange={(e) => { handleImage(e) }} className='w-full hidden' />
                                </div>
                                </>
                            )
                        ) : (
                            <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/placeholder.png`} alt="user-pic" className='rounded-full w-full h-full object-cover' />
                        )
                    }
                </div>

                <Link to={APP_PATHS.NOTIFICATIONS} className='flex flex-col justify-center items-center'>
                    <div className={`${notificationIcon} relative`}>
                        <IoIosNotifications/>
                        {hasNotifications &&
                            <div className={messagesNotification} />
                        }
                    </div>
                    <h2 className={notificationName}>{t('Dashboard.navbar.notifications')}</h2>
                </Link>

                {!auth.is_individual &&
                    <>
                        <Link to={APP_PATHS.DASHBOARD} className='flex flex-col justify-center items-center my-5'>
                            <div className={dashboardIcon}><MdDashboard/></div>
                            <h2 className={dashboardName}>{t('Dashboard.navbar.dashboard')}</h2>
                        </Link>

                        <Link
                            to={APP_PATHS.REQUESTS}
                            className='flex flex-col justify-center items-center'>
                            <img src={requestsIcon} className="text-5xl" alt="Requests Icon" />
                            <h2 className={requestsName}>{t('Dashboard.navbar.requests')}</h2>
                        </Link>
                    </>
                }
                <Link to={APP_PATHS.MESSAGES} className='flex flex-col justify-center items-center'>
                    <div className={`${messagesIcon} relative`}>
                        <MdChatBubble/>
                        {hasMessage &&
                            <div className={messagesNotification} />
                        }
                    </div>
                    <h2 className={messagesName}>{t('Dashboard.navbar.messages')}</h2>
                </Link>
                
                <Link to={APP_PATHS.MY_REQUESTS} className='flex flex-col justify-center items-center'>
                    <img src={myRequestsIcon} className="text-5xl" alt="My Requests Icon" />
                    <h2 className={myRequestsName}>{t('Dashboard.navbar.my_requests')}</h2>
                </Link>
                
                {!auth.is_individual &&
                    <>
                        {auth.is_dynamik_test ? (
                                <Link to={APP_PATHS.DUPLICATES} className='flex flex-col justify-center items-center'>
                                    <div className={duplicatesIcon}><MdDoneAll/></div>
                                    <h2 className={duplicatesName}>{t('Dashboard.navbar.duplicates')}</h2>
                                </Link>
                            ) : (
                                <div className='flex flex-col justify-center items-center'>
                                    <div className={duplicatesIcon}><MdDoneAll/></div>
                                    <h2 className={duplicatesName}>{t('Dashboard.navbar.duplicates')}</h2>
                                </div>
                            )
                        }
                        
                        <Link to={APP_PATHS.TEAM} className='flex flex-col justify-center items-center'>
                            <div className={teamIcon}><MdGroup/></div>
                            <h2 className={teamName}>{t('Dashboard.navbar.team')}</h2>
                        </Link>
                    
                        <Link to={APP_PATHS.LEARNING} className='flex flex-col justify-center items-center space-y-1'>
                            <div className={learningIcon}><FaLightbulb/></div>
                            <h2 className={learningName}>{t('Dashboard.navbar.learning')}</h2>
                        </Link>

                        { auth.is_dynamik_test ? (
                            <Link to={APP_PATHS.STORE} className='flex flex-col justify-center items-center'>
                                <div className={storeIcon}><IoMdCart/></div>
                                <h2 className={storeName}>{t('Dashboard.navbar.store')}</h2>
                            </Link>
                            ) : (
                            <div className='flex flex-col justify-center items-center'>
                                <div className={storeIcon}><IoMdCart/></div>
                                <h2 className={storeName}>{t('Dashboard.navbar.store')}</h2>
                            </div>
                            )
                        }
                    </>
                }

                { // auth.company_role_id === COMPANY_ROLES.ADMIN &&
                    <Link to={APP_PATHS.SETTINGS} className='flex flex-col justify-center items-center'>
                        <div className={settingsIcon} alt="Settings Icon"><IoIosSettings/></div>
                        <h2 className={settingsName}>{t('Dashboard.navbar.settings')}</h2>
                    </Link>
                }
                { auth.is_admin === 1 &&
                <Link to={APP_PATHS.ADMIN} className='flex flex-col justify-center items-center col-span-1'>
                    <div className={adminIcon}><RiAdminFill /></div>
                    <h2 className={adminName}>{t('Dashboard.navbar.admin')}</h2>
                </Link>
                }
                <button onClick={logout} className='flex flex-col justify-center items-center'>
                    <div className='text-5xl text-primary/80 rotate-180'><MdLogout/></div>
                </button>
            </div>

        </>
    )
}

export default DasboardNavbar