import React from 'react';
import DashboardSettings from '../../../components/Dashboard/Settings';
import ScrollToTop from '../../../components/Router/ScrollToTop';
import useAuth from '../../../hooks/useAuth';
import ComingSoon from '../../ComingSoon';
import { COMPANY_ROLES } from '../../../constants/global';
import { Navigate } from 'react-router';

const Settings = () => {
    const {auth} = useAuth();
    return (
        <>
            <ScrollToTop />
            {auth.company_role_id === COMPANY_ROLES.ADMIN ?
                (<Navigate to={ "company" } replace />)
                : (<Navigate to={ "account" } replace />)
            }
            <DashboardSettings />
        </>
    )
}

export default Settings