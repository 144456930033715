import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AmenityCard from "./AmenityCard.js";
import Select from "../../Inputs/Select.js";

const Caracteristics = ({ formData, formOptions, onSubmit }) => {

    const customSelect = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: "2px",
            borderColor: "#e5e7eb",
            width: "100%",
            borderRadius: "0.375rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
        }),
    };

    const { t } = useTranslation();

    const [conditions, setConditions] = useState([]);
    const [selectedCondition, setSelectedCondition] = useState(null);

    const [typologies, setTypologies] = useState([]);
    const [selectedTypology, setSelectedTypology] = useState(null);

    const [bathrooms, setBathrooms] = useState([]);
    const [selectedBathroom, setSelectedBathroom] = useState(null);

    const [amenities, setAmenities] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState(formData?.amenities ?? []);

    const handleAmenities = (amenityId) => {
        if (selectedAmenities.includes(amenityId)) {
            let selectedAmenitiesAux = selectedAmenities;
            selectedAmenitiesAux.splice(selectedAmenitiesAux.indexOf(amenityId), 1);
            setSelectedAmenities((current) => [...selectedAmenitiesAux]);
        } else {
            setSelectedAmenities((current) => [...current, amenityId]);
        }
    };

    const handleSubmit = () => {
        onSubmit({
            condition: selectedCondition,
            typology: selectedTypology,
            bathroom: selectedBathroom,
            amenities: selectedAmenities,
        })
    }

    useEffect(() => {
        setAmenities(formOptions.amenities);
        
        setConditions(formOptions.conditions);
        if (formOptions.conditions?.length > 0) {
            setSelectedCondition(formData?.condition ?? formOptions.conditions[0]);
        }

        setTypologies(formOptions.typologies);
        if (formOptions.typologies?.length > 0) {
            setSelectedTypology(formData?.typology ?? formOptions.typologies[0]);
        }
        
        setBathrooms(formOptions.bathrooms);
        if (formOptions.bathrooms?.length > 0) {
            setSelectedBathroom(formData?.bathroom ?? formOptions.bathrooms[0]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formOptions]);
    
    return (
        <>
            <h1 className="text-center text-2xl md:text-4xl font-bold mb-2 md:mb-5">
                {t("Dashboard.my_requests.make_a")}{" "}
                {t("Dashboard.my_requests.request")}
            </h1>

            <h2 className="text-primary md:text-xl font-bold text-center">
                {t("Dashboard.my_requests.what_features_does_it_have")}
            </h2>

            <form className="lg:w-[50vw] xl:w-[40vw] w-11/12 md:mt-14 mt-5">
                <div className="w-full md:w-4/5 m-auto space-y-5">
                    <div>
                        <p className="mb-2 text-lg">
                            {t("Dashboard.my_requests.condition")}
                        </p>
                        <Select
                            value={selectedCondition}
                            options={conditions}
                            onChange={(e) => {
                                setSelectedCondition(e);
                            }}
                            style={customSelect}
                        />
                    </div>
                    <div>
                        <p className="mb-2 text-lg">
                            {t("Dashboard.my_requests.typology")}
                        </p>
                        <Select
                            value={selectedTypology}
                            options={typologies}
                            onChange={(e) => {
                                setSelectedTypology(e);
                            }}
                            style={customSelect}
                        />
                    </div>
                    <div>
                        <p className="mb-2 text-lg">
                            {t("Dashboard.my_requests.bathrooms")}
                        </p>
                        <Select
                            value={selectedBathroom}
                            options={bathrooms}
                            onChange={(e) => {
                                setSelectedBathroom(e);
                            }}
                            style={customSelect}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4 lg:mt-20 mt-10 mx-auto">
                    {amenities &&
                        amenities.map((amenity, index) => (
                            <AmenityCard
                                key={index}
                                amenity={amenity}
                                amenities={amenities}
                                selectedAmenities={selectedAmenities}
                                onClick={(e) => {
                                    handleAmenities(e);
                                }}
                            />
                        ))
                    }
                </div>
            </form>

            <button
                type="button"
                onClick={() => {
                    handleSubmit();
                }}
                className="md:mt-20 mt-10 xl:w-[25vw] lg:w-[40vw] md:w-[50vw] w-full py-2 bg-primary font-bold text-white text-xl rounded-md"
            >
                {t("Dashboard.my_requests.continue")}
            </button>
        </>
    )
}

export default Caracteristics;