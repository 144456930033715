import React from 'react';
import DashboardNotifications from '../../components/Dashboard/Notifications';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Notifications = () => {
    return (
        <>
        <ScrollToTop />
        <section>
            <DashboardNotifications />
        </section>
        </>
    )
}

export default Notifications